<template>
  <div class="row">
    <sociallAccording :friends="friends" :posts="posts" />
    <socialPost />
    <profileIntro />
  </div>
</template>
<script>
import sociallAccording from "@/components/common/sociallAccording.vue";
import socialPost from "./socialPost.vue";
import profileIntro from "./profileIntro.vue";
export default {
  components: {
    sociallAccording,
    socialPost,
    profileIntro,
  },
  props: ["friends", "posts"],
};
</script>
