<template>
     <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 1</h6>
                        <div class="loader-box">
                          <div class="loader-1"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 2</h6>
                        <div class="loader-box">
                          <div class="loader-2"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 3</h6>
                        <div class="loader-box">
                          <div class="loader-3"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 4</h6>
                        <div class="loader-box">
                          <div class="loader-4"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 5</h6>
                        <div class="loader-box">
                          <div class="loader-5"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 6</h6>
                        <div class="loader-box">
                          <div class="loader-6"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 7</h6>
                        <div class="loader-box">
                          <div class="loader-7"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 8</h6>
                        <div class="loader-box">
                          <div class="loader-8"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 9</h6>
                        <div class="loader-box">
                          <div class="loader-9"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 10</h6>
                        <div class="loader-box">
                          <div class="loader-10"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 11</h6>
                        <div class="loader-box">
                          <div class="loader-11"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 12</h6>
                        <div class="loader-box">
                          <div class="loader-12"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 13</h6>
                        <div class="loader-box">
                          <div class="loader-13"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 14</h6>
                        <div class="loader-box">
                          <div class="loader-14"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 15</h6>
                        <div class="loader-box">
                          <div class="loader-15"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 16</h6>
                        <div class="loader-box">
                          <div class="loader-16"></div>
                        </div>
                      </div>
                      
</template>