<template>
    <Breadcrumbs main="Bonus Ui" title="Basic Card"/>
     <div class="container-fluid basic-card">
            <div class="row">
                <basicCard/>
            </div>
     </div>
</template>
<script>
import basicCard from "@/components/advance/basic/basicCard.vue"
export default {
    components:{
        basicCard,
       
    }
}
</script>