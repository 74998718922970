import axios from "axios";

const HOST = process.env.VUE_APP_API_FRIEND;

export default {
  async getFriend(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/friend/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getFriends(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/friend/user/list/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createFriend(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/friend`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateFriend(payload = {}) {
    try {
      const data = payload.payload;
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/friend/${id}`,
        method: "put",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
};
