<template>
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper col-auto p-0">
      <sidebar />
    </div>
    <div class="left-header col horizontal-wrapper ps-0">
      <leftMenu />
    </div>
    <div class="nav-right col-6 pull-right right-header p-0">
      <ul class="nav-menus">
        <li>
          <searchView />
        </li>
        <li class="serchinput">
          <searchInput />
        </li>
        <li class="onhover-dropdown">
          <helpView />
        </li>
        <li class="onhover-dropdown">
          <notificationView :notifications="notifications" />
          <span v-if="notifications.length" class="notification-count">{{
            notifications.length
          }}</span>
        </li>
        <!-- <li class="onhover-dropdown">
                    <messagesView/>
                </li>  -->
        <!-- <li class="language-nav">
                     <languageView/>
                 </li> -->
        <li class="profile-nav onhover-dropdown">
          <profileView />
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.notification-count {
  width: 20px;
  height: 20px;
  background-color: blue;
  color: white;
  border-radius: 50%;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0.2rem;
  margin-top: -10px;
}
</style>
<script>
import searchInput from "./searchInput.vue";
import sidebar from "./sidebarView.vue";
import leftMenu from "./leftMenu.vue";
import searchView from "./searchView.vue";
import modeView from "./modeView.vue";
import messagesView from "./messagesView.vue";
import helpView from "./helpView.vue";
import languageView from "./languageView.vue";
import notificationView from "./notificationView.vue";
import maximizeView from "./maximizeView.vue";
import profileView from "./profileView.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    sidebar,
    leftMenu,
    searchView,
    modeView,
    messagesView,
    languageView,
    notificationView,
    maximizeView,
    profileView,
    searchInput,
    helpView,
  },
  data() {
    return {
      filtered: false,
    };
  },
  computed: {
    ...mapState({
      notifications: (state) => state.invitation.notifications,
    }),
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    ...mapActions({
      getNotifications: "invitation/getNotifications",
    }),
    collapseFilter() {
      this.filtered = !this.filtered;
    },
  },
};
</script>
