import * as types from "../mutation-types";
import invitationAPI from "@/api/invitation";

const state = {
  notifications: []
};

const getters = {};

const actions = {
  async createInvitation({ commit }, payload) {
    try {
      const response = await invitationAPI.createInvitation(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getNotifications({ commit }, payload) {
    try {
      const response = await invitationAPI.getNotifications(payload);
      const notifications = response.data;
      commit(types.SET_NOTIFICATIONS, { notifications });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_NOTIFICATIONS](state, { notifications }) {
    state.notifications = notifications;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
