<template>
  <div class="custom-uploader">
    <label for="file-upload" class="upload-label">
      <div
        class="circle-avatar"
        :class="{ empty: !selectedImage }"
        :style="{ width: size, height: size }"
      >
        <svg
          v-if="!selectedImage"
          xmlns="http://www.w3.org/2000/svg"
          width="30%"
          height="30%"
          fill="#888"
          class="bi bi-person-lines-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
          />
        </svg>
        <img
          v-else
          :src="selectedImage"
          alt="Imagen seleccionada"
          class="avatar"
          @mouseover="showIcons = true"
          @mouseleave="showIcons = false"
        />
        <div
          class="image-overlay"
          v-if="selectedImage"
          @mouseover="showIcons = true"
          @mouseleave="showIcons = false"
        >
          <!-- Botón de lupa (Abrir en nueva pestaña) -->
          <a
            :href="selectedImage || initialValue"
            target="_blank"
            class="zoom-icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              fill="#fff"
              class="bi bi-arrows-fullscreen"
              viewBox="0 0 16 16"
              style="margin-right: 10px"
            >
              <path
                fill-rule="evenodd"
                d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"
              />
            </svg>
          </a>
          <!-- Botón de tacho de basura (Limpiar imagen) -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#007bff"
            class="bi bi-pencil"
            viewBox="0 0 16 16"
            @click="openFileInput"
          >
            <path
              d="M12.293 0.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.242-1.242l1-3a1 1 0 0 1 .242-.39l9-9zM13 1l2 2-9 9-3 1 1-3 9-9z"
            />
            <path
              fill-rule="evenodd"
              d="M13.293 0.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.242-1.242l1-3a1 1 0 0 1 .242-.39l9-9zM13 1l2 2-9 9-3 1 1-3 9-9z"
            />
          </svg>
        </div>
        <span class="choose-image-text" v-if="!selectedImage"
          >Elegir imagen</span
        >
      </div>
      <input
        ref="fileInput"
        type="file"
        id="file-upload"
        accept="image/*"
        @change="handleFileChange"
        style="display: none"
      />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    initialValue: String, // Valor inicial desde una URL
    uploadEndpoint: String,
    size: {
      type: String,
      default: "150px", // Valor por defecto
    },
  },
  data() {
    return {
      selectedImage: this.initialValue || null,
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = URL.createObjectURL(file);
        this.$emit("upload-image", event);
      } else {
        this.selectedImage = null;
        this.$emit("upload-image", null);
      }
      console.log(file);
    },
    async uploadImage(file) {
      try {
        this.selectedImage = URL.createObjectURL(file);
        this.$emit("upload-image", this.selectedImage); // Emitir valor al padre
      } catch (error) {
        console.error("Error al cargar la imagen:", error);
      }
    },
    clearImage(e) {
      e.preventDefault();
      this.selectedImage = null;
      this.$refs.fileInput.value = null;
      this.$emit("input", null);
    },
    openFileInput() {
      this.selectedImage = null;
      this.$refs.fileInput.value = null;
      this.$emit("input", null);
    },
  },
  watch: {
    initialValue(newVal) {
      this.selectedImage = newVal;
    },
  },
};
</script>

<style scoped>
.custom-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-label {
  cursor: pointer;
}

.circle-avatar {
  width: 125px;
  /* 50% más grande */
  height: 120px;
  /* 50% más grande */
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #888;
  position: relative;
}

.circle-avatar.empty {
  border-color: #888;
  background-color: #eee;
}

.circle-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  /* Hace que la imagen sea circular */
}

.avatar {
  border-radius: 100%;
  /* Asegura que la imagen seleccionada también sea circular */
}

.choose-image-text {
  font-size: 14px;
  color: #888;
  margin-top: 8px;
  text-align: center;
}

.image-overlay {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 18px;
  display: none;
  transition: background-color 0.3s;
}

.circle-avatar:hover .image-overlay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image-icons a,
.image-icons button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 4px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.image-icons a:hover,
.image-icons button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.image-icons button {
  color: #f44336;
  /* Color rojo para el botón de tacho de basura */
}

.choose-image-text {
  font-size: 14px;
  color: #888;
  margin-top: 8px;
  text-align: center;
}
</style>
