import * as types from "../mutation-types";
import directorAPI from "@/api/director";

const state = {
  directorSesions: [],
  directorPatients: [],
};

const actions = {
  async getSesionsById({ commit }, payload) {
    try {
      const response = await directorAPI.getSesionsById(payload);
      const directorSesions = response.data;
      commit(types.SET_DIRECTOR_SESIONS, { directorSesions });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getPatientsById({ commit }, payload) {
    try {
      const response = await directorAPI.getPatientsById(payload);
      const directorPatients = response.data;
      commit(types.SET_DIRECTOR_PATIENTS, { directorPatients });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_DIRECTOR_SESIONS](state, { directorSesions }) {
    state.directorSesions = directorSesions;
  },
  [types.SET_DIRECTOR_PATIENTS](state, { directorPatients }) {
    state.directorPatients = directorPatients;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
