<template>
     <Breadcrumbs main="Knowledgebase" title="Knowledgebase" />
      <div class="container-fluid faq-section">
            <div class="row">
                <knowledgebaseSearch/>
                <faqWidgets/>
                <featuredTutorial/>
            </div>
      </div>
</template>
<script>
import knowledgebaseSearch from "@/components/common/knowledgebaseSearch.vue"
import faqWidgets from "@/components/common/faqWidgets.vue"
import featuredTutorial from "@/components/knowledgebase/featuredTutorial.vue"
export default {
    components:{
        knowledgebaseSearch,
        faqWidgets,
        featuredTutorial
    }
}
</script>