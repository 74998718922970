<template>
   <div class="card mb-0">
                              <div class="card-header d-flex">
                                <h6 class="mb-0">Shared with me</h6>
                                <ul>
                                  <li><a class="grid-bookmark-view" href="javascript:void(0)"><vue-feather type="grid"></vue-feather></a></li>
                                  <li><a class="list-layout-view" href="javascript:void(0)"><vue-feather type="list"></vue-feather></a></li>
                                </ul>
                              </div>
                              <div class="card-body">
                                <div class="details-bookmark text-center"><span>No Bookmarks Found.</span></div>
                              </div>
                            </div>
</template>