<template>
     <div class="col-sm-12 col-xl-6 box-col-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h4>Status Indicator</h4>
                      <span>Use <code>.bg-*</code>for different color status Indicator.</span>
                    </div>
                    <div class="card-body"> 
                      <div class="avatar-showcase">
                        <div class="avatars">
                          <div class="avatar"><img class="img-100 rounded-circle" src="@/assets/images/user/3.jpg" alt="#">
                            <div class="status bg-primary"></div>
                          </div>
                          <div class="avatar"><img class="img-90 rounded-circle" src="@/assets/images/user/3.jpg" alt="#">
                            <div class="status bg-secondary"></div>
                          </div>
                          <div class="avatar"><img class="img-80 rounded-circle" src="@/assets/images/user/3.jpg" alt="#">
                            <div class="status bg-success"></div>
                          </div>
                          <div class="avatar"><img class="img-70 rounded-circle" src="@/assets/images/user/3.jpg" alt="#">
                            <div class="status bg-info"></div>
                          </div>
                          <div class="avatar"><img class="img-60 rounded-circle" src="@/assets/images/user/3.jpg" alt="#">
                            <div class="status bg-danger"></div>
                          </div>
                          <div class="avatar"><img class="img-50 rounded-circle" src="@/assets/images/user/3.jpg" alt="#">
                            <div class="status bg-dark"></div>
                          </div>
                          <div class="avatar"><img class="img-40 rounded-circle" src="@/assets/images/user/3.jpg" alt="#">
                            <div class="status bg-warning"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</template>