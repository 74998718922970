<template>
  <div
    class="modal fade"
    id="exampleModal1"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel1"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header header-cal">
          <h5 class="modal-title" id="exampleModalLabel1" v-if="psycology">
            Psi. {{ psycology?.first_name }} {{ psycology?.last_name }}
          </h5>
          <button
            class="btn-close btn-custom"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="margin-top: 10px; background: white">
          <form class="form-bookmark">
            <div class="row g-2">
              <div class="mb-3 col-md-12">
                <textarea
                  class="form-control"
                  v-model="comment"
                  type="text"
                  placeholder="Comentar..."
                  autocomplete="off"
                />
              </div>
              <div class="div-row">
                <rate :length="5" v-model="rating" class="div-row" />
              </div>
            </div>
            <div class="div-row">
              <button
                class="btn btn-secondary text-center"
                type="button"
                @click="sendComment"
              >
                Enviar
              </button>
              <button
                class="btn btn-primary ms-2 text-center"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.btn-custom {
  width: 40px;
}

.header-cal {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.div-row {
  display: flex;
}
</style>
<script>
import StarRating from "vue-star-rating";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      comment: "",
      rating: 2,
    };
  },
  computed: {
    ...mapState({
      psycology: (state) => state.psyco.psycology,
    }),
  },
  methods: {
    ...mapActions({
      updateUser: "user/updateUser",
    }),
    currentRating() {
      return this.rating;
    },
    async sendComment() {
      console.log(this.rating, this.comment);
      const payload = {
        id: this.psycology.id,
        user_type: 4,
        calification: [
          {
            raiting: this.rating,
            observation: this.comment,
          },
        ],
      };

      await this.updateUser({ payload: payload });
      $("#exampleModal1").modal("hide");
    },
  },
};
</script>
