<template>
     <div class="row">
                          <div class="col-xxl-6 col-xl-8 box-col-8">
                            <p class="pb-4">About 6,000 results (0.60 seconds)</p>
                            <div class="d-flex info-block">
                              <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/CJnfAXlBRTE"></iframe>
                              <div class="flex-grow-1">
                                <h6>Koho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching.</h6><a href="javascript:void(0)">Kohoeducation.info/</a>
                                <p>Koho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                                <div class="star-ratings">
                                  <ul class="search-info">
                                    <li>2.5 stars</li>
                                    <li>590 votes</li>
                                    <li>Music</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex info-block">
                              <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/-L4gEk7cOfk"></iframe>
                              <div class="flex-grow-1">
                                <h6>Tivo introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching.</h6><a href="javascript:void(0)">Tivoeducation.info/</a>
                                <p>Tivo introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                                <div class="star-ratings">
                                  <ul class="search-info">
                                    <li>2.5 stars</li>
                                    <li>590 votes</li>
                                    <li>Music</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex info-block">
                              <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/I0-vBdh4sZ8"></iframe>
                              <div class="flex-grow-1">
                                <h6>Cuba introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching.</h6><a href="javascript:void(0)">Cubaeducation.info/</a>
                                <p>Cuba introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                                <div class="star-ratings">
                                  <ul class="search-info">
                                    <li>2.5 stars</li>
                                    <li>590 votes</li>
                                    <li>Music</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex info-block">
                              <iframe class="me-3 mb-3" width="200" height="100" src="https://www.youtube.com/embed/wpmHZspl4EM"></iframe>
                              <div class="flex-grow-1">
                                <h6>Enzo introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching.</h6><a href="javascript:void(0)">Enzoeducation.info/</a>
                                <p>Enzo introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                                <div class="star-ratings">
                                  <ul class="search-info">
                                    <li>2.5 stars</li>
                                    <li>590 votes</li>
                                    <li>Music</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xxl-6 col-xl-4 box-col-30">
                            <div class="row search-vid-block">
                              <div class="col-xl-12 col-md-6 box-col-12">
                                <div class="embed-responsive embed-responsive-21by9 lg-mt">
                                  <iframe class="w-100" height="315" src="https://www.youtube.com/embed/wpmHZspl4EM" allowfullscreen=""></iframe>
                                </div>
                              </div>
                              <div class="col-xl-12 col-md-6 box-col-12">
                                <div class="embed-responsive embed-responsive-21by9">
                                  <iframe class="w-100" height="315" src="https://www.youtube.com/embed/I0-vBdh4sZ8" allowfullscreen=""></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <paginationView/>
</template>
<script>
import paginationView from "./paginationView.vue"
export default {
    components:{
        paginationView
    }
}
</script>