<template>
                                        
                                          <div class="mt-0 mb-3 col-md-12">
                                            <div class="row">
                                              <div class="col-lg-2 col-sm-4">
                                                <select class="form-control" id="birth_day">
                                                  <option class="f-w-600">Day</option>
                                                  <option>01</option>
                                                  <option>02</option>
                                                  <option>03</option>
                                                  <option>04</option>
                                                  <option>05</option>
                                                  <option>06</option>
                                                  <option>07</option>
                                                  <option>08</option>
                                                  <option>09</option>
                                                  <option>10</option>
                                                  <option>11</option>
                                                  <option>12</option>
                                                  <option>13</option>
                                                  <option>14</option>
                                                  <option>15</option>
                                                  <option>16</option>
                                                  <option>17</option>
                                                  <option>18</option>
                                                  <option>19</option>
                                                  <option>20</option>
                                                  <option>21</option>
                                                  <option>22</option>
                                                  <option>23</option>
                                                  <option>24</option>
                                                  <option>25</option>
                                                  <option>26</option>
                                                  <option>27</option>
                                                  <option>28</option>
                                                  <option>29</option>
                                                  <option>30</option>
                                                  <option>31</option>
                                                </select>
                                              </div>
                                              <div class="col-lg-3 col-sm-4">
                                                <select class="form-control" id="birth_month">
                                                  <option class="f-w-600">Month</option>
                                                  <option>January</option>
                                                  <option>February</option>
                                                  <option>March</option>
                                                  <option>April</option>
                                                  <option>May</option>
                                                  <option>June</option>
                                                  <option>July</option>
                                                  <option>August</option>
                                                  <option>September</option>
                                                  <option>October</option>
                                                  <option>November</option>
                                                  <option>December</option>
                                                </select>
                                              </div>
                                              <div class="col-lg-3 col-sm-4">
                                                <input class="form-control" id="birth_year" type="text">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="mt-0 mb-3 col-md-12">
                                            <div class="row">
                                              <div class="col-sm-6">
                                                <label>Personality</label>
                                                <input class="form-control" id="personality" type="text"  autocomplete="off">
                                              </div>
                                              <div class="col-sm-6">
                                                <label>Interest</label>
                                                <input class="form-control" id="interest" type="text"  autocomplete="off">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="mb-3 col-md-12">
                                            <label>Home Address</label>
                                            <div class="row">
                                              <div class="col-12">
                                                <div class="form-group"><input class="form-control" type="text" placeholder="Address"></div>
                                              </div>
                                              <div class="col-sm-6">
                                                <div class="form-group"><input class="form-control" id="city" type="text" placeholder="City"></div>
                                              </div>
                                              <div class="col-sm-6">
                                                <div class="form-group"><input class="form-control" type="text" placeholder="State"></div>
                                              </div>
                                              <div class="col-sm-6">
                                                <div><input class="form-control" type="text" placeholder="Country"></div>
                                              </div>
                                              <div class="col-sm-6">
                                                <div><input class="form-control" type="text" placeholder="Pin Code"></div>
                                              </div>
                                            </div>
                                          </div>       
</template>