<template>
     <div class="card total-investment">
                  <div class="card-header pb-0">
                    <div class="d-flex justify-content-between">
                      <div class="flex-grow-1"> 
                        <p class="square-after f-w-600 header-text-primary">Total Investment<i class="fa fa-circle"> </i></p>
                        <h4>96.564%</h4>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="progress sm-progress-bar">
                      <div class="progress-colors" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        <div class="bg-secondary progress-1"></div>
                        <div class="bg-primary progress-2"></div>
                      </div>
                    </div>
                    <div class="bottom-progress"><span class="badge round-badge-primary font-worksans">3.56% <i class="fa fa-caret-up"></i></span><span class="pull-right font-primary font-worksans f-w-700">75%</span></div>
                   
                  </div>
                </div>
</template>