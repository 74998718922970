<template>
    <div class="col-xl-6 col-sm-12">
                    <form>
                      <div class="row">
                        <div class="mb-3 col-sm-6">
                          <label for="inputEmail4">First Name</label>
                          <input class="form-control" id="inputEmail4" type="text">
                        </div>
                        <div class="mb-3 col-sm-6">
                          <label for="inputPassword4">Last Name</label>
                          <input class="form-control" id="inputPassword4" type="text">
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3 col-sm-6">
                          <label for="phonenumber">Phone</label>
                          <input class="form-control" id="phonenumber" type="number">
                        </div>
                        <div class="mb-3 col-sm-6">
                          <label for="inputPassword7">Email Address</label>
                          <input class="form-control" id="inputPassword7" type="email">
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="inputState">Country</label>
                        <select class="form-control" id="inputState">
                          <option selected>Choose...</option>
                          <option>...</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="inputAddress5">Address</label>
                        <input class="form-control" id="inputAddress5" type="text">
                      </div>
                      <div class="mb-3">
                        <label for="inputCity">Town/City</label>
                        <input class="form-control" id="inputCity" type="text">
                      </div>
                      <div class="mb-3">
                        <label for="inputAddress2">State/Country</label>
                        <input class="form-control" id="inputAddress2" type="text">
                      </div>
                      <div class="mb-3">
                        <label for="inputAddress6">Postal Code</label>
                        <input class="form-control" id="inputAddress6" type="number">
                      </div>
                      <div class="mb-3">
                        <div class="form-check">
                          <input class="form-check-input" id="gridCheck" type="checkbox">
                          <label class="form-check-label" for="gridCheck">Check me out</label>
                        </div>
                      </div>
                    </form>
                  </div>
</template>