<template>
  <div class="col-xl-12  d-none">
    <div class="card">
      <div class="card-header">
        <h5 class="p-0">
          <button
            class="btn btn-link ps-0"
            data-bs-toggle="collapse"
            data-bs-target="#collapseicon"
            :aria-expanded="isActive ? 'true' : 'false'"
            aria-controls="collapseicon"
            :class="[isActive ? 'active' : '']"
            v-on:click="toggle"
          >
            Mi Perfil
          </button>
        </h5>
      </div>
      <div :class="[isActive ? 'block' : ' show']" v-show="isActive">
        <div class="card-body socialprofile filter-cards-view">
          <div class="d-flex align-items-center">
            <img
              class="img-50 img-fluid m-r-20 rounded-circle"
              :src="userInfo?.profile_photo"
              alt=""
            />
            <div class="flex-grow-1">
              <h5 class="font-primary f-w-600">{{ userInfo.first_name }}</h5>
              <span class="d-block"
                ><span
                  ><i class="fa fa-comments-o"> </i
                  ><span class="px-2"
                    >Messages<span class="badge rounded-pill badge-light ms-1"
                      >9</span
                    ></span
                  ></span
                ></span
              ><span class="d-block"
                ><span
                  ><i class="fa fa-bell-o"> </i
                  ><span class="px-2"
                    >Notification<span
                      class="badge rounded-pill badge-light ms-1"
                      >9</span
                    ></span
                  ></span
                ></span
              >
            </div>
          </div>
          <!-- <div class="social-btngroup d-flex">
                <button class="btn btn-primary text-center me-2" type="button">Likes</button>
                <button class="btn btn-light text-center" type="button">View</button>
              </div> -->
          <div class="likes-profile">
            <h4 class="f-w-600 mb-1">
              <i class="fa fa-heart font-danger pt-3"></i> 884 Likes
            </h4>
          </div>
          <!-- <h6 class="text-center">35 New Likes This Week</h6>
              <div class="customers text-center social-group">
                <ul>
                  <li class="d-inline-block"><img class="img-30 rounded-circle" src="@/assets/images/user/3.jpg" alt="" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Johny Waston"></li>
                  <li class="d-inline-block"><img class="img-30 rounded-circle" src="@/assets/images/user/5.jpg" alt="" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Andew Jon"></li>
                  <li class="d-inline-block"><img class="img-30 rounded-circle" src="@/assets/images/user/1.jpg" alt="" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Comeren Diaz"></li>
                  <li class="d-inline-block"><img class="img-30 rounded-circle" src="@/assets/images/user/2.png" alt="" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Bucky Barnes"></li>
                  <li class="d-inline-block"><img class="img-30 rounded-circle" src="@/assets/images/user/8.jpg" alt="" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Jason Borne"></li>
                  <li class="d-inline-block"><img class="img-30 rounded-circle" src="@/assets/images/user/11.png" alt="" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Comeren Diaz"></li>
                </ul>
              </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      isActive: true,
      user: {
        banner:
          "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg",
        img: "https://dev.sigma.net.pe/img/man.76101d57.png",
        name: "Leonardo campos",
        position: "Psicologo",
        social_media: {
          fb: "https://www.facebook.com/",
          x: "https://twitter.com/",
          ig: "https://www.instagram.com/",
        },
        friends: 25,
        following: 200,
        likes: 1000,
        files: [
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
        ],
      },
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user || {},
    }),
  },
};
</script>
