<template>
    <div class="card">
              <div class="row product-page-main">
                <div class="col-sm-12">
                  <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="top-home-tab" data-bs-toggle="tab" href="#top-home" role="tab" aria-controls="top-home" aria-selected="false">Febric</a>
                      <div class="material-border"></div>
                    </li>
                    <li class="nav-item"><a class="nav-link" id="profile-top-tab" data-bs-toggle="tab" href="#top-profile" role="tab" aria-controls="top-profile" aria-selected="false">Video</a>
                      <div class="material-border"></div>
                    </li>
                    <li class="nav-item"><a class="nav-link" id="contact-top-tab" data-bs-toggle="tab" href="#top-contact" role="tab" aria-controls="top-contact" aria-selected="true">Details</a>
                      <div class="material-border"></div>
                    </li>
                    <li class="nav-item"><a class="nav-link" id="brand-top-tab" data-bs-toggle="tab" href="#top-brand" role="tab" aria-controls="top-brand" aria-selected="true">Brand</a>
                      <div class="material-border"></div>
                    </li>
                  </ul>
                  <div class="tab-content" id="top-tabContent">
                    <div class="tab-pane fade active show" id="top-home" role="tabpanel" aria-labelledby="top-home-tab">
                      <p class="mb-0 m-t-20">Refresh your wardrobe with this chic top. With an eye-catching square neck,
                           this top also features pretty puff sleeves. Stunning pink colour Classic solid pattern Square 
                           neck Elasticated puff sleeves Belt included, Polyester fabric, machine wash.."</p>
                           
                      <p class="mb-0 m-t-20">Tee Stores is an Indian contemporary clothing brand. 
                          The product pages display a fine quality fabric with colorful description.
                           We offer many vivid designs, art, styles that "combine heritage with modernity,
                            simplicity, playfulness and street style"."</p>
                    </div>
                    <div class="tab-pane fade" id="top-profile" role="tabpanel" aria-labelledby="profile-top-tab">
                      <p class="mb-0 m-t-20">Lorate Solid Men's Fashion Full Sleeves Latest Jacket for Men With<br>Button Closure Long Sleeve Casual Torn Lycra Denim Jacket.</p>
                    </div>
                    <div class="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">
                      <p class="mb-0 m-t-20">Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton<br>T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve.</p>
                    </div>
                    <div class="tab-pane fade" id="top-brand" role="tabpanel" aria-labelledby="brand-top-tab">
                      <p class="mb-0 m-t-20">Product Dimensions : 18 x 18 x 4 cm<br>Date First Available : 31 March 2023<br>Manufacturer : Tee Stores<br>Item part number : TS-WT721-XS-WHITE</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</template>