<template>
  <div class="chat-message clearfix">
    <div class="row">
      <div class="col-xl-12 d-flex">
        <div class="smiley-box bg-primary">
          <div class="picker">
            <img src="@/assets/images/smiley.png" alt="" />
          </div>
        </div>
        <div class="input-group text-box">
          <input class="form-control input-txt-bx" id="message-to-send" v-model="text" v-on:keyup.enter="addChat()"
            type="text" name="message-to-send" placeholder="Escribir mensaje" />
          <button @click="addChat()" class="btn btn-primary input-group-text" type="button">
            SEND
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      text: "",
      currentchat: [],
      chatmenutoogle: false,
    };
  },
  computed: {
    ...mapState({
      currentChat() {
        return (this.currentchat = this.$store.getters["chat/currentChat"]);
      },
      activeUser: (state) => state.chats.activeUser
    }),
  },
  methods: {
    ...mapActions({
      sendMsg: "chats/createMsg",
      getSendMsg: "chats/getSendMsg"
    }),
    getImgUrl(path) {
      return require("@/assets/images/" + path);
    },
    async addChat() {
      if (this.text === '') return;
      const userID = localStorage.getItem("userId");
      let data = {
        id_remitting: userID,
        id_addressee: this.activeUser?.id,
        message: this.text
      }
      await this.sendMsg({ payload: data })
      let payload = {
        id: localStorage.getItem("userId")
      };
      this.text = '';
      await this.getSendMsg({ payload: payload })
    },
  },
};
</script>
