<template>
    <div class="col-sm-12 col-md-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h4>Multiple Sections with Headers</h4><span>Two datasets that are prefetched, stored, and searched on the client. Highlighting is enabled.</span>
                    </div>
                    <div class="card-body">
                      <div id="multiple-datasets">
                        <form class="theme-form">
                          <div>
                            <input class="typeahead form-control" type="text" placeholder="NBA and NHL teams">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
</template>