import axios from "axios";

const HOST = process.env.VUE_APP_API_PSYCOLOGOS;

export default {
  async getPsycos(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/psychologist/list/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getCharts(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/psyco/chart/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getChartsInstitutions(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/institution/chart/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
