<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>No Border</h4><span>By adding .plain-style class to .mega-inline You can archive this style</span>
                  </div>
                  <div class="card-body">
                    <form class="mega-inline plain-style">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="d-flex p-20">
                              <div class="form-check radio radio-primary me-3">
                                <input class="form-check-input" id="radio11" type="radio" name="radio1" value="option1">
                                <label class="form-check-label" for="radio11"></label>
                              </div>
                              <div class="flex-grow-1">
                                <h6 class="mt-0 mega-title-badge">COD<span class="badge badge-primary pull-right digits">50 INR</span></h6>
                                <p class="mb-0">Estimated 1 Day Shipping ( Duties end tax may be due delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="d-flex p-20">
                              <div class="form-check radio radio-secondary me-3">
                                <input class="form-check-input" id="radio12" type="radio" name="radio1" value="option1">
                                <label class="form-check-label" for="radio12"></label>
                              </div>
                              <div class="flex-grow-1">
                                <h6 class="mt-0 mega-title-badge">Fast<span class="badge badge-secondary pull-right digits">100 INR</span></h6>
                                <p class="mb-0">Estimated 1 Day Shipping ( Duties end tax may be due delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="card-footer text-end">
                    <button class="btn btn-primary m-r-15" type="submit">Submit</button>
                    <button class="btn btn-light" type="submit">Cancel</button>
                  </div>
                </div>
              </div>
               <div class="col-sm-12 col-xl-6 box-col-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Solid Border Style</h4><span>By adding .border-style class to .mega-inline You can archive this style</span>
                  </div>
                  <div class="card-body megaoptions-border-space-sm">
                    <form class="mega-inline border-style">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="d-flex p-20">
                              <div class="form-check radio radio-primary me-3">
                                <input class="form-check-input" id="radio15" type="radio" name="radio1" value="option1">
                                <label class="form-check-label" for="radio15"></label>
                              </div>
                              <div class="flex-grow-1">
                                <h6 class="mt-0 mega-title-badge">COD<span class="badge badge-primary pull-right digits">50 INR</span></h6>
                                <p class="mb-0">Estimated 1 Day Shipping ( Duties end tax may be due delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="card">
                            <div class="d-flex p-20">
                              <div class="form-check radio radio-primary me-3">
                                <input class="form-check-input" id="radio16" type="radio" name="radio1" value="option1">
                                <label class="form-check-label" for="radio16"></label>
                              </div>
                              <div class="flex-grow-1">
                                <h6 class="mt-0 mega-title-badge">Fast<span class="badge badge-secondary pull-right digits">100 INR</span></h6>
                                <p class="mb-0">Estimated 1 Day Shipping ( Duties end tax may be due delivery )</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="card-footer text-end">
                    <button class="btn btn-primary m-r-15" type="submit">Submit</button>
                    <button class="btn btn-light" type="submit">Cancel</button>
                  </div>
                </div>
              </div>
</template>