<template>
  <div class="card_tab">
    <div class="content-header">
      <Breadcrumbs main="" title="Datos Generales" />
      <button class="small-primary-button" @click="submitPatient">
        <i class="fa fa-bookmark-o mx-1" aria-hidden="true"></i>
        Guardar y continuar
      </button>

   <!-- Botón nuevo de "Guardar y Salir" -->
   <button class="small-primary-button" @click="submitAndExit">
      <i class="fa fa-sign-out mx-1" aria-hidden="true"></i>
      Guardar y Salir
    </button>

    </div>
    <div class="card-body">
      <div class="row g-3">
        <div v-if="errors.length > 0" class="alert alert-danger">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Nombre</label>
          <input
            class="form-control"
            type="text"
            placeholder="Nombre"
            v-model="form.first_name"
            :class="{
              'is-invalid': submit && !form.first_name,
            }"
          />
          <span
            v-if="submit && !form.first_name"
            class="error-message text-danger"
          >
            El nombre es requerido.
          </span>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Apellidos</label>
          <input
            class="form-control"
            type="text"
            placeholder="Apellidos"
            v-model="form.last_name"
            :class="{
              'is-invalid': submit && !form.last_name,
            }"
          />
          <span
            v-if="submit && !form.last_name"
            class="error-message text-danger"
          >
            El apellido es requerido.
          </span>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Correo</label>
          <input
            class="form-control"
            type="text"
            placeholder="Correo"
            v-model="form.email"
            :class="{
              'is-invalid': submit && !form.email,
            }"
          />
          <span
            v-if="submit && !isValidEmail(form.email)"
            class="error-message text-danger"
          >
            Ingresa un correo válido.
          </span>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">DNI</label>
          <input
            class="form-control"
            type="text"
            placeholder="DNI"
            v-model="form.DNI"
            :class="{
              'is-invalid': submit && !form.DNI,
            }"
          />
          <span v-if="submit && !form.DNI" class="error-message text-danger">
            El DNI es requerido.
          </span>
        </div>
        <div class="mb-3 col-lg-2 col-md-3 col-12">
          <label class="col-form-label">Fecha de Nacimiento</label>
          <input
            class="form-control"
            type="date"
            ref="dateOfBirthInput"
            @input="limitDateOfBirth"
            v-model="form.date_of_birth"
            :format="format"
            :max="maxDate"
            placeholder="Fecha de nacimiento"
          />
          <span
            v-if="submit && !form.date_of_birth"
            class="error-message text-danger"
          >
            La fecha de nacimiento
          </span>
        </div>
        <div class="mb-3 col-lg-1 col-md-3 col-12">
          <label class="col-form-label">Edad</label>
          <input
            class="form-control"
            type="text"
            placeholder="Edad"
            v-model="form.age"
          />
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Sexo</label>
          <select
            class="form-select digits"
            id="exampleFormControlSelect9"
            v-model="form.gender"
            placeholder="Seleccionar"
          >
            <option value="male">Masculino</option>
            <option value="female">Femenino</option>
          </select>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Estado civil</label>
          <select
            class="form-select digits"
            id="exampleFormControlSelect9"
            v-model="form.civil_state"
            placeholder="seleccionar"
          >
            <option value="Single">Soltero</option>
            <option value="Married">Casado</option>
            <option value="Cohabitant">Conviviente</option>
            <option value="Separate">Separado</option>
            <option value="Widower">Viudo</option>
          </select>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Grado de Instrucción</label>
          <select
            class="form-select digits"
            id="exampleFormControlSelect9"
            v-model="form.education_grade"
            placeholder="instruccion"
          >
            <option value="1">Jardin</option>
            <option value="2">Primaria</option>
            <option value="3">Secundaria completa</option>
            <option value="4">Tecnico</option>
            <option value="5">Universitario</option>
            <option value="6">Master</option>
            <option value="7">Doctorado</option>
          </select>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Dirección</label>
          <input
            class="form-control"
            type="text"
            placeholder="Dirección"
            v-model="form.address"
          />
        </div>
        <div v-if="userInfo?.user_type === 3" class="col-md-8">
          <label class="form-label" for="validationCustom04"
            >Psicologo Asignado</label
          >
          <select
            v-model="form.psychologist_id"
            id="inputGenre"
            class="form-select"
          >
            <option
              v-for="item in psychologists"
              :key="item.id"
              :value="item.id"
            >
              {{ item.first_name }}{{ item.last_name }}
            </option>
          </select>
        </div>
        <div
          v-if="change.enable_sys !== null"
          class="mb-3 col-lg-3 col-md-3 col-12"
        >
          <label class="col-form-label">Estado del Paciente</label>
          <select
            v-model="change.enable_sys"
            @change="changeStatus"
            class="form-select digits"
            id="exampleFormControlSelect9"
          >
            <option :value="true">Activo</option>
            <option :value="false">Archivado</option>
          </select>
        </div>
        <div class="mb-3 col-lg-3 col-md-3 col-12">
          <label class="col-form-label">Familiares responsables</label>
          <div class="form-check checkbox mt-2">
            <input
              class="form-check-input"
              id="checkbox3"
              type="checkbox"
              v-model="family"
              checked=""
            />
            <label class="form-check-label" for="checkbox3">Ninguno</label>
          </div>
        </div>

        <div v-if="family === false">
          <div v-if="family === false">
            <!--<h3 class="mt-3 mb-2 h3_sub">Familiar Directo</h3>-->
            <div
              v-for="(item, idx) in directFamily"
              :key="idx"
              class="family-form row"
            >
              <h3 class="mt-3 mb-2 h3_fd">
                <i
                  style="cursor: pointer; font-size: 13px"
                  class="fa fa-minus"
                  @click="removeFamilyMember(idx)"
                ></i>
                Familiar Directo
              </h3>

              <div class="mb-3 col-lg-6 col-md-6 col-12">
                <label class="col-form-label"
                  >Nombre y apellidos del 1er familiar responsable</label
                >
                <input
                  v-model="item.name"
                  class="form-control"
                  type="text"
                  placeholder="Nombre y apellidos del 1er familiar responsable"
                />
              </div>

              <div class="mb-3 col-lg-3 col-md-3 col-12">
                <label class="col-form-label">Vinculo</label>
                <select
                  v-model="item.family_bond"
                  class="form-select digits"
                  id="exampleFormControlSelect9"
                >
                  <option value="padre">Padre</option>
                  <option value="madre">Madre</option>
                  <option value="pareja">Pareja</option>
                  <option value="hijos">Hijos</option>
                  <option value="otros">Otros</option>
                </select>
              </div>

              <div class="mb-3 col-lg-3 col-md-3 col-12">
                <label class="col-form-label">Celular</label>
                <input
                  v-model="item.phone"
                  class="form-control"
                  type="text"
                  placeholder="Celular"
                />
              </div>

              <div class="mb-3 col-lg-3 col-md-3 col-12">
                <label class="col-form-label">Email</label>
                <input
                  v-model="item.email"
                  class="form-control"
                  type="email"
                  placeholder="Email"
                />
              </div>

              <div class="mb-3 col-lg-3 col-md-3 col-12">
                <label class="col-form-label">Dirección</label>
                <input
                  v-model="item.adress"
                  class="form-control"
                  type="text"
                  placeholder="Direccion"
                />
              </div>
            </div>

            <p
              style="cursor: pointer; margin-top: 2rem"
              @click="addFamilyMember(directFamilyMember)"
            >
              <i class="fa fa-plus"></i> AÑADIR OTRO FAMILIAR
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.card_tab {
  background: white;
  padding: 20px !important;
  margin-bottom: 20px;
  border-radius: 20px;
}

.content-header {
  display: flex;
  justify-content: space-between;
}
.h3_fd {
  font-size: 22px;
  font-weight: 400;
}
.h3_sub {
  font-size: 24px;
}
.dp__input_wrap input {
  height: 47px;
}
.small-primary-button {
  width: 200px;
  font-size: 0.8rem;
  /* Adjust the font size to make it smaller */
  padding: 0px;
  /* Adjust the padding to make it smaller */
  background-color: #4f9cd6;
  /* Customize the background color */
  color: #fff;
  /* Customize the text color */
  border: none;
  /* Remove the border */
  cursor: pointer;
  font-weight: 700;
  border-radius: 20px;
  height: 40px;
  margin-right: 10px;
}
</style>
<script>
import { ref } from "vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      errors: [],
      family: false,
      submit: false,
      change: {
        enable_sys: null,
      },
      form: {
        first_name: "",
        last_name: "",
        DNI: "",
        date_of_birth: "",
        age: null,
        gender: "",
        civil_state: "",
        education_grade: "",
        email: "",
        user_type: 6,
        person_type: "natural",
        address: "",
        phone: "",
        psychologist_id: "",
        habiliability: 1,
        family_patient: [],
        enable_sys: true,
      },
      directFamilyMember: {
        name: "",
        family_bond: "",
        adress: "",
        phone: "",
        email: "",
      },
      directFamily: [
        {
          name: "",
          family_bond: "",
          adress: "",
          phone: "",
          email: "",
        },
      ],
    };
  },
  watch: {
    "form.date_of_birth": function (newDate, oldDate) {
      this.calculateAge(newDate);
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
      patient: (state) => state.user.patient,
      psychologists: (state) => state.psyco.psychologists,
    }),
    maxDate() {
      const today = new Date();
      today.setFullYear(today.getFullYear() - 5);
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  async mounted() {
    const info = {
      id: localStorage.getItem("userId"),
    };
    await this.getUser({ payload: info });
    if (this.userInfo.user_type === 3) {
      this.psycos();
    }
    this.setData();
    if (this.$route.params.id !== undefined) {
      const data = {
        id: this.$route.params.id,
      };
      await this.getUserPatient({ payload: this.$route.params.id });
      this.setDataPatient();
    }
  },
  methods: {
    ...mapActions({
      updateUser: "user/updateUser",
      getUser: "user/getUser",
      setStep: "patients/setStep",
      getUserPatient: "user/getUserPatient",
      psycos: "psyco/getPsyco",
    }),
    validateForm() {
      this.errors = [];

      if (!this.form.first_name.trim()) {
        this.errors.push("El campo Nombres es requerido");
      }

      if (!this.form.last_name.trim()) {
        this.errors.push("El campo Apellidos es requerido");
      }

      if (!this.form.DNI.trim()) {
        this.errors.push("El campo DNI es requerido");
      }

      if (!this.form.email.trim()) {
        this.errors.push("El campo email es requerido");
      }
      return this.errors.length === 0;
    },

    limitDateOfBirth() {
      const currentDate = new Date().toISOString().split("T")[0];

      this.$refs.dateOfBirthInput.max = currentDate;
    },
    calculateAge(birthdate) {
      if (!birthdate) {
        this.form.age = null;
        return;
      }

      const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      this.form.age = age;
    },
    setData() {
      if (this.userInfo.user_type !== 3) {
        this.form.psychologist_id = this.userInfo.id;
      }
    },
    setDataPatient() {
      for (const prop in this.patient) {
        if (this.patient.hasOwnProperty(prop)) {
          this.form[prop] = this.patient[prop];
        }
      }

      this.directFamily = this.form.family_patient;
      this.change.enable_sys = this.form.enable_sys;
    },
    addFamilyMember(directFamilyMember) {
      this.directFamily.push({ ...directFamilyMember });
    },
    removeFamilyMember(idx) {
      this.directFamily.splice(idx, 1);
    },
    isValidEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    },
    async changeStatus() {
      try {
        this.change.id = this.$route.params.id;
        this.change.user_type = this.patient.user_type;
        const response = await this.updateUser({ payload: this.change });
        this.$toast.show("Se cambio de estado exitosamente", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
      } catch (e) {
        this.$toast.show("No se pudo cambiar de estado", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
    async submitPatient() {
      try {
        this.submit = true;

        if (this.validateForm()) {
          this.form.family_patient = this.directFamily;
          this.$emit("join-data", this.form);
          this.$toast.show("Continuamos exitosamente ", {
            theme: "outline",
            position: "top-right",
            icon: "times",
            type: "success",
            duration: 2000,
          });
          const step = "queryReason";
          this.setStep({ payload: step });
        } else {
          return;
        }
      } catch (error) {
        this.$toast.show("Verificar bien los datos de ingreso", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },

    async submitAndExit() {
      try {
        this.submit = true;

        if (this.validateForm()) {
          this.form.s = this.directFamily;
          this.$emit("join-data", this.form);
          this.$toast.show("Guardado exitosamente ", {
            theme: "outline",
            position: "top-right",
            icon: "times",
            type: "success",
            duration: 2000,
          });
          setTimeout(() => {
          this.$router.push({ name: "myConsultingRoom" });
          }, 1000); 
        } else {
          return;
        }
      } catch (error) {
        this.$toast.show("Verificar bien los datos de ingreso", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
  setup() {
    const date = ref();
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return ` ${day}/${month}/${year}`;
    };

    return {
      date,
      format,
    };
  },
};
</script>
