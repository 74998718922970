<template>
  <div class="card card_perfil" id="default">
    <div class="card-header p-0 ">
      <div class="flex_perfil">
        <div class="si_perfil_flex">
          <div class="">
          <img
            src="https://via.placeholder.com/200x150"
            alt="Grupo Manchego S.A.C."
          />
        </div>
        <div class="info_perfil">
          <router-link to="/users/profile">
            <h6 class="mt-0">
              {{ userInfo?.first_name }} {{ userInfo?.last_name }}
              <span class="p-0"> ( Psicologo )</span>
            </h6>
          </router-link>
          <p><strong>RUC: </strong> {{ userInfo?.RUC }}</p>  
          <p>
            <a href="">{{ userInfo?.email }} </a>
          </p>    
        </div> 
        </div>
        <div class="btn-showcase">
          <div class="flex_buttons_btn">
            <router-link to="/agregar-psicologo">
            <button class="btn btn-primary" type="button">
              Nuevo Psicologo
            </button></router-link
          >
          <router-link to="/nuevo-paciente">
            <button class="btn btn_new_paciente" type="button">
              Nuevo Paciente
            </button>
          </router-link>
          </div>
        </div>               
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .card_perfil{
    padding: 20px 20px; 
    .comment-box{
      margin: 0px 0px 20px 0px;
    }
    .btn_new_paciente{
      background-color: #1c4375 !important;
      border-color: #1c4375 !important;
      color: #ffffff;
    }
  }
  .btn_new_paciente:hover{
    color: #ffffff !important;
  }
  .flex_perfil{
    display: flex;
    .si_perfil_flex{
      width: 50%;
      display: flex;
    }
    .btn-showcase{
      width: 50%;
      .flex_buttons_btn{
        float: right;
      }
    }
    .info_perfil{
      padding: 0px 20px;
      p{
        margin-bottom: 8px;
      }
    }
  }
</style>
<script>
export default {
  props: ["userInfo"],
  methods: {
    handleImageError() {
      this.userInfo.profile_photo = "@/assets/images/user-card/1.jpg";
    },
  },
};
</script>
