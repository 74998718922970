<template>
    <div class="autocomplete">
      <input
        v-model="inputValue"
        @input="handleInput"
        @focus="showDropdown"
        @blur="hideDropdown"
        class="form-control"
        type="text"
        placeholder="Buscar..."
      />
      <ul v-if="isDropdownVisible" class="dropdown-menu">
        <li
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="selectItem(item)"
          class="dropdown-item"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        inputValue: '',
        isDropdownVisible: false,
      };
    },
    props: {
      items: Array,
    },
    computed: {
      filteredItems() {
        return this.items.filter(item =>
          item.nickname.toLowerCase().includes(this.inputValue.toLowerCase())
        );
      },
    },
    methods: {
      handleInput() {
        this.isDropdownVisible = true;
      },
      showDropdown() {
        this.isDropdownVisible = true;
      },
      hideDropdown() {
        setTimeout(() => {
          this.isDropdownVisible = false;
        }, 200);
      },
      selectItem(item) {
        this.inputValue = item;
        this.isDropdownVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .autocomplete {
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
  }
  </style>