<template>
     <div class="card">
                  <div class="card-header pb-0"><h4>Animation</h4><span>There are serveral predefined animations you can use already:</span></div>
                  <div class="card-body">
                    <ul class="line">
                      <li>
                        <h5>Fade animations:</h5>
                        <ul class="ps-4 mb-4 list-circle">
                          <li>fade</li>
                          <li>fade-up</li>
                          <li>fade-down</li>
                          <li>fade-left</li>
                          <li>fade-right</li>
                          <li>fade-up-right</li>
                          <li>fade-up-left</li>
                          <li>fade-down-right</li>
                          <li>fade-down-left</li>
                        </ul>
                      </li>
                      <li>
                        <h5>Flip animations:</h5>
                        <ul class="ps-4 mb-4 list-circle">
                          <li>flip-up</li>
                          <li>flip-down</li>
                          <li>flip-left</li>
                          <li>flip-right</li>
                        </ul>
                      </li>
                      <li>
                        <h5>Slide animations:</h5>
                        <ul class="ps-4 mb-4 list-circle">
                          <li>slide-up</li>
                          <li>slide-down</li>
                          <li>slide-left</li>
                          <li>slide-right</li>
                        </ul>
                      </li>
                      <li>
                        <h5>Zoom animations:</h5>
                        <ul class="ps-4 list-circle">
                          <li>zoom-in</li>
                          <li>zoom-in-up</li>
                          <li>zoom-in-down</li>
                          <li>zoom-in-left</li>
                          <li>zoom-in-right</li>
                          <li>zoom-out</li>
                          <li>zoom-out-up</li>
                          <li>zoom-out-down</li>
                          <li>zoom-out-left</li>
                          <li>zoom-out-right</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0"><h4>Anchor placement:</h4></div>
                  <div class="card-body">
                    <ul class="ps-4 list-circle line">
                      <li>top-bottom</li>
                      <li>top-center</li>
                      <li>top-top</li>
                      <li>center-bottom</li>
                      <li>center-center</li>
                      <li>center-top</li>
                      <li>bottom-bottom</li>
                      <li>bottom-center</li>
                      <li>bottom-top</li>
                    </ul>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0"><h4>Easing functions:</h4></div>
                  <div class="card-body">
                    <ul class="ps-4 list-circle line">
                      <li>linear</li>
                      <li>ease</li>
                      <li>ease-in</li>
                      <li>ease-out</li>
                      <li>ease-in-out</li>
                      <li>ease-in-back</li>
                      <li>ease-out-back</li>
                      <li>ease-in-out-back</li>
                      <li>ease-in-sine</li>
                      <li>ease-out-sine</li>
                      <li>ease-in-out-sine</li>
                      <li>ease-in-quad</li>
                      <li>ease-out-quad</li>
                      <li>ease-in-out-quad</li>
                      <li>ease-in-cubic</li>
                      <li>ease-out-cubic</li>
                      <li>ease-in-out-cubic</li>
                      <li>ease-in-quart</li>
                      <li>ease-out-quart</li>
                      <li>ease-in-out-quart</li>
                    </ul>
                  </div>
                </div>
</template>