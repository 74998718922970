<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Default bootstrap breadcrumb</h4><span>use class <code>.breadcrumb to ol</code></span>
                  </div>
                  <div class="card-body">
                    <ol class="breadcrumb bg-white p-t-0 p-l-0">
                      <li class="breadcrumb-item active">Home</li>
                    </ol>
                    <ol class="breadcrumb bg-white p-l-0">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                      <li class="breadcrumb-item active">Library</li>
                    </ol>
                    <ol class="breadcrumb bg-white p-l-0">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Library</a></li>
                      <li class="breadcrumb-item active">Data</li>
                    </ol>
                    <ol class="breadcrumb bg-white m-b-0 p-b-0 p-l-0">
                      <li class="breadcrumb-item"><a href="javascript:void(0)"><i class="fa fa-home"></i></a></li>
                      <li class="breadcrumb-item">Library</li>
                      <li class="breadcrumb-item active">Data</li>
                    </ol>
                  </div>
                </div>
              </div>
</template>