import * as types from "../mutation-types";
import chatAPI from "@/api/chat";

const state = {
  sendMsg: [],
  recivedMsg: [],
  activeUser: "",
};

const getters = {};

const actions = {
  async createMsg({ commit }, payload) {
    try {
      const response = await chatAPI.createMsg(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getRecivedMsg({ commit }, payload) {
    try {
      const response = await chatAPI.getRecivedMsg(payload);
      const recivedMsg = response.data;
      commit(types.SET_RECIVED_MSG, { recivedMsg });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getSendMsg({ commit }, payload) {
    try {
      const response = await chatAPI.getSendMsg(payload);
      const sendMsg = response.data;
      commit(types.SET_SEND_MSG, { sendMsg });
      return response;
    } catch (error) {
      throw error;
    }
  },
  setActiveUser: ({ commit }, payload) => {
    let activeUser = payload;
    commit(types.SET_USER_ACTIVE, { activeUser });
  },
};

const mutations = {
  [types.SET_SEND_MSG](state, { sendMsg }) {
    state.sendMsg = sendMsg;
  },
  [types.SET_RECIVED_MSG](state, { recivedMsg }) {
    state.recivedMsg = recivedMsg;
  },
  [types.SET_USER_ACTIVE](state, { activeUser }) {
    state.activeUser = activeUser;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
