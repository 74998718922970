<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Display headings</h4><span>Traditional heading elements are designed to work best in the meat of your page content. When you need a heading to stand out, consider using a <strong>display heading</strong>—a larger, slightly more opinionated heading style.</span>
                  </div>
                  <div class="card-body typography">
                    <h1 class="display-1">Display 1</h1>
                    <h1 class="display-2">Display 2</h1>
                    <h1 class="display-3">Display 3</h1>
                    <h1 class="display-4">Display 4</h1>
                  </div>
                </div>
              </div>
               <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Lead</h4><span>Make a paragraph stand out by adding <code>.lead</code>.</span>
                  </div>
                  <div class="card-body">
                    <p class="lead mb-0">Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.</p>
                  </div>
                </div>
              </div>
</template>