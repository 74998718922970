<template>
     <div class="col-lg-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Padding Left</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes theme-scrollbar">.p-l-0 {
padding-left: 0px;
}
.p-l-5 {
padding-left: 5px;
}
.p-l-10 {
padding-left: 10px;
}
.p-l-15 {
padding-left: 15px;
}
.p-l-20 {
padding-left: 20px;
}
.p-l-25 {
padding-left: 25px;
}
.p-l-30 {
padding-left: 30px;
}
.p-l-35 {
padding-left: 35px;
}
.p-l-40 {
padding-left: 40px;
}
.p-l-45 {
padding-left: 45px;
}
.p-l-50 {
padding-left: 50px;
}</pre>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Padding Right</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes theme-scrollbar">.p-r-0 {
padding-right: 0px;
}
.p-r-5 {
padding-right: 5px;
}
.p-r-10 {
padding-right: 10px;
}
.p-r-15 {
padding-right: 15px;
}
.p-r-20 {
padding-right: 20px;
}
.p-r-25 {
padding-right: 25px;
}
.p-r-30 {
padding-right: 30px;
}
.p-r-35 {
padding-right: 35px;
}
.p-r-40 {
padding-right: 40px;
}
.p-r-45 {
padding-right: 45px;
}
.p-r-50 {
padding-right: 50px;
}</pre>
                  </div>
                </div>
              </div>
</template>