<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
                  <div class="card">
                    <div class="card-header">
                      <h4>Groups</h4>
                    </div>
                    <div class="card-body"> 
                      <div class="avatar-showcase">
                        <div class="customers d-inline-block avatar-group">
                          <ul>
                            <li class="d-inline-block"><img class="img-70 rounded-circle" src="@/assets/images/user/11.png" alt=""></li>
                            <li class="d-inline-block"><img class="img-70 rounded-circle" src="@/assets/images/user/12.png" alt=""></li>
                            <li class="d-inline-block"><img class="img-70 rounded-circle" src="@/assets/images/user/14.png" alt=""></li>
                          </ul>
                        </div>
                        <div class="customers d-inline-block avatar-group">
                          <ul>
                            <li class="d-inline-block"><img class="img-50 rounded-circle" src="@/assets/images/dashboard/default/05.png" alt=""></li>
                            <li class="d-inline-block"><img class="img-50 rounded-circle" src="@/assets/images/dashboard/default/02.png" alt=""></li>
                            <li class="d-inline-block"><img class="img-50 rounded-circle" src="@/assets/images/dashboard/default/03.png" alt=""></li>
                          </ul>
                        </div>
                        <div class="customers d-inline-block avatar-group">
                          <ul>
                            <li class="d-inline-block"><img class="img-40 rounded-circle" src="@/assets/images/user/3.jpg" alt=""></li>
                            <li class="d-inline-block"><img class="img-40 rounded-circle" src="@/assets/images/user/5.jpg" alt=""></li>
                            <li class="d-inline-block"><img class="img-40 rounded-circle" src="@/assets/images/user/1.jpg" alt=""></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</template>