<template>
  <div class="col-xl-12 col-lg-12 col-md-12 box-col-50 cols-xl-50">
    <div class="card order-card">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <div class="flex-grow-1">
            <p class="square-after f-w-600">
              Archivados<i class="fa fa-circle"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="table-responsive theme-scrollbar data-table tabla_all_data">
          <table class="table table-striped table-bordered table_all" id="table_archived">
            <thead>
              <tr>
                <th>Historia Clinica (HC) = (DNI)</th>
                <th>Nombres y Apellidos</th>
                <th>Edad</th>
                <th>Genero</th>
                <th>Pre DX</th>
                <th>Conteo Sesiones</th>
                <th>Última cita</th>
                <th>Alta</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in directorPatients" :key="item">
                <td>
                  <div class="d-flex">
                    <div class="number-dot">
                      <span class="f-w-700">{{ item.DNI }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <span>{{ item.first_name }} {{ item.last_name }} </span>
                </td>
                <td>{{ item.age }}</td>
                <td>{{ item.gender }}</td>
                <td>{{ item.treatment.pre_diag }}</td>
                <td>{{ item.treatment.number_sessions }}</td>
                <td></td>
                <td><span class="badge badge-light-primary">Si</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["directorPatients"],
  computed: {
    ...mapState({
      dashbord: (state) => state.dashbord.orderList,
    }),
  },
  methods: {
    getImgUrl(path) {
      return require("@/assets/images/dashboard-2/dash-2/" + path);
    },
  },
};
</script>
