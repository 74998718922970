<template>
     <div class="col-xl-6 col-lg-12">
                <div class="card browser-table">
                  <div class="card-header">
                    <div class="d-flex"> 
                      <div class="flex-grow-1"> 
                        <h4>Usage in Browser</h4>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive theme-scrollbar mb-0">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Browser</th>
                            <th>Name</th>
                            <th>Daily</th>
                            <th>Weekly</th>
                            <th>Monthly</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><img class="img-fluid" src="@/assets/images/dashboard/chrom.png" alt=""></td>
                            <td>Google Chrome</td>
                            <td>28%</td>
                            <td>76%</td>
                            <td>36%</td>
                          </tr>
                          <tr>
                            <td><img class="img-fluid" src="@/assets/images/dashboard/firefox.png" alt=""></td>
                            <td>Mozilla Firefox</td>
                            <td>28%</td>
                            <td>76%</td>
                            <td>36%</td>
                          </tr>
                          <tr>
                            <td><img class="img-fluid" src="@/assets/images/dashboard/safari.png" alt=""></td>
                            <td>Safari (web browser)</td>
                            <td>28%</td>
                            <td>76%</td>
                            <td>36%</td>
                          </tr>
                          <tr>
                            <td><img class="img-fluid" src="@/assets/images/dashboard/opera.png" alt=""></td>
                            <td>Opera (web browser)</td>
                            <td>28%</td>
                            <td>76%</td>
                            <td>36%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
</template>