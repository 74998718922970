<template>
  <div class="col-xl-12 col-lg-6 col-md-12 col-sm-6">
    <div class="card">
      <div class="card-header">
        <h5 class="p-0">
          <button
            class="btn btn-link ps-0"
            data-bs-toggle="collapse"
            data-bs-target="#collapseicon13"
            :aria-expanded="isActive ? 'true' : 'false'"
            aria-controls="collapseicon13"
            :class="[isActive ? 'active' : '']"
            v-on:click="toggle"
          >
            Friends
          </button>
        </h5>
      </div>
      <div :class="[isActive ? 'block' : ' show']" v-show="isActive">
        <div class="card-body avatar-showcase filter-cards-view">
          <div
            v-for="f in friends"
            :key="f.id"
            class="d-inline-block friend-pic"
          >
            <img class="img-50 rounded-circle" :src="f.profile_photo" alt="#" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["friends"],
  data() {
    return {
      isActive: true,
      friends1: [
        {
          banner:
            "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg",
          img: "https://media.licdn.com/dms/image/D4E03AQFD-WS7XzISww/profile-displayphoto-shrink_200_200/0/1671112184041?e=1702512000&v=beta&t=cvtkxsojZCyM-KqllS7Y4Lm04y7FF1wqZ-SSIOyQzpw",
          name: "Mark Jecno",
          position: "Psicologo",
          social_media: {
            fb: "https://www.facebook.com/",
            x: "https://twitter.com/",
            ig: "https://www.instagram.com/",
          },
          friends: 25,
          likes: 1000,
        },
        {
          banner:
            "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg",
          img: "https://media.licdn.com/dms/image/C4E03AQGmntJ2l_ejcw/profile-displayphoto-shrink_200_200/0/1596720373561?e=1702512000&v=beta&t=eN_5R9Unog_By9xCYEvP3ew33ZqUncFu58MY-7gOvDc",
          name: "Johan Deo",
          position: "Psicologo",
          social_media: {
            fb: "https://www.facebook.com/",
            x: "https://twitter.com/",
            ig: "https://www.instagram.com/",
          },
          friends: 25,
          likes: 1000,
        },
        {
          banner:
            "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg",
          img: "https://media.licdn.com/dms/image/D4D35AQGDj7Zd9I-DYw/profile-framedphoto-shrink_200_200/0/1692183643539?e=1697612400&v=beta&t=N7s8XKE4mbKtC9qf4oRWL52fg2PuKZTFjacnGvM3tAw",
          name: "Dev John",
          position: "Psicologo",
          social_media: {
            fb: "https://www.facebook.com/",
            x: "https://twitter.com/",
            ig: "https://www.instagram.com/",
          },
          friends: 25,
          likes: 1000,
        },
        {
          banner:
            "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg",
          img: "https://media.licdn.com/dms/image/C5603AQEKrPJIVEDbXA/profile-displayphoto-shrink_200_200/0/1601410411926?e=1702512000&v=beta&t=3mDC5iesF3Rn_7JSiNxhHjy6OGfIIhXYeqdAOc5YGSE",
          name: "Mark Jecno",
          position: "Psicologo",
          social_media: {
            fb: "https://www.facebook.com/",
            x: "https://twitter.com/",
            ig: "https://www.instagram.com/",
          },
          friends: 25,
          likes: 1000,
        },
        {
          banner:
            "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg",
          img: "https://media.licdn.com/dms/image/C4D03AQGYBj5LK7Jz4w/profile-displayphoto-shrink_200_200/0/1516562281559?e=1702512000&v=beta&t=GxVe-MLAESr5uqhJszRn6DVqG3nfyktMegeSVWL8j80",
          name: "Johan Deo",
          position: "Psicologo",
          social_media: {
            fb: "https://www.facebook.com/",
            x: "https://twitter.com/",
            ig: "https://www.instagram.com/",
          },
          friends: 25,
          likes: 1000,
        },
      ],
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
  },
};
</script>
