// let eventGuid = 0
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

// export const INITIAL_EVENTS= [
//   {
//     id: createEventId(),
//     title: 'All-day event',
//     start: todayStr
//   },
//   {
//     id: createEventId(),
//     title: 'Timed event',
//     start: todayStr + 'T12:00:00'
//   },
//   {
//     id: createEventId(),
//       title: "event 1",
//        date: "2023-09-01"
//   },
// {
//     id: createEventId(),
//      title: "BirthDay",
//       date: "2023-09-16"
// },
// {
//   id: createEventId(),
//   title: "All-day event",
//   date: "2023-09-04"
//     },
// {
//     id: createEventId(),
//    title: "event",
//    date: "2023-09-20"
//    },
// {
//     id: createEventId(),
//   title:"event 2",
//    date:"2023-10-07"
//   },
// {
//     id: createEventId(),
//     title:"all-day event",
//     date:"2023-10-27"
//   },
// {
//     id: createEventId(),
//   title:"event",
//   date:"2023-12-10"
//         },
// {
//     id: createEventId(),
//     title:"event2",
//     date:"2023-12-12"
//   },
// {
//     id: createEventId(),
//     title:"event",
//     date:"2023-11-1",
//     start:"T12:00:00"},
//   {
//       id: createEventId(),
//       title:"event",
//       date:"2023-11-18",},
//    {
//        id: createEventId(),
//       title:"event",
//       date:"2023-11-06",
//     },
//     {
//         id: createEventId(),
//        title:"All Day event",
//        date:"2023-12-06",
//      },
//      {
//         id: createEventId(),
//        title:"BirthDay Party",
//        date:"2023-12-22",
//      },
//      {
//         id: createEventId(),
//        title:"4p meeting",
//        date:"2023-12-11",
//      },
//      {
//         id: createEventId(),
//        title:"3:30p meeting",
//        date:"2023-12-12",
//      },
//      {
//         id: createEventId(),
//        title:"Tour with our team ",
//        date:"2023-01-16",
//      },
//      {
//         id: createEventId(),
//        title:"4p meeting ",
//        date:"2023-01-03",
//      },
//      {
//       id: createEventId(),
//      title:"event3",
//      date:"2023-01-06",
//    },
//      {
//         id: createEventId(),
//        title:"4p meeting",
//        date:"2023-01-22",
//      },
//      {
//         id: createEventId(),
//        title:"event1",
//        date:"2023-01-22",
//      },
//      {
//         id: createEventId(),
//        title:"3:30p meeting",
//        date:"2023-01-23",
//      },
//      {
//         id: createEventId(),
//        title:"3:30p meeting",
//        date:"2023-01-27",
//      },
// ]

// export function createEventId() {
//   return String(eventGuid++)
// }
import * as types from "../mutation-types";
import calendarAPI from "@/api/calendar";

const state = {
  calendar: null,
  weekly: [],
  monthly: [],
  initialEvents: [],
  weeklyDirector: [],
  monthlyDirector: [],
  initialEventsDirector: [],
};

const getters = {};

const actions = {
  async createCalendar({ commit }, payload) {
    try {
      const response = await calendarAPI.createCalendar(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getCalendar({ commit }, payload) {
    try {
      const response = await calendarAPI.getCalendar(payload);
      const calendar = response.data;
      commit(types.SET_CALENDAR, { calendar });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getWeekly({ commit }, payload) {
    try {
      const response = await calendarAPI.getWeekly(payload);
      const weekly = response.data;
      commit(types.SET_WEEKLY, { weekly });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getMonthly({ commit }, payload) {
    try {
      const response = await calendarAPI.getMonthly(payload);
      const monthly = response.data;
      commit(types.SET_MONTHLY, { monthly });
      const nuevoFormatoEventos = monthly.map((evento, index) => {
        return {
          id: index,
          title: evento.patient_name,
          date: evento.date,
        };
        Console.log(nuevoFormatoEventos);
      });
      commit(types.INITIAL_EVENTS, { nuevoFormatoEventos });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getWeeklyDirector({ commit }, payload) {
    try {
      const response = await calendarAPI.getWeeklyDirector(payload);
      const weeklyDirector = response.data;
      commit(types.SET_WEEKLY_DIRECTOR, { weeklyDirector });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getMonthlyDirector({ commit }, payload) {
    try {
      const response = await calendarAPI.getMonthlyDirector(payload);
      const monthlyDirector = response.data[0];
      commit(types.SET_MONTHLY_DIRECTOR, { monthlyDirector });
      const newFormatt = monthlyDirector.map((evento, index) => {
        return {
          id: index,
          title: evento.patient_name,
          date: evento.date,
        };
      });
      commit(types.INITIAL_EVENTS_DIRECTOR, { newFormatt });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_CALENDAR](state, { calendar }) {
    state.calendar = calendar;
  },
  [types.SET_WEEKLY](state, { weekly }) {
    state.weekly = weekly;
  },
  [types.SET_MONTHLY](state, { monthly }) {
    state.monthly = monthly;
  },
  [types.INITIAL_EVENTS](state, { nuevoFormatoEventos }) {
    state.initialEvents = nuevoFormatoEventos;
  },
  [types.SET_WEEKLY_DIRECTOR](state, { weeklyDirector }) {
    state.weeklyDirector = weeklyDirector;
  },
  [types.SET_MONTHLY_DIRECTOR](state, { monthlyDirector }) {
    state.monthlyDirector = monthlyDirector;
  },
  [types.INITIAL_EVENTS_DIRECTOR](state, { newFormatt }) {
    state.initialEventsDirector = newFormatt;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
