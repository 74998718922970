<template>
     <div class="col-xl-6 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Background Color</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.bg-primary {
background-color: #5c61f2 !important;
color: #fff;
}
.bg-secondary {
background-color: #eeb82f !important;
color: #fff;
}
.bg-success {
background-color: #51bb25 !important;
color: #fff;
}
.bg-danger {
background-color: #dc3545 !important;
color: #fff;
}
.bg-info {
background-color: #a927f9 !important;
color: #fff;
}
.bg-light {
background-color: #f4f4f4 !important;
color: #fff;
}
.bg-dark {
background-color: #898989 !important;
color: #fff;
}
.bg-warning {
background-color: #f8d62b !important;
color: #fff;
}</pre>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Button Color</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.btn-primary {
background-color: #5c61f2;
border-color: #5c61f2;
}
.btn-secondary {
background-color: #eeb82f;
border-color: #eeb82f;
}
.btn-success {
background-color: #51bb25 !important;
color: #fff;
}
.btn-success {
background-color: #51bb25;
border-color: #51bb25;
}
.btn-danger {
background-color: #dc3545;
border-color: #dc3545;
}
.btn-info {
background-color: #a927f9;
border-color: #a927f9;
}
.btn-light {
background-color: #f4f4f4;
border-color: #f4f4f4;
}
.btn-warning {
background-color: #f8d62b;
border-color: #f8d62b;
}</pre>
                  </div>
                </div>
              </div>
</template>