<template>
  <div class="col chat-right-aside">
    <div class="chat">
      <div class="d-flex chat-header clearfix align-items-start">
        <img
          class="rounded-circle"
          v-if="activeUser"
          :src="activeUser?.profile_photo"
          alt=""
        />
        <div class="flex-grow-1">
          <div class="about">
            <div class="name">
              {{ activeUser.first_name }}{{ activeUser.last_name }}
            </div>
            <div class="status digits">
              
            </div>
          </div>
        </div>
        <ul class="list-inline float-start float-sm-end chat-menu-icons">
          <li class="list-inline-item">
            <a href="javascript:void(0)"
              ><vue-feather type="search"></vue-feather
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="javascript:void(0)"
              ><vue-feather type="paperclip"></vue-feather
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="javascript:void(0)"
              ><vue-feather type="headphones"></vue-feather
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="javascript:void(0)"
              ><vue-feather type="video"></vue-feather
            ></a>
          </li>
          <li
            class="list-inline-item toogle-bar"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            :class="[isActive ? 'active' : '', 'collapsible']"
            v-on:click="toggle"
          >
            <a href="javascript:void(0)"
              ><vue-feather type="menu"></vue-feather
            ></a>
          </li>
        </ul>
      </div>
      <div class="chat-history chat-msg-box custom-scrollbar">
        <ul>
          <li
            v-for="(chat, index) in getMessages"
            :key="index"
            v-bind:class="{ clearfix: chat.id_remitting === activeUser.id }"
          >
            <div
              v-if="chat.id_remitting === activeUser.id || chat.id_addressee === activeUser.id"
              class="message"
              v-bind:class="{
                'my-message': chat.id_remitting === userInfo.id,
                'other-message pull-right': chat.id_addressee === userInfo.id,
              }"
            >
              <img
                class="rounded-circle float-start chat-user-img img-30 text-end"
                alt=""
                v-if="activeUser.id === chat.id_remitting"
                v-bind:src="activeUser.profile_photo"
              />
              <img
                class="rounded-circle float-end chat-user-img img-30"
                alt=""
                v-if="chat.id_remitting === userInfo.id"
                v-bind:src="userInfo.profile_photo"
              />
              <div
                class="message-data text-end"
                v-bind:class="{ 'text-start': chat.id_remitting !== userInfo.id }"
              >
                <span class="message-data-time">{{ formattedDate(chat.createdAt) }}</span>
              </div>

              {{ chat.message }}
            </div>
          </li>
        </ul>
      </div>
      <addChat />
    </div>
  </div>
  <div
    class="col chat-menu"
    :class="[isActive ? 'block' : 'none', 'content']"
    v-show="isActive"
    id="collapseExample"
  >
    <chatMenu />
  </div>
</template>
<script>
import { mapState } from "vuex";
import addChat from "./addChat.vue";
import chatMenu from "@/components/common/chatMenu.vue";
export default {
  components: { addChat, chatMenu },
  data() {
    return {
      currentchat: [],
      chatmenutoogle: false,
      isActive: false,
    };
  },
  computed: {
    ...mapState({
      currentChat() {
        return (this.currentchat = this.$store.getters["chat/currentChat"]);
      },
      activeUser: (state) => state.chats.activeUser,
      sendMsg: (state) => state.chats.sendMsg,
      recivedMsg: (state) => state.chats.recivedMsg,
      userInfo: (state) => state.user.user,
    }),
    getMessages() {
      const combinedArray = [...this.recivedMsg, ...this.sendMsg];
      return combinedArray
        .map(message => ({
          ...message,
          formattedDate: new Date(message.createdAt).toLocaleString(),
        }))
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }
  },
  methods: {
    getImgUrl(path) {
      return require("@/assets/images/" + path);
    },
    toggle() {
      this.isActive = !this.isActive;
    },
    formattedDate(date) {
      const timestamp = date;
      const fecha = new Date(timestamp * 1000);

      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const año = fecha.getFullYear();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
      const segundos = fecha.getSeconds();
      return `${dia}/${mes}/${año} ${hora}:${minutos}:${segundos}`;
    },
  },
};
</script>