<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="my-gallery card-body gallery-with-description" itemscope="">
          <div class="row">
            <figure
              v-for="(src, index) in photos"
              :key="index"
              class="col-xl-3 col-sm-6 box-col-33"
            >
              <a>
                <img
                  v-if="src.image_url"
                  :src="src.image_url"
                  :alt="src.title"
                  height="250"
                />
                <div class="caption description">
                  <h4 v-if="src.title">{{ src.title }}</h4>
                  <p class="d-none">{{ src.content }}</p>
                </div>
              </a>
            </figure>
          </div>
        </div>
        <vue-easy-lightbox
          :index="index"
          :visible="visible"
          :imgs="lightBoxImages"
          @hide="handleHide"
        >
        </vue-easy-lightbox>
      </div>
    </div>
  </div>
</template>
<script>
import imageMixin from "@/mixins/commen/imageMixin";
import { mapState } from "vuex";
export default {
  mixins: [imageMixin],
  data() {
    return {
      lightBoxImages: [],
      lightBoxTitle: [],
      visible: false,
      posts: [
        {
          photo:
            "https://images.pexels.com/photos/6844385/pexels-photo-6844385.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          title: "Un nuevo proyecto",
          description:
            'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy.',
        },
        {
          photo:
            "https://images.pexels.com/photos/18401224/pexels-photo-18401224/free-photo-of-ciudad-nubes-edificio-arquitectura.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          title: "Un nuevo proyecto 2",
          description:
            'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy.',
        },
        {
          photo:
            "https://www.debate.com.mx/__export/1683311074829/sites/debate/img/2023/05/05/que_significa_random.jpg_423682103.jpg",
          title: "Un nuevo proyecto 3",
          description:
            'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy.',
        },
        {
          photo:
            "https://i.pinimg.com/originals/cc/61/e0/cc61e0afec490d5a8e59ba330c67ab2a.jpg",
          title: "Un nuevo proyecto 4",
          description:
            'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy.',
        },
        {
          photo:
            "https://images.squarespace-cdn.com/content/v1/57f4717846c3c46d4a4bf475/1668120993782-30TW7O8IRN1QSD6LQT88/IMG_7700.JPG",
          title: "Un nuevo proyecto 5",
          description:
            'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy.',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      photo: (state) => state.masonry.photo,
      postsData: (state) => state.feed.posts,
    }),
    photos() {
      const id = localStorage.getItem("userId");
      return this.postsData.filter(
        (item) => item.user_id === id && item.image_url !== ""
      );
    },
  },
  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  mounted() {
    this.photo.forEach((item) => {
      this.lightBoxImages.push(require("@/assets/images/" + item.image));
      this.lightBoxTitle.push(item.title);
    });
  },
};
</script>
