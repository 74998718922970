import * as types from "../mutation-types";
import searchAPI from "@/api/search";

const state = {
  results: null,
};

const getters = {
  results: (state) => state.results,
};

const actions = {
  async searchPsycos({ commit }, payload) {
    try {
      const response = await searchAPI.searchPsycos(payload);
      const results = response.data;
      commit(types.SET_RESULTS, { results });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_RESULTS](state, { results }) {
    state.results = results;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
