<template>
  <div class="default-according style-1 job-accordion" id="accordionoc4">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h5 class="p-0">
            <button
              class="btn btn-link ps-0"
              data-bs-toggle="collapse"
              data-bs-target="#collapseicon"
              :aria-expanded="detailShow ? 'true' : 'false'"
              aria-controls="collapseicon"
              :class="[detailShow ? 'active' : '']"
              v-on:click="handleShow"
            >
              <img
                class="img-30 img-fluid m-r-20 rounded-circle"
                src="@/assets/images/icons/man.png"
                alt=""
              />
              Psi. {{ result.first_name }} {{ result.last_name }}, años,
              valoración
              <span v-if="result?.calification">
                {{
                  result?.calification.length > 0
                    ? result.calification[0].raiting
                    : 1
                }}</span
              >
              <i class="fa fa-star font-warning"></i>
            </button>
          </h5>
        </div>
        <div :class="[detailShow ? 'block' : ' show']" v-show="detailShow">
          <div class="card-body socialprofile filter-cards-view">
            <div class="d-flex align-items-center">
              <img
                class="img-50 img-fluid m-r-20 rounded-circle"
                src="@/assets/images/icons/man.png"
                alt=""
              />
              <div class="flex-grow-1">
                <div class="card-body filter-cards-view">
                  <div class="bloq-items">
                    <div class="f-w-600 item-result">
                      Edad :
                      <span class="f-w-600"> {{ result.age }} </span>
                    </div>
                  </div>
                  <div class="bloq-items">
                    <div class="f-w-600 item-result">
                      Especialidad :
                      <span class="f-w-600">
                        {{ result.terapeutic_fomation }}
                      </span>
                    </div>
                  </div>
                  <div class="bloq-items">
                    <div class="f-w-600 item-result">
                      Modalidad:<span class="f-w-600">
                        {{ result.remote ? "Remoto" : "Presencial" }}
                      </span>
                    </div>
                    <div class="f-w-600 item-result">
                      Costo:
                      <span class="f-w-600">
                        S/. {{ result.price_session }}</span
                      >
                    </div>
                  </div>
                  <div class="f-w-600">
                    Mi dirección es: {{ result.address }}
                  </div>
                  <div class="bloq-items">
                    <span class="f-w-600 item-result">
                      <a target="_blank" :href="`mailto:${result.email}`">{{
                        result.email
                      }}</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="likes-profile">
              <a
                :href="'https://wa.me/' + result.phone"
                class="btn social-btn btn-secondary me-2"
                target="_blank"
              >
                Contacta Ahora!
              </a>
              <div
                class="btn social-btn btn-primary text-center me-2"
                type="button"
                @click="setData(result)"
              >
                Califica y Comenta
              </div>
              <a
                :href="
                  'https://www.facebook.com/sharer/sharer.php?u=' +
                  'https://dev.sigma.net.pe/ecommerce'
                "
                target="_blank"
                class="btn social-btn btn-info text-center"
                >Compartir
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <addCategory />
  </div>
</template>
<style scoped>
.bloq-items {
  display: flex;
}
.item-result {
  width: 300px !important;
}
.result {
  display: flex;
  border: 1px solid #eee;
  border-radius: 20px;
  margin-top: 10px;
  padding: 5px;
  position: relative;
}
.result-btns {
  display: flex;
  flex-direction: column;
}
.content-result {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.result-accion {
  position: absolute;
  top: 4px;
  right: 10px;
  cursor: pointer;
}
.result-body {
  padding: 10px;
}
.image-result {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.large-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
</style>
<script>
import { mapActions } from "vuex";
import addCategory from "./addComment.vue";
export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  components: {
    addCategory,
  },
  data() {
    return {
      detailShow: false,
      data: null,
      title: "",
    };
  },
  methods: {
    ...mapActions({
      setPsyco: "psyco/setPsyco",
    }),
    handleShow() {
      this.detailShow = !this.detailShow;
    },
    setData(item) {
      this.setPsyco(item);
      setTimeout(() => {
        $("#exampleModal1").modal("show");
      }, 1000);
    },
  },
};
</script>
