<template>
     <div class="col-sm-12 col-xl-6 col-lg-12 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Basic Switch</h4>
                  </div>
                  <div class="card-body">
                    <div class="row switch-showcase height-equal">
                      <div class="col-sm-12">
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Bootstrap Switch</label>
                          <div class="flex-grow-1 text-end">
                            <div class="form-check form-switch">
                              <input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox">
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Bootstrap Disabled</label>
                          <div class="flex-grow-1 text-end">
                            <div class="form-check form-switch">
                              <input class="form-check-input" id="flexSwitchCheckDisabled" type="checkbox" disabled="">
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Default Switch</label>
                          <div class="flex-grow-1 text-end">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Unchecked Switch</label>
                          <div class="flex-grow-1 text-end">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">With Icon</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Unchecked With Icon</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Disabled State</label>
                          <div class="flex-grow-1 text-end">
                            <label class="switch">
                              <input type="checkbox" disabled=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex">
                          <label class="col-form-label m-r-10">Disabled With Icon</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" disabled=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>