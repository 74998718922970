<template>
     <div class="card">
                  <div class="card-header pb-0">
                    <h4>Float</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.f-left {
float: left;
}
.f-right {
float: right;
}
.f-none {
float: none;
}</pre>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Overflow</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.o-hidden {
overflow: hidden;
}
.o-visible {
overflow: visible;
}
.o-auto {
overflow: auto;
}</pre>
                  </div>
                </div>
</template>