<template>
     <Breadcrumbs main="Pages" title="Internationalization"/>
     <div class="container-fluid">
            <div class="row">
            <languageInternationa/>
              </div>
             </div>
</template>
<script>
import languageInternationa from "@/components/internationalization/languageInternationa.vue"
export default {
   components:{
    languageInternationa
   }
}
</script>