<template>
  <div class="people-list">
    <div class="search">
      <form class="theme-form">
        <div class="form-group">
          <input
            class="form-control"
            type="text"
            placeholder="Write Status..."
          /><i class="fa fa-pencil"></i>
        </div>
      </form>
    </div>
  </div>
  <div class="status">
    <p class="font-primary f-w-600">Active</p>
    <hr />
    <p class="mb-0">
      Success isn't about end result, it's about what you learn. <i
        class="icofont icofont-emo-heart-eyes font-warning f-20"
      ></i
      ><i class="icofont icofont-emo-heart-eyes font-warning f-20 m-l-5"></i>
    </p>
    <hr />
    <p class="mb-0">
      Love Your Self. <i
        class="icofont icofont-emo-rolling-eyes font-info f-20"
      ></i>
    </p>
  </div>
</template>