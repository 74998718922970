import axios from "axios";

const HOST = process.env.VUE_APP_API_PSYCOLOGOS;

export default {
  async getSesionsById(payload = {}) {
    const id = payload.payload.id;
    const response = await axios({
      url: `${HOST}/institution/list/session/${id}`,
      method: "get",
    });
    return response;
  },
  async getPatientsById(payload = {}) {
    const id = payload.payload.id;
    const response = await axios({
      url: `${HOST}/patient/director/list/${id}`,
      method: "get",
    });
    return response;
  },
};
