import * as types from "../mutation-types";
import diagsAPI from "@/api/diags";

const state = {
  diags: [],
  diagsDirector: [],
  diagsPsico: [],
};

const getters = {};

const actions = {
  async getDiags({ commit }, payload) {
    try {
      const response = await diagsAPI.getDiags();
      const diags = response.data;
      commit(types.SET_DIAGS, { diags });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getDiagsPsico({ commit }, payload) {
    try {
      const response = await diagsAPI.getDiagsPsico();
      const diagsPsico = response.data;
      commit(types.SET_DIAGS_PSICO, { diagsPsico });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getDiagsDirector({ commit }, payload) {
    try {
      const response = await diagsAPI.getDiagsDirector();
      const diagsDirector = response.data;
      commit(types.SET_DIAGS_DIRECTOR, { diagsDirector });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_DIAGS](state, { diags }) {
    state.diags = diags;
  },
  [types.SET_DIAGS_PSICO](state, { diagsPsico }) {
    state.diagsPsico = diagsPsico;
  },
  [types.SET_DIAGS_DIRECTOR](state, { diagsDirector }) {
    state.diagsDirector = diagsDirector;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
