const AWS = require("aws-sdk");

AWS.config.update({
  accessKeyId: "AKIAQRLFUGKFUIBXFQFI",
  secretAccessKey: "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",
  region: "us-east-1",
});

const s3 = new AWS.S3();

// Function to upload a file to S3
function uploadFile(bucketName, key, file) {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: file,
    ACL: "public-read",
  };

  return s3.upload(params).promise();
}

module.exports = {
  uploadFile,
};
