import axios from "axios";

const HOST = process.env.VUE_APP_API_USERS;

export default {
  async getUser(payload = {}) {
    try {
      const userID = payload.payload.id;
      const response = await axios({
        url: `${HOST}/user/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getUsers(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/user/list`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getUserPatient(payload = {}) {
    try {
      const userID = payload.payload;
      const response = await axios({
        url: `${HOST}/user/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateUser(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/user`,
        method: "put",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async createUser(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/admin/user`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
};
