<template>
    <div class="tiptap-component">
        <div class="tiptap-controls" v-if="editor">
            <button class="btn sm" @click="editor.chain().focus().toggleBold().run()"
                :disabled="!editor.can().chain().focus().toggleBold().run()"
                :class="{ 'is-active': editor.isActive('bold') }">
                bold
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleItalic().run()"
                :disabled="!editor.can().chain().focus().toggleItalic().run()"
                :class="{ 'is-active': editor.isActive('italic') }">
                italic
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleStrike().run()"
                :disabled="!editor.can().chain().focus().toggleStrike().run()"
                :class="{ 'is-active': editor.isActive('strike') }">
                strike
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleCode().run()"
                :disabled="!editor.can().chain().focus().toggleCode().run()"
                :class="{ 'is-active': editor.isActive('code') }">
                code
            </button>
            <button class="btn sm" @click="editor.chain().focus().unsetAllMarks().run()">
                clear marks
            </button>
            <button class="btn sm" @click="editor.chain().focus().clearNodes().run()">
                clear nodes
            </button>
            <button class="btn sm" @click="editor.chain().focus().setParagraph().run()"
                :class="{ 'is-active': editor.isActive('paragraph') }">
                paragraph
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                h1
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                h2
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                h3
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
                h4
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
                h5
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
                h6
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleBulletList().run()"
                :class="{ 'is-active': editor.isActive('bulletList') }">
                bullet list
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleOrderedList().run()"
                :class="{ 'is-active': editor.isActive('orderedList') }">
                ordered list
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleCodeBlock().run()"
                :class="{ 'is-active': editor.isActive('codeBlock') }">
                code block
            </button>
            <button class="btn sm" @click="editor.chain().focus().toggleBlockquote().run()"
                :class="{ 'is-active': editor.isActive('blockquote') }">
                blockquote
            </button>
            <button class="btn sm" @click="editor.chain().focus().setHorizontalRule().run()">
                horizontal rule
            </button>
            <button class="btn sm" @click="editor.chain().focus().setHardBreak().run()">
                hard break
            </button>
            <button class="btn sm" @click="editor.chain().focus().undo().run()"
                :disabled="!editor.can().chain().focus().undo().run()">
                undo
            </button>
            <button class="btn sm" @click="editor.chain().focus().redo().run()"
                :disabled="!editor.can().chain().focus().redo().run()">
                redo
            </button>
        </div>
        <editor-content :editor="editor" />
    </div>
</template>
  
<script>
  import StarterKit from '@tiptap/starter-kit'
  import { Editor, EditorContent } from '@tiptap/vue-3'
  import TipTapImage from '@tiptap/extension-image';
  
  export default {
    components: {
      EditorContent,
    },
  
    props: {
      modelValue: {
        type: String,
        default: '',
      },
    },
  
    emits: ['update:modelValue'],
  
    data() {
      return {
        editor: null,
      }
    },
  
    watch: {
      modelValue(value) {
        // HTML
        const isSame = this.editor.getHTML() === value
  
        // JSON
        // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
  
        if (isSame) {
          return
        }
  
        this.editor.commands.setContent(value, false)
      },
    },
  
    mounted() {
      this.editor = new Editor({
        extensions: [
          StarterKit,
          TipTapImage,
        ],
        editorProps: {
            handleDrop: function(view, event, slice, moved) {
            if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) { // if dropping external files
                let file = event.dataTransfer.files[0]; // the dropped file
                let filesize = ((file.size/1024)/1024).toFixed(4); // get the filesize in MB
                if ((file.type === "image/jpeg" || file.type === "image/png") && filesize < 10) { // check valid image type under 10MB
                // check the dimensions
                const _URL = window.URL || window.webkitURL;
                const img = new Image(); /* global Image */
                img.src = _URL.createObjectURL(file);
                img.onload = function () {
                    if (this.width > 5000 || this.height > 5000) {
                    window.alert("Your images need to be less than 5000 pixels in height and width."); // display alert
                    } else {
                    // valid image so upload to server
                    // uploadImage will be your function to upload the image to the server or s3 bucket somewhere
                    new Promise((resolve) => resolve(img.src)).then(function(response) {
                        console.log(response) // response is the image url for where it has been saved
                        // place the now uploaded image in the editor where it was dropped
                        const { schema } = view.state;
                        const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
                        const node = schema.nodes.image.create({ src: response }); // creates the image element
                        const transaction = view.state.tr.insert(coordinates.pos, node); // places it in the correct position
                        return view.dispatch(transaction);
                    }).catch(function(error) {
                        if (error) {
                        window.alert("There was a problem uploading your image, please try again.");
                        }
                    });
                    }
                };
                } else {
                window.alert("Images need to be in jpg or png format and less than 10mb in size.");
                }
                return true; // handled
            }
            return false; // not handled use default behaviour
            }
        },
        content: this.modelValue,
        onUpdate: () => {
          // HTML
          this.$emit('update:modelValue', this.editor.getHTML())
  
          // JSON
          // this.$emit('update:modelValue', this.editor.getJSON())
        },
      })
    },
  
    beforeUnmount() {
      this.editor.destroy()
    },
  }
  </script>

<style lang="scss">
/* Basic editor styles */
.tiptap-component {
    .tiptap-controls {
        .btn {
            font-size: inherit;
            font-family: inherit;
            color: #000;
            margin: .1rem;
            border: 1px solid black;
            border-radius: .3rem;
            padding: .1rem .4rem;
            background: white;
            accent-color: black;
        }
    }

    .tiptap {
        min-height: 200px;
        border: 1px solid #333;
        border-radius: 6px;
        padding: 15px;
        margin: 20px 0;

        >*+* {
            margin-top: 0.75em;
        }

        ul,
        ol {
            padding: 0 1rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.1;
        }

        code {
            background-color: rgba(#616161, 0.1);
            color: #616161;
        }

        pre {
            background: #0D0D0D;
            color: #FFF;
            font-family: 'JetBrainsMono', monospace;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;

            code {
                color: inherit;
                padding: 0;
                background: none;
                font-size: 0.8rem;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }

        blockquote {
            padding-left: 1rem;
            border-left: 2px solid rgba(#0D0D0D, 0.1);
        }

        hr {
            border: none;
            border-top: 2px solid rgba(#0D0D0D, 0.1);
            margin: 2rem 0;
        }
    }
}
</style>