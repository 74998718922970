<template>
  <div class="col-sm-12 col-xl-6">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Nav breadcrumb</h4><span>use class <code>.breadcrumb to nav</code> similar to navigation</span>
      </div>
      <div class="card-body">
        <nav class="breadcrumb"><a class="breadcrumb-item" href="javascript:void(0)">Home</a><a class="breadcrumb-item"
            href="javascript:void(0)">Library</a><span class="breadcrumb-item active">Bootstrap</span></nav>
        <nav class="breadcrumb breadcrumb-icon"><a class="breadcrumb-item" href="javascript:void(0)">Home</a><a
            class="breadcrumb-item" href="javascript:void(0)">Library</a><span
            class="breadcrumb-item active">Bootstrap</span></nav>
        <nav class="breadcrumb breadcrumb-no-divider "><a class="breadcrumb-item" href="javascript:void(0)">Home</a><a
            class="breadcrumb-item" href="javascript:void(0)">Library</a><span
            class="breadcrumb-item active">Bootstrap</span></nav>
        <nav class="breadcrumb mb-0"><a class="breadcrumb-item" href="javascript:void(0)"><i class="fa fa-home"></i></a><a
            class="breadcrumb-item" href="javascript:void(0)">Library</a><span
            class="breadcrumb-item active">Bootstrap</span></nav>
      </div>
    </div>
  </div>
</template>