<template>
  <div class="col-sm-12">
    <div class="card profile-header">
      <div class="profile-img-wrrap"></div>
      <div class="userpro-box">
        <div class="img-wrraper">
          <div class="avatar">
            <img class="img-fluid" alt="" :src="userInfo.profile_photo" />
          </div>
          <router-link
            v-if="!hasParams"
            class="icon-wrapper"
            to="/mi-perfil/edit"
          >
            <i class="icofont icofont-pencil-alt-5"></i
          ></router-link>
        </div>
        <div class="user-designation">
          <div class="title">
            <a target="_blank" href="">
              <h4>{{ userInfo.first_name }} {{ userInfo.last_name }}</h4>
              <h6>{{ user.position }}</h6>
            </a>
          </div>
          <div class="social-media">
            <ul class="user-list-social">
              <li>
                <a :href="user.social_media.fb" target="_blank"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li>
              <li>
                <a :href="user.social_media.x" target="_blank"
                  ><i class="fa fa-twitter"></i
                ></a>
              </li>
              <li>
                <a :href="user.social_media.ig" target="_blank"
                  ><i class="fa fa-instagram"></i
                ></a>
              </li>
            </ul>
          </div>
          <div class="follow">
            <ul class="follow-list">
              <li>
                <div class="follow-num counter">{{ friends.length }}</div>
                <span>Amigos</span>
              </li>
              <li class="d-none">
                <div class="follow-num counter">{{ user.likes }}</div>
                <span>Likes</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <socialTab />

  <component is="style">
    .user-profile .profile-header { background-image: url({{ defaultURL }})
    !important; }
  </component>
</template>

<script>
import socialTab from "./socialTab.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    socialTab,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        gender: "",
        email: "",
        banner:
          "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg",
        img: "https://dev.sigma.net.pe/img/man.76101d57.png",
        name: "Leonardo campos",
        position: "Psicologo",
        photo_large: "",
        social_media: {
          fb: "https://www.facebook.com/",
          x: "https://twitter.com/",
          ig: "https://www.instagram.com/",
        },
        friends: 25,
        following: 200,
        likes: 10,
        files: [
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
          "https://concepto.de/wp-content/uploads/2015/03/naturaleza-medio-ambiente-e1505407093531-1.jpeg",
        ],
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user || {},
      friends: (state) => state.friends.friends,
    }),
    hasParams() {
      return Object.keys(this.$route.params).length > 0;
    },
    defaultURL() {
      const defaultURL =
        "https://pymstatic.com/536/conversions/grupos-psicologia-facebook-social.jpg";
      if (!this.userInfo.hasOwnProperty("photo_large")) return defaultURL;
      return this.userInfo?.photo_large !== ""
        ? this.userInfo.photo_large
        : defaultURL;
    },
  },
  async mounted() {
    if (this.$route.params.id !== undefined) {
      const data = {
        id: this.$route.params.id,
      };

      await this.getUser({ payload: data });
    } else {
      const data = {
        id: localStorage.getItem("userId"),
      };
      await this.getUser({ payload: data });
    }
    this.setData();
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      updateUser: "user/updateUser",
      createFriend: "friends/createFriend",
    }),
    setData() {
      this.user.first_name = this.userInfo.first_name;
      this.user.last_name = this.userInfo.last_name;
      this.user.gender = this.userInfo.gender;
      this.user.email = this.userInfo.email;
      this.user.photo_large = this.userInfo.photo_large;
    },
    async newFriend() {
      const data = {
        user_id: localStorage.getItem("userId"),
        user_friend_id: this.$route.params.id,
        status_of_friend: "pending",
      };
      try {
        await this.createFriend({ payload: data });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
