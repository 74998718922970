<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Vertical alignment</h4>
                  </div>
                  <div class="card-body grid-showcase grid-align">
                    <p>Use flexbox alignment utilities to vertically and horizontally align columns.</p>
                    <div class="row">
                      <div class="col align-self-start"><span>One of three columns</span></div>
                      <div class="col align-self-center"><span>One of three columns</span></div>
                      <div class="col align-self-end"><span>One of three columns</span></div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                    </div>
                    <div class="row align-items-end">
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                      <div class="col"><span>One of three columns</span></div>
                    </div>
                  </div>
                </div>
              </div>
                <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Horizontal alignment</h4>
                  </div>
                  <div class="card-body grid-showcase grid-align">
                    <p>Use flexbox alignment utilities to vertically and horizontally align columns.</p>
                    <div class="row justify-content-start">
                      <div class="col-4"><span>One of two columns</span></div>
                      <div class="col-4"><span>One of two columns</span></div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-4"><span>One of two columns</span></div>
                      <div class="col-4"><span>One of two columns</span></div>
                    </div>
                    <div class="row justify-content-end">
                      <div class="col-4"><span>One of two columns</span></div>
                      <div class="col-4"><span>One of two columns</span></div>
                    </div>
                    <div class="row justify-content-around">
                      <div class="col-4"><span>One of two columns</span></div>
                      <div class="col-4"><span>One of two columns</span></div>
                    </div>
                    <div class="row justify-content-between">
                      <div class="col-4"><span>One of two columns</span></div>
                      <div class="col-4"><span>One of two columns</span></div>
                    </div>
                  </div>
                </div>
              </div>
</template>