<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card height-equal">
                  <div class="card-header pb-0">
                    <h4>Alert With Icon</h4><span>Just add Any icon before text</span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary dark alert-dismissible fade show" role="alert"><vue-feather type="clock"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-secondary dark alert-dismissible fade show" role="alert"><vue-feather type="heart"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-success dark alert-dismissible fade show" role="alert"><vue-feather type="thumbs-up"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-info dark alert-dismissible fade show" role="alert"><vue-feather type="help-circle"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-warning dark alert-dismissible fade show" role="alert"><vue-feather type="bell"></vue-feather>
                      <p>can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-danger dark alert-dismissible fade show" role="alert"><vue-feather type="thumbs-down"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-light dark alert-dismissible fade show" role="alert"><vue-feather type="alert-triangle"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-dark dark alert-dismissible fade show" role="alert"><vue-feather type="alert-circle"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  </div>
                </div>
              </div>
</template>