<template>
    <div class="col-12">
                        <h6 class="sub-title m-0">Larger shadow</h6>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow-lg p-25 shadow-showcase text-center">
                          <h5 class="m-0 f-16">Larger shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow-lg p-25 shadow-showcase text-center">
                          <h5 class="m-0 f-16">Larger shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow-lg p-25 shadow-showcase text-center">
                          <h5 class="m-0 f-16">Larger shadow</h5>
                        </div>
                      </div>
                      <div class="col-12">
                        <h6 class="sub-title">Regular shadow</h6>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">Regular shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">Regular shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">Regular shadow</h5>
                        </div>
                      </div>
                      <div class="col-12">
                        <h6 class="sub-title">Small shadow</h6>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow-sm shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">Small shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow-sm shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">Small shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="shadow-sm shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">Small shadow</h5>
                        </div>
                      </div>
                      <div class="col-12">
                        <h6 class="sub-title">No shadow</h6>
                      </div>
                      <div class="col-sm-4 mb-0">
                        <div class="shadow-none shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">No shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-0">
                        <div class="shadow-none shadow-showcase p-25 text-center">
                          <h5 class="m-0 f-16">No shadow</h5>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-0">
                        <div class="shadow-sm shadow-showcase shadow-none p-25 text-center">
                          <h5 class="m-0 f-16">No shadow</h5>
                        </div>
                      </div>
</template>