<template>
     <div class="history-details">
                              <div class="text-center"><i class="icofont icofont-ui-edit"></i>
                                <p>Contact has not been modified yet.</p>
                              </div>
                              <div class="d-flex align-items-start"><i class="icofont icofont-star me-3"></i>
                                <div class="flex-grow-1 mt-0">
                                  <h6 class="mt-0">Contact Created</h6>
                                  <p class="mb-0">Contact is created via mail</p><span class="f-12">Sep 10, 2023 4:00</span>
                                </div>
                              </div>
                            </div>
</template>