<template>
  <div class="card_tab mb-5">
    <div class="content-header">
      <Breadcrumbs main="" title="Sesiones Terapeuticas" />
      <button class="small-primary-button" @click="submitPatient">
        <i class="fa fa-bookmark-o mx-2" aria-hidden="true"></i>
        Guardar y continuar
      </button>
    </div>
    <div class="row mb-5">
      <div class="sesion-section mt-4">
        <div class="col-12">
          <div>
            <h4>Sesion 1</h4>
          </div>
          <div class="row g-3">
            <div class="mb-3 col-md-4">
              <div class="form-group">
                <select
                  class="form-select digits"
                  id="exampleFormControlSelect9"
                  placeholder="Modalidad"
                  v-model="form.session_mode"
                >
                  <option value="onsite">Presencial</option>
                  <option value="remote">Virtual</option>
                </select>
              </div>
            </div>
            <div class="mb-3 col-md-4">
              <div class="form-group">
                <select
                  class="form-select digits"
                  id="exampleFormControlSelect9"
                  placeholder="Visita"
                >
                  <option value="acompañado">Acompañado</option>
                  <option value="solo">Solo</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p>Resumen de la sesión</p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea4"
            rows="3"
          ></textarea>
        </div>
        <div class="col-12">
          <p>Observaciones</p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea4"
            rows="3"
          ></textarea>
        </div>
        <div class="col-12 mt-3">
          <div class="form-check form-check-inline">
            <select class="form-select digits" id="exampleFormControlSelect9">
              <option selected>Objetivo logrado</option>
              <option>Si</option>
              <option>No</option>
            </select>
          </div>
          <div class="form-check form-check-inline">
            <datepicker
              class="datetimepicker-input digits"
              v-model="date"
              :format="format"
              placeholder="Proxima Cita"
            />
          </div>
          
          <div class="row align-items-center">
            <div class="col-12">
              <h3 class="mt-5 mb-4 h3_personal_history">Adjuntar Documentación</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3">
              <label class="col-form-label">Ingresar nombre de archivo</label>            
              <input class="form-control" type="text" id="namefileNew" />
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3 mt-4">            
              <input class="form-control" type="file" id="formfileNew" @change="handleFileChangeInform1" />
            </div>            
          </div>
        </div>
      </div>
      <div class="sesion-section mt-5">
        <div class="col-12">
          <div class="form-check form-check-inline">
            <h4>Sesion 2</h4>
          </div>
          <div class="form-check form-check-inline">
            <datepicker
              class="datetimepicker-input digits"
              v-model="date"
              :format="format"
              placeholder="fecha"
            />
          </div>
          <div class="form-check form-check-inline">
            <select class="form-select digits" id="exampleFormControlSelect9">
              <option selected>Modalidad</option>
              <option>Presencial</option>
              <option>Virtual</option>
            </select>
          </div>
          <div class="form-check form-check-inline">
            <select class="form-select digits" id="exampleFormControlSelect9">
              <option selected>Acompañado</option>
              <option>Solo</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <p>Resumen de la sesión</p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea4"
            rows="3"
          ></textarea>
        </div>
        <div class="col-12">
          <p>Observaciones</p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea4"
            rows="3"
          ></textarea>
        </div>
        <div class="col-12 mt-3">
          <div class="form-check form-check-inline">
            <select class="form-select digits" id="exampleFormControlSelect9">
              <option selected>Objetivo logrado</option>
              <option>Si</option>
              <option>No</option>
            </select>
          </div>
          <div class="form-check form-check-inline">
            <datepicker
              class="datetimepicker-input digits"
              v-model="date"
              :format="format"
              placeholder="Proxima Cita"
            />
          </div>
          <div class="row align-items-center">
            <div class="col-12">
              <h3 class="mt-5 mb-4 h3_personal_history">Adjuntar Documentación</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3">
              <label class="col-form-label">Ingresar nombre de archivo</label>            
              <input class="form-control" type="text" id="namefileNew" />
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3 mt-4">            
              <input class="form-control" type="file" id="formfileNew" @change="handleFileChangeInform1" />
            </div>            
          </div>
        </div>
      </div>
      <div class="sesion-section mt-5">
        <div class="col-12">
          <div class="form-check form-check-inline">
            <h4>Sesion 3</h4>
          </div>
          <div class="form-check form-check-inline">
            <datepicker
              class="datetimepicker-input digits"
              v-model="date"
              :format="format"
              placeholder="fecha"
            />
          </div>
          <div class="form-check form-check-inline">
            <select class="form-select digits" id="exampleFormControlSelect9">
              <option selected>Modalidad</option>
              <option>Presencial</option>
              <option>Virtual</option>
            </select>
          </div>
          <div class="form-check form-check-inline">
            <select class="form-select digits" id="exampleFormControlSelect9">
              <option selected>Acompañado</option>
              <option>Solo</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <p>Resumen de la sesión</p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea4"
            rows="3"
          ></textarea>
        </div>
        <div class="col-12">
          <p>Observaciones</p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea4"
            rows="3"
          ></textarea>
        </div>
        <div class="col-12 mt-3">
          <div class="form-check form-check-inline">
            <select class="form-select digits" id="exampleFormControlSelect9">
              <option selected>Objetivo logrado</option>
              <option>Si</option>
              <option>No</option>
            </select>
          </div>
          <div class="form-check form-check-inline">
            <datepicker
              class="datetimepicker-input digits"
              v-model="date"
              :format="format"
              placeholder="Proxima Cita"
            />
          </div>
          <div class="row align-items-center">
            <div class="col-12">
              <h3 class="mt-5 mb-4 h3_personal_history">Adjuntar Documentación</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3">
              <label class="col-form-label">Ingresar nombre de archivo</label>            
              <input class="form-control" type="text" id="namefileNew" />
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3 mt-4">            
              <input class="form-control" type="file" id="formfileNew" @change="handleFileChangeInform1" />
            </div>            
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="form-check form-check-inline">
          <select class="form-select digits" id="exampleFormControlSelect9">
            <option selected>Objetivo logrado</option>
            <option>Si</option>
            <option>No</option>
          </select>
        </div>
        <div class="form-check form-check-inline">
          <datepicker
            class="datetimepicker-input digits"
            v-model="date"
            :format="format"
            placeholder="Proxima Cita"
          />
        </div>
        <div class="form-check form-check-inline">
          <input class="form-control" type="file" id="formFile" />
        </div>
      </div>
    </div>
    <div class="sesion-section mt-5">
      <div class="col-12">
        <div class="form-check form-check-inline">
          <h4>Sesion 2</h4>
        </div>
        <div class="form-check form-check-inline">
          <datepicker
            class="datetimepicker-input digits"
            v-model="date"
            :format="format"
            placeholder="fecha"
          />
        </div>
        <div class="form-check form-check-inline">
          <select class="form-select digits" id="exampleFormControlSelect9">
            <option selected>Modalidad</option>
            <option>Presencial</option>
            <option>Virtual</option>
          </select>
        </div>
        <div class="form-check form-check-inline">
          <select class="form-select digits" id="exampleFormControlSelect9">
            <option selected>Acompañado</option>
            <option>Solo</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <p>Resumen de la sesión</p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea4"
          rows="3"
        ></textarea>
      </div>
      <div class="col-12">
        <p>Observaciones</p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea4"
          rows="3"
        ></textarea>
      </div>
      <div class="col-12 mt-3">
        <div class="form-check form-check-inline">
          <select class="form-select digits" id="exampleFormControlSelect9">
            <option selected>Objetivo logrado</option>
            <option>Si</option>
            <option>No</option>
          </select>
        </div>
        <div class="form-check form-check-inline">
          <datepicker
            class="datetimepicker-input digits"
            v-model="date"
            :format="format"
            placeholder="Proxima Cita"
          />
        </div>
        <div class="form-check form-check-inline">
          <input class="form-control" type="file" id="formFile" />
        </div>
      </div>
    </div>
    <div class="sesion-section mt-5">
      <div class="col-12">
        <div class="form-check form-check-inline">
          <h4>Sesion 3</h4>
        </div>
        <div class="form-check form-check-inline">
          <datepicker
            class="datetimepicker-input digits"
            v-model="date"
            :format="format"
            placeholder="fecha"
          />
        </div>
        <div class="form-check form-check-inline">
          <select class="form-select digits" id="exampleFormControlSelect9">
            <option selected>Modalidad</option>
            <option>Presencial</option>
            <option>Virtual</option>
          </select>
        </div>
        <div class="form-check form-check-inline">
          <select class="form-select digits" id="exampleFormControlSelect9">
            <option selected>Acompañado</option>
            <option>Solo</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <p>Resumen de la sesión</p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea4"
          rows="3"
        ></textarea>
      </div>
      <div class="col-12">
        <p>Observaciones</p>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea4"
          rows="3"
        ></textarea>
      </div>
      <div class="col-12 mt-3">
        <div class="form-check form-check-inline">
          <select class="form-select digits" id="exampleFormControlSelect9">
            <option selected>Objetivo logrado</option>
            <option>Si</option>
            <option>No</option>
          </select>
        </div>
        <div class="form-check form-check-inline">
          <datepicker
            class="datetimepicker-input digits"
            v-model="date"
            :format="format"
            placeholder="Proxima Cita"
          />
        </div>
        <div class="row align-items-center">
            <div class="col-12">
              <h3 class="mt-5 mb-4 h3_personal_history">Adjuntar Documentación</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3">
              <label class="col-form-label">Ingresar nombre de archivo</label>            
              <input class="form-control" type="text" id="namefileNew" />
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-3 mt-4">            
              <input class="form-control" type="file" id="formfileNew" @change="handleFileChangeInform1" />
            </div>            
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  data() {
    return {
      form: {
        title: "",
        session_mode: "",
        companionship: "",
        summary: "",
        detail: "",
        urls: [],
        patient_id: "",
        professional_id: "",
        treatment_id: "",
      },
    };
  },
  setup() {
    const date = ref();

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return ` ${day}/${month}/${year}`;
    };

    return {
      date,
      format,
    };
  },
};
</script>
