<template>
     <Breadcrumbs main="E-Commerce" title="product"/>
     <productHeader/>
</template>
<script>
import productHeader from "@/components/ecommerce/product/productHeader.vue"
export default {
    components:{
        productHeader
    }
}
</script>