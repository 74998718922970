<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Inline text elements</h4><span>Styling for common inline HTML5 elements.</span>
                  </div>
                  <div class="card-body">
                    <p>You can use the mark tag to 
                      <mark>highlight</mark> text.
                    </p>
                    <p>
                      <del>This line of text is meant to be treated as deleted text.</del>
                    </p>
                    <p>
                      <s>This line of text is meant to be treated as no longer accurate.</s>
                    </p>
                    <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
                    <p>
                      <u>This line of text will render as underlined</u>
                    </p>
                    <p><small>This line of text is meant to be treated as fine print.</small></p>
                    <p><strong>This line rendered as bold text.</strong></p>
                    <p><em>This line rendered as italicized text.</em></p>
                  </div>
                </div>
              </div>
               <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Text Color</h4><span>You can Give text color by using txt-* class</span>
                  </div>
                  <div class="card-body">
                    <p class="txt-primary">This is Primary text You can archive this adding <code>.txt-primary</code> class</p>
                    <p class="txt-secondary">This is Secondary text You can archive this adding <code>.txt-secondary</code> class</p>
                    <p class="txt-success">This is Success text You can archive this adding <code>.txt-success</code> class</p>
                    <p class="txt-info">This is Info text You can archive this adding <code>.txt-info</code> class</p>
                    <p class="txt-warning">This is Warning text You can archive this adding <code>.txt-warning</code> class</p>
                    <p class="txt-danger">This is Danger text You can archive this adding <code>.txt-danger</code> class</p>
                    <p class="txt-dark">This is Dark text You can archive this adding <code>.txt-dark</code> class</p>
                    <p class="txt-primary">This is Primary text You can archive this adding <code>.txt-primary</code> class</p>
                  </div>
                </div>
              </div>
</template>