<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card">
          <div>
            <div>
              <a
                class="logo text-center"
                style="height: 100%; display: flex; justifycontent: center"
                ><router-link to="/">
                  <img
                    class="img-fluid for-light"
                    style="width: 150px; margin-bottom: 2rem; margin-top: 4rem"
                    src="/img/logo.png"
                    alt="looginpage" /></router-link
              ></a>
            </div>
            <div class="login-main">
              <div class="theme-form">
                <h4 class="text-center">Olvidaste tu contraseña</h4>
                <p class="text-center">
                  No te preocupes Ingresa tu correo y te enviaremos un enlace
                  para que cambies tu contraseña
                </p>

                <div class="form-group">
                  <label class="col-form-label">Correo Electronico</label>
                  <input
                    v-model="form.email"
                    class="form-control"
                    type="email"
                    placeholder="Test@gmail.com"
                  />
                </div>
                <div class="form-group">
                  <button
                    @click="submitUser"
                    :disabled="form.email === ''"
                    class="btn btn-primary btn-block w-100 mt-3"
                  >
                    Enviar
                  </button>
                </div>
                <div class="center">
                  <a class="text-center"
                    ><router-link to="/"> ir al login</router-link></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      free: false,
      condition: false,
      form: {
        email: "",
      },
    };
  },
  methods: {
    ...mapActions({
      recovery: "auth/recovery",
    }),
    async submitUser() {
      try {
        const response = await this.recovery({ payload: this.form });
        this.$toast.show("Se envio correctamente revisar su correo ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
        this.$router.push("/auth/login");
      } catch (error) {
        this.$toast.show(error.response.data.msg, {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>
