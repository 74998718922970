import axios from "axios";

const HOST = process.env.VUE_APP_API_SESION;

export default {
  async createSesion(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/session`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateSesion(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/session/${data.id}`,
        method: "put",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async getSesionsById(payload = {}) {
    const id = payload.payload.id;
    const response = await axios({
      url: `${HOST}/session/list/patient/${id}`,
      method: "get",
    });
    return response;
  },
  async getSesionsByProfesionalId(payload = {}) {
    const id = payload.payload.id;
    const response = await axios({
      url: `${HOST}/session/list/professional/${id}`,
      method: "get",
    });
    return response;
  },
  async getSesions(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/session`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async deleteSesion(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/session/${payload.id}`,
        method: "delete",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
