<template>
     <div class="col-sm-12 col-xl-6 col-lg-12 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Switch Color</h4>
                  </div>
                  <div class="card-body">
                    <div class="row switch-showcase height-equal">
                      <div class="col-sm-12">
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Bootstrap Switch</label>
                          <div class="flex-grow-1 text-end">
                            <div class="form-check form-switch">
                              <input class="form-check-input" id="flexSwitchCheckChecked" type="checkbox" data-onstyle="secondary" checked="">
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Disabled checked</label>
                          <div class="flex-grow-1 text-end">
                            <div class="form-check form-switch">
                              <input class="form-check-input" id="flexSwitchCheckCheckedDisabled" type="checkbox" checked="" disabled="">
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Primary Color</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state bg-primary"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Secondary Color</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state bg-secondary"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Success Color</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state bg-success"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Info Color</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state bg-info"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Warning Color</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state bg-warning"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex">
                          <label class="col-form-label m-r-10">Danger Color</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state bg-danger"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>