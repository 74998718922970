<template>
     <div class="col-lg-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Margin Left</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes theme-scrollbar">.m-l-0 {
margin-left: 0px !important;
}
.m-l-5 {
margin-left: 5px !important;
}
.m-l-10 {
margin-left: 10px !important;
}
.m-l-15 {
margin-left: 15px !important;
}
.m-l-20 {
margin-left: 20px !important;
}
.m-l-25 {
margin-left: 25px !important;
}
.m-l-30 {
margin-left: 30px !important;
}
.m-l-35 {
margin-left: 35px !important;
}
.m-l-40 {
margin-left: 40px !important;
}
.m-l-45 {
margin-left: 45px !important;
}
.m-l-50 {
margin-left: 50px !important;
}</pre>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Margin Right</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes theme-scrollbar">.m-r-0 {
margin-right: 0px;
}
.m-r-5 {
margin-right: 5px;
}
.m-r-10 {
margin-right: 10px;
}
.m-r-15 {
margin-right: 15px;
}
.m-r-20 {
margin-right: 20px;
}
.m-r-25 {
margin-right: 25px;
}
.m-r-30 {
margin-right: 30px;
}
.m-r-35 {
margin-right: 35px;
}
.m-r-40 {
margin-right: 40px;
}
.m-r-45 {
margin-right: 45px;
}
.m-r-50 {
margin-right: 50px;
}</pre>
                  </div>
                </div>
              </div>
</template>