<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Template Color Alerts</h4><span>Template color Archive just adding class <code>.dark</code></span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary dark" role="alert">
                      <p>This is a info alert—check it out!</p>
                    </div>
                    <div class="alert alert-secondary dark" role="alert">
                      <p>This is a light alert—check it out!</p>
                    </div>
                    <div class="alert alert-success dark" role="alert">
                      <p>This is a success alert—check it out!</p>
                    </div>
                    <div class="alert alert-info dark" role="alert">
                      <p>This is a info alert—check it out!</p>
                    </div>
                    <div class="alert alert-warning dark" role="alert">
                      <p>This is a warning alert—check it out!</p>
                    </div>
                    <div class="alert alert-danger dark" role="alert">
                      <p>This is a danger alert—check it out!</p>
                    </div>
                    <div class="alert alert-light dark" role="alert">
                      <p>This is a light alert—check it out!</p>
                    </div>
                    <div class="alert alert-dark dark" role="alert">
                      <p>This is a dark alert—check it out!</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Link color in template-color</h4><span>Use the <code>.alert-link</code> utility class to quickly provide matching colored links within any alert</span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary dark" role="alert">
                      <p>This is a primary alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-secondary dark" role="alert">
                      <p>This is a secondary alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-success dark" role="alert">
                      <p>This is a success alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-info dark" role="alert">
                      <p>This is a info alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-warning dark" role="alert">
                      <p>This is a warning alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-danger dark" role="alert">
                      <p>This is a danger alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-light dark" role="alert">
                      <p>This is a light alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-dark dark" role="alert">
                      <p>This is a light alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                  </div>
                </div>
              </div>
</template>