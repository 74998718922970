import axios from "axios";

const HOST = process.env.VUE_APP_API_STATITICS;
const HOST_CAL = process.env.VUE_APP_API_CALENDAR;
export default {
  async getStatiticsArchived(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/user/statistics/list/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsServices(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/user/statistics/list/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsAsigned(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/user/statistics/assigns/list/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsAsignedTotal(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/user/statistics/assigns/total/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsArchivedTotal(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/user/statistics/archived/total/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsServicesTotal(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST_CAL}/calendar/sessions/statistics/institution/total/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
