import * as types from "../mutation-types";
import universidades from "../../data/universidades.json";
import userAPI from "@/api/user";

const state = {
  user: null,
  patient: null,
  universidades: universidades.universidades,
  users: []
};

const getters = {
  user: (state) => state.user,
};

const actions = {
  async getUser({ commit }, payload) {
    try {
      const response = await userAPI.getUser(payload);
      const user = response.data;
      commit(types.SET_USER, { user });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getUsers({ commit }, payload) {
    try {
      const response = await userAPI.getUsers(payload);
      const users = response.data;
      commit(types.SET_USERS, { users });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getUserPatient({ commit }, payload) {
    try {
      const response = await userAPI.getUserPatient(payload);
      const patient = response.data;
      commit(types.SET_PATIENT, { patient });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createUser({ commit }, payload) {
    try {
      const response = await userAPI.createUser(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateUser({ commit }, payload) {
    try {
      const response = await userAPI.updateUser(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_USER](state, { user }) {
    state.user = user;
  },
  [types.SET_USERS](state, { users }) {
    state.users = users;
  },
  [types.SET_PATIENT](state, { patient }) {
    state.patient = patient;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
