import * as types from "../mutation-types";
import statisticsAPI from "@/api/statitics";

const state = {
  statiticsArchived: [],
  statiticsAsigned: [],
  statiticsServices: [],
  statiticsArchivedTotal: [],
  statiticsAsignedTotal: [],
  statiticsServicesTotal: [],
};

const getters = {};

const actions = {
  async getStatiticsArchived({ commit }, payload) {
    try {
      const response = await statisticsAPI.getStatiticsArchived(payload);
      const statiticsArchived = response.data;
      commit(types.SET_STATITICS_ARCHIVED, { statiticsArchived });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsAsigned({ commit }, payload) {
    try {
      const response = await statisticsAPI.getStatiticsAsigned(payload);  
      const statiticsAsigned = response.data;
      commit(types.SET_STATITICS_ASIGNED, { statiticsAsigned });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsArchivedTotal({ commit }, payload) {
    try {
      const response = await statisticsAPI.getStatiticsArchivedTotal(payload);
      const statiticsArchivedTotal = response.data;
      commit(types.SET_STATITICS_ARCHIVED_TOTAL, { statiticsArchivedTotal });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsAsignedTotal({ commit }, payload) {
    try {
      const response = await statisticsAPI.getStatiticsAsignedTotal(payload);  
      const statiticsAsignedTotal = response.data;
      commit(types.SET_STATITICS_ASIGNED_TOTAL, { statiticsAsignedTotal });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsServices({ commit }, payload) {
    try {
      const response = await statisticsAPI.getStatiticsServices(payload);
      const statistics = response.data;
      commit(types.SET_STATITICS_SERVICES, { statistics });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStatiticsServicesTotal({ commit }, payload) {
    try {
      const response = await statisticsAPI.getStatiticsServicesTotal(payload);
      const statiticsServicesTotal = response.data;
      commit(types.SET_STATITICS_SERVICES_TOTAL, { statiticsServicesTotal });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_STATITICS_ARCHIVED](state, { statiticsArchived }) {
    state.statiticsArchived = statiticsArchived;
  },
  [types.SET_STATITICS_ASIGNED](state, { statiticsAsigned }) {
    state.statiticsAsigned = statiticsAsigned;
  },
  [types.SET_STATITICS_SERVICES](state, { statitics }) {
    state.statiticsServices = statitics;
  },
  [types.SET_STATITICS_SERVICES_TOTAL](state, { statiticsServicesTotal }) {
    state.statiticsServicesTotal = statiticsServicesTotal;
  },
  [types.SET_STATITICS_ARCHIVED_TOTAL](state, { statiticsArchivedTotal }) {
    state.statiticsArchivedTotal = statiticsArchivedTotal;
  },
  [types.SET_STATITICS_ASIGNED_TOTAL](state, { statiticsAsignedTotal }) {
    state.statiticsAsignedTotal = statiticsAsignedTotal;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
