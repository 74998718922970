import axios from "axios";

const HOST = process.env.VUE_APP_API_TREATMENT;

export default {
  async createTreatment(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/treatment`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async getTreatments(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/treatment/list`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
