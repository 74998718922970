<template>
     <div class="col-xl-4 col-lg-6 col-md-12 box-col-33">
                <div class="card">
                  <div class="card-header pb-0">
                    <div class="d-flex justify-content-between">
                      <div class="flex-grow-1">
                        <p class="square-after f-w-600">Recent Activity<i class="fa fa-circle"> </i></p>
                        <h4>New & Update</h4>
                      </div>
                      
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="activity-timeline">
                      <div class="d-flex align-items-start">
                        <div class="activity-line"></div>
                        <div class="activity-dot-secondary"></div>
                        <div class="flex-grow-1">
                          <p class="mt-0 todo-font"><span class="font-primary">20-04-2023 </span>Today</p><span class="f-w-700">Updated Product</span>
                          <p class="mb-0">I like to be real. I don't like things to be staged or fussy.</p>
                        </div><i class="fa fa-circle circle-dot-secondary pull-right"></i>
                      </div>
                      <div class="d-flex align-items-start">
                        <div class="activity-dot-primary"></div>
                        <div class="flex-grow-1">
                          <p class="mt-0 todo-font"><span class="font-primary">20-04-2023 </span>Today<span class="badge badge-primary ms-2">New</span></p><span class="f-w-700">James just like your product</span>
                          <p>Design and style should work toward making look good.</p>
                          <ul class="img-wrapper">
                            <li> <img class="img-fluid" src="@/assets/images/dashboard-2/product/shoes1.png" alt=""></li>
                            <li><img class="img-fluid" src="@/assets/images/dashboard-2/product/shoes2.png" alt=""></li>
                          </ul>
                        </div><i class="fa fa-circle circle-dot-primary pull-right"></i>
                      </div>
                      <div class="d-flex align-items-start">
                        <div class="activity-dot-secondary"></div>
                        <div class="flex-grow-1">
                          <p class="mt-0 todo-font"><span class="font-primary">20-04-2023 </span>Today</p><span class="f-w-700">Jihan Doe just like your product</span>
                          <p class="mb-0">If you have it, you can make anything look good.</p>
                        </div><i class="fa fa-circle circle-dot-secondary pull-right"></i>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
</template>