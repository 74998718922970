<template>
  <div class="card-body">
    <div class="row" id="wrap">
      <div class="col-xl-12 box-col-70">
        <div class="calendar-default" id="calendar-container">
          <FullCalendar
            ref="fullCalendar"
            class="demo-app-calendar"
            :options="calendarOptions"
          ></FullCalendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import { createEventId } from "@/store/modules/calendar";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";
import { mapState, mapActions } from "vuex";

export default {
  components: { FullCalendar },
  props: ["type"],
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        locale: esLocale,
        initialView: "dayGridMonth",
        dropAccept: ".draggableButton",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        droppable: true,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
      },
    };
  },
  computed: {
    ...mapState({
      initialEvents: (state) => state.calendar.initialEvents,
      initialEventsDirector: (state) => state.calendar.initialEventsDirector,
    }),
  },
  async created() {
    if (this.type === "psico") {
      await this.getMonthly();
    } else {
      await this.getMonthlyDirector();
    }
    if (this.initialEvents.length > 0) {
      this.calendarOptions.events = this.initialEvents;
    }
    if (this.initialEventsDirector.length > 0) {
      this.calendarOptions.events = this.initialEventsDirector;
    }
  },
  methods: {
    ...mapActions({
      getMonthly: "calendar/getMonthly",
      getMonthlyDirector: "calendar/getMonthlyDirector",
    }),
    HandleDrop(selectInfo) {
      let title = selectInfo.draggedEl.outerText;
      let calendarApi = selectInfo.view.calendar;
      calendarApi.unselect();
      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.date,
          allDay: true,
        });
      }
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends;
    },
    handleDateSelect(selectInfo) {
      let title = prompt("Please enter a new title for your event");
      let calendarApi = selectInfo.view.calendar;
      calendarApi.unselect();
      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },
    handleEventClick(clickInfo) {
      if (
        confirm(
          `Cita programada a las '${clickInfo.event.start}', paciente: '${clickInfo.event.title}'`
        )
      ) {
        clickInfo.event.remove();
      }
    },
  },
  mounted() {
    let buttons = document.getElementsByClassName("draggableButton");
    for (var i = 0; i < buttons.length; i++) {
      new Draggable(buttons[i]);
    }
  },
};
</script>
