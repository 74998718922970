<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h4>Basic & Sizing Avatar</h4>
                      <span>Use 
                        <code>.bg-light-*</code>
                        for different color avatar.</span>
                    </div>
                    <div class="card-body">
                      <div class="avatar-showcase">
                        <div class="avatars">
                          <div class="avatar bg-light-primary p-2 rounded-pill"><img class="img-100 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                          <div class="avatar bg-light-secondary p-2 rounded-pill"><img class="img-90 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                          <div class="avatar bg-light-success p-2 rounded-pill"><img class="img-80 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                          <div class="avatar bg-light-danger p-2 rounded-pill"><img class="img-70 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                          <div class="avatar bg-light-info p-2 rounded-pill"><img class="img-60 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                          <div class="avatar bg-light-warning p-2 rounded-pill"><img class="img-50 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                          <div class="avatar bg-light-dark p-2 rounded-pill"><img class="img-40 rounded-circle" src="@/assets/images/user/1.jpg" alt="#"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</template>