<template>
     <div class="col-xl-4 proorder box-col-33">
                <div class="card user-chat">
                  <div class="card-header pb-0">
                    <div class="d-flex justify-content-between">
                      <div class="flex-grow-1">
                        <p class="square-after f-w-600 header-text-primary">Chat With Our Users<i class="fa fa-circle"> </i></p>
                        <h4>Chat</h4>
                      </div>
                     
                    </div>
                  </div>
                  <div class="card-body chat-box">
                    <div class="d-flex left-chat">
                      <div class="flex-grow-1">
                        <div class="message-main">
                          <p class="mb-0">Hii</p>
                        </div>
                        <div class="sub-message message-main">
                          <p class="mb-0">Good Evening, My Friend</p>
                        </div>
                      </div>
                      <p class="f-w-500 mb-0 px-0">7:28 PM</p>
                    </div>
                    <div class="d-flex right-chat">
                      <div class="flex-grow-1 text-end">
                        <div class="message-main pull-right">
                          <p class="text-start mb-0">What can do for you</p>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex left-chat">
                      <div class="flex-grow-1">
                        <div class="sub-message message-main mt-0">
                          <p class="mb-0">Can i Borrow some money</p>
                        </div>
                      </div>
                    </div>
                    <div class="input-group">
                      <input class="form-control" id="mail" type="text" placeholder="Type Your Message" name="text">
                      <div class="send-msg"><vue-feather type="send"></vue-feather></div>
                    </div>
                  
                  </div>
                </div>
              </div>
</template>