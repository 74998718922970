import axios from "axios";

const HOST = process.env.VUE_APP_API_CHAT;

export default {
  async createMsg(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/email`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async getChatById(payload = {}) {
    const id = payload.payload.id;
    const response = await axios({
      url: `${HOST}/email/${id}`,
      method: "get",
    });
    return response;
  },
  async getSendMsg(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/email/send/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getRecivedMsg(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/email/recieved/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
