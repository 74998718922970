export const LOGOUT = "LOGOUT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";

export const SET_PATIENTS = "SET_PATIENTS";

export const SET_PSYCHOLOGISTS = "SET_PSYCHOLOGISTS";

export const SET_CALENDAR = "SET_CALENDAR";
export const SET_WEEKLY = "SET_WEEKLY";
export const SET_MONTHLY = "SET_MONTHLY";
export const SET_WEEKLY_DIRECTOR = "SET_WEEKLY_DIRECTOR";
export const SET_MONTHLY_DIRECTOR = "SET_MONTHLY_DIRECTOR";

export const SET_TREATMENTS = "SET_TREATMENTS";

export const SET_SESIONS = "SET_SESIONS";
export const SET_USER_SESIONS = "SET_USER_SESIONS";
export const SET_DIRECTOR_SESIONS = "SET_DIRECTOR_SESIONS";
export const SET_DIRECTOR_PATIENTS = "SET_DIRECTOR_PATIENTS";

export const SET_STEP = "SET_STEP";
export const SET_PATIENT = "SET_PATIENT";
export const SET_PATIENTS_ARCHIVED = "SET_PATIENTS_ARCHIVED";

export const SET_DIAGS = "SET_DIAGS";
export const SET_CDRS = "SET_CDRS";
export const SET_APPROACHS = "SET_APPROACHS";
export const SET_RESULTS = "SET_RESULTS";
export const INITIAL_EVENTS = "INITIAL_EVENTS";
export const INITIAL_EVENTS_DIRECTOR = "INITIAL_EVENTS_DIRECTOR";
export const SET_POSTS = "SET_POSTS";
export const SET_TAGS = "SET_TAGS";
export const SET_STATITICS_ARCHIVED = "SET_STATITICS_ARCHIVED";
export const SET_STATITICS_ASIGNED = "SET_STATITICS_ASIGNED";
export const SET_STATITICS_SERVICES = "SET_STATITICS_SERVICES";
export const SET_STATITICS_ARCHIVED_TOTAL = "SET_STATITICS_ARCHIVED_TOTAL";
export const SET_STATITICS_ASIGNED_TOTAL = "SET_STATITICS_ASIGNED_TOTAL";
export const SET_STATITICS_SERVICES_TOTAL = "SET_STATITICS_SERVICES_TOTAL";

export const SET_SEND_MSG = "SET_SEND_MSG";
export const SET_RECIVED_MSG = "SET_RECIVED_MSG";
export const SET_FRIENDS = "SET_FRIENDS";
export const SET_USER_ACTIVE = "SET_USER_ACTIVE";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_PSYCHOLOGY = "SET_PSYCHOLOGY";

export const SET_LIST_SESIONS = "SET_LIST_SESIONS";
export const SET_DIAGS_PSICO = "SET_DIAGS_PSICO";
export const SET_DIAGS_DIRECTOR = "SET_DIAGS_DIRECTOR";
