import Cookies from "js-cookie";
import * as types from "../mutation-types";
import authAPI from "@/api/auth";

const state = {
  user: null,
  token: Cookies.get("token"),
  cdrs: [],
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  check: (state) => {
    return state.user !== null;
  },
};

const actions = {
  async login({ commit }, payload) {
    try {
      const response = await authAPI.login(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async recovery({ commit }, payload) {
    try {
      const response = await authAPI.recovery(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async reset({ commit }, payload) {
    try {
      const response = await authAPI.reset(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  logout({ commit }, payload) {
    return new Promise((resolve, reject) => {
      authAPI
        .logout()
        .then((response) => {
          commit(types.LOGOUT);

          resolve(response);

          payload.router.push({ name: "login" });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getCdrs({ commit }, payload) {
    try {
      const response = await authAPI.getCdrs();
      const cdrs = response.data;
      commit(types.SET_CDRS, { cdrs });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_CDRS](state, { cdrs }) {
    state.cdrs = cdrs;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
