<template>
     <div class="row">
                <div class="col-xl-6 box-col-6">
                            <p class="pb-4">About 6,000 results (0.60 seconds)</p>
                            <div class="info-block">
                              <h6>Zeta Admin is a full featured,premium bootstrap admin template</h6><a href="javascript:void(0)">Zetaeducation.info/</a>
                              <p>Zeta introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>2.5 stars</li>
                                  <li>590 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <h6>Tivo Admin is a full featured,premium bootstrap admin template</h6><a href="javascript:void(0)">Tivoeducation.info/</a>
                              <p>Tivo introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>2.5 stars</li>
                                  <li>590 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <h6>Cuba Admin is a full featured,premium bootstrap admin template</h6><a href="javascript:void(0)">Cubaeducation.info/</a>
                              <p>Cuba introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>
                                    <ul class="rating">
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                    </ul>
                                  </li>
                                  <li>2.5 stars</li>
                                  <li>590 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <h6>Enzo Admin is a full featured,premium bootstrap admin template</h6><a href="javascript:void(0)">Enzoeducation.info/</a>
                              <p>Enzo introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>
                                    <ul class="rating">
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                    </ul>
                                  </li>
                                  <li>2.5 stars</li>
                                  <li>590 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                            <div class="info-block">
                              <h6>Viho Admin is a full featured,premium bootstrap admin template</h6><a href="javascript:void(0)">Vihoeducation.info/</a>
                              <p>Viho introduces a IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT Coaching, SAT Coaching in Surat.</p>
                              <div class="star-ratings">
                                <ul class="search-info">
                                  <li>
                                    <ul class="rating">
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rating"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                      <li><i class="icofont icofont-ui-rate-blank"></i></li>
                                    </ul>
                                  </li>
                                  <li>2.5 stars</li>
                                  <li>590 votes</li>
                                  <li>Music</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6 box-col-6 search-banner">
                            <div class="card">
                              <div class="blog-box blog-shadow"><img class="img-fluid bg-img-cover" src="@/assets/images/blog/blog.jpg" alt="">
                                <div class="blog-details">
                                  <p class="digits">25 July 2023</p><h4>Accusamus et iusto odio dignissimos ducimus qui blanditiis.</h4>
                                  <ul class="blog-social digits">
                                    <li><i class="icofont icofont-user"></i>Mark Jecno</li>
                                    <li><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
</template>