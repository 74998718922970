<template>
  <Breadcrumbs main="Home" title="Mi perfil" />
  <div class="container-fluid">
    <div class="user-profile social-app-profile">
      <div class="row">
        <socialHeader />
      </div>
    </div>
  </div>
</template>
<script>
import socialHeader from "@/components/socialapp/socialHeader.vue"
import { mapState, mapActions } from "vuex";

export default {
  components: {
    socialHeader
  },
  data() {
    return {
      form: {
        id: "",
        age: 35,
        user_type: 4,
        nationality: "Colombiano",
        address: "Calle 456",
        state_country: "Santander",
        province: "Bucaramanfa",
        district: "Distrito 2",
        phone: "+57 0987654321",
        undergraduate: true,
        master: false,
        doctorade: false,
        collegiate: "67890",
        enable_sys: false,
        university: "Universidad de Santander",
        onsite: false,
        remote: false,
        speciality: null,
        url_file: "ruta/al/archivo.pdf",
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
    }),

    gender: function () {
      return this.userInfo.gender === "Male" ? "Masculino" : "Femenino";
    },
  },
  async mounted() {
    if (this.$route.params.id !== undefined) {
      const data = {
        id: this.$route.params.id,
      };
      
      await this.getUser({ payload: data});
      await this.getFriends({payload:data})
    }
    else {
      const data = {
        id: localStorage.getItem("userId"),
      };
    await this.getUser({payload: data});
    await this.getFriends({payload:data})

    }
    this.setData();
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      updateUser: "user/updateUser",
      getFriends:"friends/getFriends"
    }),
    setData() {
      this.form.id = this.userInfo.id;
      this.form.user_type = this.userInfo.user_type;
    },
    editProfile() {
      this.$router.push("/mi-perfil/edit");
    },
    toggle() {
      this.isActive = !this.isActive
    },
  },
}
</script>