<template>
     <div class="col-sm-12 col-xl-4">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>With Icon</h4>
                  </div>
                  <div class="card-body">
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action" href="javascript:void(0)"><i class="icon-save-alt"></i>Cras justo odio</a>
                        <a class="list-group-item list-group-item-action" href="javascript:void(0)"><i class="icon-target"></i>Dapibus ac facilisis in</a>
                        <a class="list-group-item list-group-item-action" href="javascript:void(0)"><i class="icon-mobile"></i>Morbi leo risus</a>
                        <a class="list-group-item list-group-item-action disabled" href="javascript:void(0)"><i class="icon-shopping-cart-full"></i>Vestibulum at eros</a>
                    </div>
                  </div>
                </div>
              </div>
</template>