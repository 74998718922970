<template>
  <div class="col-sm-12 col-xl-4">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Basic Tags</h4>
      </div>
      <div class="card-body basic-icontag">
          <a class="badge badge-light-primary rounded-pill" href="#">
            <i data-feather="dollar-sign">
            <vue-feather type="dollar-sign"></vue-feather>
            </i>
          </a>

          <a class="badge badge-light-secondary rounded-pill" href="#">
            <i data-feather="headphones">
            <vue-feather type="headphones"></vue-feather>
            </i>
          </a>

          <a class="badge badge-light-success rounded-pill" href="#">
            <i data-feather="link">
            <vue-feather type="link"></vue-feather>
            </i>
          </a>

          <a class="badge badge-light-info rounded-pill" href="#">
            <i data-feather="github">
            <vue-feather type="github"></vue-feather>
            </i>
          </a>

          <a class="badge badge-light-warning rounded-pill" href="#">
            <i data-feather="award">
            <vue-feather type="award"></vue-feather>
            </i>
          </a>

          <a class="badge badge-light-danger rounded-pill" href="#">
            <i data-feather="activity">
            <vue-feather type="activity"></vue-feather>
            </i>
          </a>

          <a class="badge badge-light-dark rounded-pill" href="#">
            <i data-feather="heart">
            <vue-feather type="heart"></vue-feather>
            </i>
          </a>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-xl-4">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Tags With icon</h4>
      </div>
      <div class="card-body">
        <a class="badge badge-primary" href="#"
          ><i data-feather="dollar-sign"><vue-feather type="dollar-sign"></vue-feather></i></a
        ><a class="badge badge-secondary" href="#"
          ><i data-feather="headphones"><vue-feather type="headphones"></vue-feather></i></a
        ><a class="badge badge-success" href="#"><i data-feather="link"><vue-feather type="link"></vue-feather></i></a
        ><a class="badge badge-info" href="#"><i data-feather="github"><vue-feather type="github"></vue-feather></i></a
        ><a class="badge badge-warning text-dark" href="#"
          ><i data-feather="award"><vue-feather type="award"></vue-feather></i></a
        ><a class="badge badge-danger" href="#"
          ><i data-feather="activity"><vue-feather type="activity"></vue-feather></i></a
        ><a class="badge badge-light text-dark" href="#"
          ><i data-feather="heart"><vue-feather type="heart"></vue-feather></i></a
        ><a class="badge badge-dark" href="#"><i data-feather="mail"><vue-feather type="mail"></vue-feather></i></a>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-xl-4">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Pills with Icon</h4>
      </div>
      <div class="card-body">
        <a class="badge rounded-pill badge-primary" href="#"
          ><i data-feather="dollar-sign"><vue-feather type="dollar-sign"></vue-feather></i></a
        ><a class="badge rounded-pill badge-secondary" href="#"
          ><i data-feather="headphones"><vue-feather type="headphones"></vue-feather></i></a
        ><a class="badge rounded-pill badge-success" href="#"
          ><i data-feather="link"><vue-feather type="link"></vue-feather></i></a
        ><a class="badge rounded-pill badge-info" href="#"
          ><i data-feather="github"><vue-feather type="github"></vue-feather></i></a
        ><a class="badge rounded-pill badge-warning text-dark" href="#"
          ><i data-feather="award"><vue-feather type="award"></vue-feather></i></a
        ><a class="badge rounded-pill badge-danger" href="#"
          ><i data-feather="activity"><vue-feather type="activity"></vue-feather></i></a
        ><a class="badge rounded-pill badge-light text-dark" href="#"
          ><i data-feather="heart"><vue-feather type="heart"></vue-feather></i></a
        ><a class="badge rounded-pill badge-dark" href="#"
          ><i data-feather="mail"><vue-feather type="mail"></vue-feather></i
        ></a>
      </div>
    </div>
  </div>
</template>