<template>
     <div class="col-xl-4 col-md-6 box-col-33">
                <div class="card our-todolist">
                  <div class="card-header pb-0">
                    <div class="d-flex justify-content-between">
                      <div class="flex-grow-1"> 
                        <p class="square-after f-w-600 header-text-primary">Our To-Do List<i class="fa fa-circle"> </i></p>
                        <h4>Todo List</h4>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="activity-timeline todo-timeline">
                      <div class="d-flex">
                        <div class="activity-line"></div>
                        <div class="activity-dot-primary"></div>
                        <div class="flex-grow-1">
                          <p class="mt-0 todo-font"><span class="font-primary">20-04-2023 </span>Today</p>
                          <div class="d-flex mt-0"><img class="img-fluid img-30" src="@/assets/images/dashboard/default/todo.png" alt="">
                            <div class="flex-grow-1"><span class="f-w-600">New Order $2340<i class="fa fa-circle circle-dot-primary pull-right"></i></span>
                              <p class="mb-0">Update New Product Pdf And Delivery Product</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="activity-dot-secondary"></div>
                        <div class="flex-grow-1">
                          <p class="mt-0 todo-font"><span class="font-primary">20-04-2023 </span>Today<span class="badge badge-primary ms-2">New</span></p><span class="f-w-600">James just like your product<i class="fa fa-circle circle-dot-secondary pull-right"></i></span>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="activity-dot-primary"></div>
                        <div class="flex-grow-1">
                          <p class="mt-0 todo-font"><span class="font-primary">20-04-2022 </span>Today</p><span class="f-w-600">Jihan Doe just like your product</span>
                          <p class="mb-0">Design and style should work making you look good and feel good without lot of effort.</p>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="activity-dot-primary"></div>
                        <div class="flex-grow-1">
                          <p class="mt-0 todo-font"><span class="font-primary">20-04-2023 </span>Today</p><span class="f-w-600">Take Our Client Metting<i class="fa fa-circle circle-dot-primary pull-right"></i></span>
                          <p class="mb-0">Hosting an effective client meeting.</p>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
</template>