import * as types from "../mutation-types";
import approachAPI from "@/api/approach";

const state = {
  approachs: [],
};

const getters = {};

const actions = {
  async getApproachs({ commit }, payload) {
    try {
      const response = await approachAPI.getApproachs(payload);
      const approachs = response.data;
      commit(types.SET_APPROACHS, { approachs });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_APPROACHS](state, { approachs }) {
    state.approachs = approachs;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
