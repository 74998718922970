<template>
  <Breadcrumbs main="" title="Bienvenido a Sigma" />

  <div class="container">
    <div class="row">
      <div class="mb-3">
        <span
          >Le saluda {{ director }} Invitación para unirse a {{ institution }}
        </span>
      </div>
      <button class="btn btn-primary mb-5" @click="sendInvitation">
        Confirmar invitación
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      institution: "",
      director: "",
      form: {
        id: "",
        institution_id: "",
        user_type: 4,
      },
    };
  },
  async mounted() {
    let id = this.$route.params.id;
    const response = await this.getUserbyId({ payload: id });
    this.institution = response.data.inst_name;
    this.director = response.data.first_name;
  },
  methods: {
    ...mapActions({
      updateUser: "user/updateUser",
      getUserbyId: "user/getUserPatient",
    }),
    async sendInvitation() {
      try {
        this.form.id = localStorage.getItem("userId");
        this.form.institution_id = this.$route.params.id;
        await this.updateUser({ payload: this.form });
        this.$toast.show("Se acepto la invitación exitosamente ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
        this.$router.push({
          path: `/mi-institucion`,
        });
      } catch (error) {
        this.$toast.show("Error al aceptar la invitaciòn", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
        console.log(error);
      }
    },
  },
};
</script>
