<template>
     <div class="card" id="large-btn">
                  <div class="card-header pb-0">
                    <h4>Sizing buttons</h4><span>Add <code>.btn-lg</code><code>.btn-sm</code><code>.btn-xs</code> class for Different Sizing buttons</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-primary btn-lg" type="button">Primary Button</button>
                      <button class="btn btn-secondary btn-lg" type="button">Secondary Button</button>
                      <button class="btn btn-success btn-lg" type="button">Success Button</button>
                      <button class="btn btn-info btn-lg" type="button">Info Button</button>
                      <button class="btn btn-warning btn-lg" type="button">Warning Button</button>
                      <button class="btn btn-danger btn-lg" type="button">Danger Button</button>
                      <button class="btn btn-light btn-lg" type="button">Light Button</button>
                    </div>
                  </div>
                </div>
                <div class="card" id="active-btn">
                  <div class="card-header pb-0">
                    <h4>Active Buttons</h4><span>Add <code>.active</code> class for active state</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-primary active" type="button">Active</button>
                      <button class="btn btn-secondary active" type="button">Active</button>
                      <button class="btn btn-success active" type="button">Active</button>
                      <button class="btn btn-info active" type="button">Active</button>
                      <button class="btn btn-warning active" type="button">Active</button>
                      <button class="btn btn-danger active" type="button">Active</button>
                      <button class="btn btn-light active txt-dark" type="button">Active</button>
                    </div>
                  </div>
                </div>
                <div class="card" id="disabled-btn">
                  <div class="card-header pb-0">
                    <h4>Disabled buttons</h4><span>Add <code>.disabled</code> class or <code>disabled="disabled"</code> attribute for disabled button</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-primary disabled" type="button">Disabled</button>
                      <button class="btn btn-secondary disabled" type="button">Disabled</button>
                      <button class="btn btn-success disabled" type="button">Disabled</button>
                      <button class="btn btn-info disabled" type="button">Disabled</button>
                      <button class="btn btn-warning disabled" type="button">Disabled</button>
                      <button class="btn btn-danger disabled" type="button">Disabled</button>
                      <button class="btn btn-light disabled" type="button">Disabled</button>
                    </div>
                  </div>
                </div>
                 <div class="card" id="outline-button">
                  <div class="card-header pb-0">
                    <h4>outline buttons</h4><span>Add <code>.btn-outline-*</code> class for border button</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-outline-primary" type="button">Primary Button</button>
                      <button class="btn btn-outline-secondary" type="button">Secondary Button</button>
                      <button class="btn btn-outline-success" type="button">Success Button</button>
                      <button class="btn btn-outline-info" type="button">Info Button</button>
                      <button class="btn btn-outline-warning" type="button">Warning Button</button>
                      <button class="btn btn-outline-danger" type="button">Danger Button</button>
                      <button class="btn btn-outline-light txt-dark" type="button">Light Button</button>
                    </div>
                  </div>
                </div>
                <div class="card" id="outline-bold-button">
                  <div class="card-header pb-0">
                    <h4>bold Border outline buttons</h4><span>Add <code>.btn-outline-*-2x</code> class for bold outline</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-outline-primary-2x" type="button">Primary Button</button>
                      <button class="btn btn-outline-secondary-2x" type="button">Secondary Button</button>
                      <button class="btn btn-outline-success-2x" type="button">Success Button</button>
                      <button class="btn btn-outline-info-2x" type="button">Info Button</button>
                      <button class="btn btn-outline-warning-2x" type="button">Warning Button</button>
                      <button class="btn btn-outline-danger-2x" type="button">Danger Button</button>
                      <button class="btn btn-outline-light-2x txt-dark" type="button">Light Button</button>
                    </div>
                  </div>
                </div>
                <div class="card" id="gradiant">
                  <div class="card-header pb-0">
                    <h4>Gradien buttons</h4><span>Add <code>.btn-*-gradien</code> class for gradien button</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-primary-gradien" type="button">Primary Button</button>
                      <button class="btn btn-secondary-gradien" type="button">Secondary Button</button>
                      <button class="btn btn-success-gradien" type="button">Success Button</button>
                      <button class="btn btn-info-gradien" type="button">Info Button</button>
                      <button class="btn btn-warning-gradien" type="button">Warning Button</button>
                      <button class="btn btn-danger-gradien" type="button">Danger Button</button>
                    </div>
                  </div>
                </div>
</template>