<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <h4>Input</h4>
                  </div>
                  <div>
                    <div class="card-block row">
                      <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive theme-scrollbar">
                          <table class="table table-bordered checkbox-td-width">
                            <tbody>
                              <tr>
                                <td>Default Input text</td>
                                <td class="w-50">
                                  <input class="form-control input-primary" id="exampleFormControlInput1" type="email" placeholder="Input text">
                                </td>
                                <td><span>Use for basic select control.</span></td>
                              </tr>
                              <tr>
                                <td>Input Hover Color </td>
                                <td class="w-50">
                                  <input class="form-control" id="exampleFormControlInput2" type="email" placeholder="Input text">
                                </td>
                                <td><span>Use for basic input color add class<code>input-air-*</code><code>primary, secondary, success, info</code>.on input</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>