<template>
     <div class="col-sm-12 col-md-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h4>Custom Templates</h4><span>Custom templates give you full control over how suggestions get rendered</span>
                    </div>
                    <div class="card-body">
                      <div id="custom-templates">
                        <form class="theme-form">
                          <div>
                            <input class="typeahead form-control" type="text" placeholder="Oscar winners">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
</template>