<template>
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header pb-0">
                <h4>Outline Colored breadcrumb</h4><span>use class <code>.breadcrumb-colored .bg-primary</code></span>
            </div>
            <div class="card-body">
                <ol class="breadcrumb breadcrumb-colored m-b-30 bg-outline-primary">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item active">Library</li>
                </ol>
                <ol class="breadcrumb breadcrumb-colored m-b-30 bg-outline-secondary">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item active">Library</li>
                </ol>
                <ol class="breadcrumb breadcrumb-colored m-b-30 bg-outline-success">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item active">Library</li>
                </ol>
                <ol class="breadcrumb breadcrumb-colored m-b-30 bg-outline-info">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item active">Library</li>
                </ol>
                <ol class="breadcrumb breadcrumb-colored m-b-30 bg-outline-warning">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item active">Library</li>
                </ol>
                <ol class="breadcrumb breadcrumb-colored m-b-30 bg-outline-danger">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item active">Library</li>
                </ol>
                <ol class="breadcrumb breadcrumb-colored m-b-30 bg-outline-light">
                    <li class="breadcrumb-item"><a class="txt-dark" href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item txt-dark active">Library</li>
                </ol>
                <ol class="breadcrumb breadcrumb-colored m-b-0 bg-outline-dark">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                    <li class="breadcrumb-item active">Library</li>
                </ol>
            </div>
        </div>
    </div>
</template>