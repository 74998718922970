<template>
  <div class="col-lg-12 col-md-12 box-col-12">
    <div class="card best-seller">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <div class="flex-grow-1">
            <p class="square-after f-w-600">
              Hoy ( {{ directorSesions?.length }} )<i class="fa fa-circle"> </i>
            </p>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="theme-scrollbar data-table tabla_all_data">
          <table
            class="table table-striped table-bordered table_all"
            id="table_today"
          >
            <thead>
              <tr>
                <th>Hora</th>
                <th>Psicólogo</th>
                <th>Paciente</th>
                <th>Modalidad</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in directorSesions" :key="item">
                <td>{{ obtenerHoraDesdeFecha(item.date_hour) }}</td>
                <td>{{ item.psyco.first_name }} {{ item.psyco.last_name }}</td>
                <td>
                  {{ item.patient.first_name }} {{ item.patient.last_name }}
                </td>
                <td>{{ item.session_mode === 1 ? "Presencial" : "Remoto" }}</td>
                <td>
                  <button class="btn" @click="changeStatus(item)">
                    <i class="icon-archive text-primary"></i>
                  </button>
                  <span>
                    <router-link
                      :to="`/nuevo-paciente/${item.id}?step=generalInfo`"
                      >Edit</router-link
                    ></span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#table_today tbody tr td {
  text-align: center !important;
}
#activess_patients tbody tr td {
  text-align: center !important;
}
#table_archived tbody tr td {
  text-align: center !important;
}
#table_archived thead tr th {
  font-size: 13px !important;
}
#total_servicios tbody tr:nth-of-type(odd) {
}
</style>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["directorSesions"],

  async mounted() {
    $("#table_today").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });
    $("#activess_patients").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });
    $("#table_archived").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });
    $("#table_casos_asignados").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });
    $("#total_archivados").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });
    $("#total_servicios").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });
  },
  methods: {
    ...mapActions({
      getPatients: "director/getPatientsById",
      getPatientsArchived: "patients/getPatientsArchived",
      updateUser: "user/updateUser",
    }),
    formattedDate(date) {
      const timestamp = date;
      const fecha = new Date(timestamp * 1000);

      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const año = fecha.getFullYear();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
      const segundos = fecha.getSeconds();
      return ` ${hora}:${minutos}:${segundos}`;
    },
    obtenerHoraDesdeFecha(fechaCompleta) {
      console.log("llega", horaFormateada);
      const fecha = new Date(fechaCompleta);

      const horas = fecha.getHours();
      const minutos = fecha.getMinutes();
      const segundos = fecha.getSeconds();

      const horaFormateada = `${horas}:${minutos}:${segundos}`;
      return horaFormateada;
    },
    async changeStatus(patient) {
      try {
        const change = {
          id: patient.id,
          user_type: patient.user_type,
          psychologist_id: patient.psychologist_id,
          person_type: patient.person_type,
          enable_sys: false,
        };
        const response = await this.updateUser({ payload: change });
        await this.getPatients();
        await this.getPatientsArchived();
        this.$toast.show("Se cambio de estado exitosamente", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
      } catch (e) {
        this.$toast.show("No se pudo cambiar de estado", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>
