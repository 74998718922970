import axios from "axios";

const HOST = process.env.VUE_APP_API_SEARCH;

export default {
  async searchPsycos(payload = {}) {
    try {
      const data = payload.payload || {};
      const response = await axios({
        url: `${HOST}`,
        method: "post",
        data,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
