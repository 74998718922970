<template>
  <div class="left-menu-header">
    <ul class="app-list">
      <li class="onhover-dropdown">
        <div class="app-menu">
          <i data-feather="folder-plus"
            ><vue-feather
              type="folder-plus"
              @click="collapseFilter()"
            ></vue-feather
          ></i>
        </div>
        <ul
          class="onhover-show-div left-dropdown"
          :class="filtered ? 'active' : ''"
        >
          <li>
            <router-link to="/app/file_manager">File Manager </router-link>
          </li>
          <li>
            <router-link to="/app/kanbanBoard"> Kanban board</router-link>
          </li>
          <li><router-link to="/pages/social-app"> Social App</router-link></li>
          <li><router-link to="/app/bookmark"> Bookmark</router-link></li>
        </ul>
      </li>
    </ul>
    <!-- <ul class="header-left">
      <li><router-link to="/app/file_manager">Nuevo Paciente</router-link></li>
    </ul> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtered: false,
    };
  },
  methods: {
    collapseFilter() {
      this.filtered = !this.filtered;
    },
  },
};
</script>
