import * as types from "../mutation-types";
import feedAPI from "@/api/feed";

const state = {
  posts: [],
  tags: [],
};

const getters = {
  posts: (state) => state.posts,
};

const actions = {
  async getPosts({ commit }, payload) {
    try {
      const response = await feedAPI.getPosts(payload);
      const posts = response.data.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      commit(types.SET_POSTS, { posts });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createPost({ commit }, payload) {
    try {
      const response = await feedAPI.createPost(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updatePost({ commit }, payload) {
    try {
      const response = await feedAPI.updatePost(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createComment({ commit }, payload) {
    try {
      const response = await feedAPI.createComment(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getComments({ commit }, payload) {
    try {
      const response = await feedAPI.getComments(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getTags({ commit }, payload) {
    try {
      const response = await feedAPI.getTags(payload);
      let tags = response.data;
      tags = tags.sort((a, b) => b.counter_tag - a.counter_tag);
      commit(types.SET_TAGS, { tags });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getPostsForTags({ commit }, payload) {
    try {
      const response = await feedAPI.getPostsForTags(payload);
      const posts = response.data;
      commit(types.SET_POSTS, { posts });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_POSTS](state, { posts }) {
    state.posts = posts;
  },
  [types.SET_TAGS](state, { tags }) {
    state.tags = tags;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
