<template>
    <div class="customizer-links" :class="{ open: customizer }">
        
          </div>
</template>
<script>
 import { mapState } from 'vuex';
 import customizerColor from "./customizerColor.vue"
 import customizerconfiguration from "./configurationView.vue"
 import customColor from "./customColor.vue"
 import customizerSetting from "./customizerSetting.vue"
 import customSetting from "./customSetting.vue"
export default {
    components:{
      customizerColor,
      customizerconfiguration,
      customColor,
      customizerSetting,
      customSetting
    },
     computed: {
      ...mapState({
        customizer: (state) => state.menu.customizer,
      }),
    },
}
</script>