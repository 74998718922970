import users from "../../data/users.json"

const state = {
	data : users.data,
   
};


export default {
	namespaced: true,
	state,
	
};