<template>
     <div class="col-sm-12 col-xl-6 box-col-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h4>Initials</h4>
                    </div>
                    <div class="card-body"> 
                      <div class="avatar-showcase">
                        <div class="avatars">
                          <div class="avatar"><img class="img-100 rounded-circle" src="@/assets/images/user/11.png" alt="#"></div>
                          <div class="avatar"><img class="img-90 rounded-circle" src="@/assets/images/user/12.png" alt="#"></div>
                          <div class="avatar"><img class="img-80 rounded-circle" src="@/assets/images/user/14.png" alt="#"></div>
                          <div class="avatar"><img class="img-70 rounded-circle" src="@/assets/images/user/2.png" alt="#"></div>
                          <div class="avatar"><img class="img-60 rounded-circle" src="@/assets/images/user/3.png" alt="#"></div>
                          <div class="avatar"><img class="img-50 rounded-circle" src="@/assets/images/user/12.png" alt="#"></div>
                          <div class="avatar"><img class="img-40 rounded-circle" src="@/assets/images/user/10.jpg" alt="#"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</template>