<template>
  <div class="card_tab">
    <div class="content-header">
      <Breadcrumbs main="" title="Nueva sesión" />
      <button v-if="isEdit" class="small-cancel-button" @click="clearData">
        Cancelar
      </button>
      <button
        class="small-primary-button"
        @click="submitData"
        :disabled="
          !form.session_date &&
          !form.objective_target &&
          !form.observations &&
          !form.summary
        "
      >
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span v-else>
          <i class="fa fa-bookmark-o mx-2" aria-hidden="true"></i>
          Guardar
        </span>
      </button>
    </div>

    <div class="sesion-section mt-3">
      <div class="col-12">
        <div class="row">
          <div class="mb-3 col-md-4">
            <div v-if="errorMessages.session_date" class="error-message">
              {{ errorMessages.session_date }}
            </div>
            <div class="form-group">
              <label>Fecha</label>
              <input
                class="form-control"
                type="datetime-local"
                v-model="form.session_date"
                :format="format"
                placeholder="Fecha"
                :min="minDate"
              />
            </div>
          </div>
          <div class="mb-3 col-md-4">
            <div class="form-group">
              <label>Modalidad</label>
              <select
                class="form-select"
                id="exampleFormControlSelect9"
                placeholder="Modalidad"
                v-model="form.session_mode"
              >
                <option value="1">Presencial</option>
                <option value="0">Virtual</option>
              </select>
            </div>
          </div>
          <div class="mb-3 col-md-4">
            <div class="form-group">
              <label>Atención</label>
              <select
                class="form-select"
                v-model="form.companionship"
                id="exampleFormControlSelect9"
              >
                <option value="1">Acompañado</option>
                <option value="0">Solo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-lg-4 col-md-4 col-12">
            <label class="d-block">Resumen de la sesión:</label>
            <textarea
              class="form-control"
              type="textarea"
              placeholder="Resumen de la sesión"
              rows="5"
              v-model="form.summary"
            ></textarea>
            <div v-if="errorMessages.summary" class="error-message">
              {{ errorMessages.summary }}
            </div>
          </div>
          <div class="mb-3 col-lg-4 col-md-4 col-12">
            <label class="d-block">Observaciones:</label>
            <textarea
              class="form-control"
              type="textarea"
              placeholder="Observaciones"
              rows="5"
              v-model="form.observations"
            ></textarea>
          </div>
          <div v-if="patient" class="mb-3 col-lg-4 col-md-4 col-12">
            <label class="d-block">Objetivos a lograr:</label>
            <select v-model="form.objective_target" class="form-select">
              <option
                v-for="item in patient.treatment.objective"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
            <div v-if="errorMessages.objective_target" class="error-message">
              {{ errorMessages.objective_target }}
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="col-12">
            <h3 class="mb-4 h3_personal_history">Adjuntar Documentación</h3>
          </div>
          <div
            v-for="(file, index) in form.session_files"
            :key="index"
            class="row align-items-center"
          >
            <div class="col-lg-4 col-md-4 col-12 mb-3">
              <label class="col-form-label">Ingresar nombre de archivo</label>
              <input
                class="form-control"
                type="text"
                id="namefileNew"
                v-model="file.name"
              />
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 mb-3 mt-4"
              style="display: flex"
            >
              <input
                class="form-control"
                type="file"
                id="formfileNew"
                ref="fileInput"
                @change="(e) => handleFileChange(e, index)"
              />
              <a
                v-if="file.url_file"
                :href="file.url_file"
                download
                style="margin-left: 10px"
              >
                {{ file.name }}
                <i class="fa fa-download"></i>
              </a>
              <span
                v-if="index !== 0"
                class="input-group-text"
                style="cursor: pointer; color: #bb0c0c; border: none"
                @click="removeFile(index)"
                ><i class="fa fa-minus"></i
              ></span>
            </div>
          </div>
          <div class="col-12 mb-4">
            <button class="btn p-0" @click="addFile">+ Agregar Archivo</button>
          </div>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-12">
          <h3>Mis Sesiones</h3>

          <table class="table table-hover mt-4">
            <thead>
              <tr>
                <th scope="col"># Sesión</th>
                <th scope="col">Fecha</th>
                <th scope="col">Tratamiento</th>
                <th scope="col">Atendido</th>
                <th scope="col">Modalidad</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sesion, index) in sesiones" :key="sesion.id">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ convertISOTo12HourFormatWithDate(sesion.session_date) }}
                </td>
                <td>{{ sesion.objective_target }}</td>
                <td>{{ sesion.companionship == 1 ? "Acompañado" : "Solo" }}</td>
                <td>
                  {{ sesion.session_mode == 1 ? "Presencial" : "Remoto" }}
                </td>
                <td>
                  <span class="edit" @click="showEdit(sesion)">
                    <a><i class="icon-pencil-alt"></i></a
                  ></span>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="showModal(sesion)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade modal_confirm"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="margin-top: 10px; background: white">
        <div class="modal-content">
          <div v-if="modalData" class="card">
            <div class="modal-body">
              <div class="text-center profile-details mt-10">
                <h4>Estas seguro de eliminar esta sesión?</h4>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="small-primary-button"
                @click="confirmDelete"
              >
                Confirmar
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                @click="closeModal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapState, mapActions } from "vuex";
import { uploadFile } from "../../utils/s3Service";
export default {
  data() {
    return {
      errorMessages: {
        session_date: "",
        objective_target: "",
        summary: "",
        companionship: "",
        session_mode: "",
      },
      sesiones: [],
      errorMessage: "",
      showConfirmModal: false,
      isEdit: false,
      modalData: "",
      loading: false,
      photo_session: "",
      father_consent: "",
      toxicological: "",
      objetives: [],
      id: "",
      minDate: "",
      form: {
        session_mode: 1,
        companionship: 1,
        session_date: "",
        summary: "",
        observations: "",
        objective_target: "",
        last_session_date: "",
        session_files: [{ name: "", url_file: null }],
        patient_id: "",
        professional_id: "",
      },
    };
  },
  computed: {
    ...mapState({
      treatments: (state) => state.treatment.treatments,
      userSesions: (state) => state.sesion.userSesions,
      patient: (state) => state.user.patient,
      step: (state) => state.patients.step,
    }),
  },
  watch: {
    step(newStep, oldStep) {
      this.clearData();
      this.isEdit = false;
      if (newStep !== "therapeuticSessions") return;
      this.getDataById();
    },
  },
  setup() {
    const date = ref();

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return ` ${day}/${month}/${year}`;
    };

    return {
      date,
      format,
    };
  },
  async mounted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    this.minDate = `${year}-${month}-${day}`;
    await this.getDataById();
  },
  methods: {
    ...mapActions({
      createSesion: "sesion/createSesion",
      getTreatments: "treatment/getTreatments",
      getSesionsById: "sesion/getSesionsById",
      deleteSesion: "sesion/deleteSesion",
      updateSesion: "sesion/updateSesion",
      getUserPatient: "user/getUserPatient",
      createCalendar: "calendar/createCalendar",
    }),
    submitData() {
      // Reinicia los mensajes de error
      this.errorMessages = {
        session_date: "",
        objective_target: "",
        summary: "",
        companionship: "",
        session_mode: "",
        // Reinicia más campos según sea necesario
      };

      // Verifica campos requeridos
      if (!this.form.session_date) {
        this.errorMessages.session_date = "Por favor, ingrese la fecha.";
      }

      if (!this.form.objective_target) {
        this.errorMessages.objective_target =
          "Por favor, seleccione un objetivo.";
      }

      if (!this.form.summary) {
        this.errorMessages.summary = "Completar Campo";
      }
      if (!this.form.companionship) {
        this.errorMessages.companionship = "Por favor, selecciona una opción";
      }
      if (!this.form.session_mode) {
        this.errorMessages.session_mode = "Por favor, selecciona una opción";
      }
      // Verifica si hay algún mensaje de error
      const hasErrors = Object.values(this.errorMessages).some(
        (message) => message !== ""
      );

      if (hasErrors) {
        // Puedes mostrar mensajes de error en la consola
        console.log("Campos obligatorios no completados", this.errorMessages);

        // Puedes mostrar los mensajes de error en la interfaz de usuario o realizar alguna otra acción
        return;
      }
      // Resto del código para enviar los datos
      if (!this.isEdit) {
        this.submitSesion();
      } else {
        this.editSesion();
      }
      // this.clearData();
    },

    clearData() {
      this.sesiones = [];
      var inputImage = document.getElementById("formfileNew");
      inputImage.value = "";
      this.form = {
        session_mode: 1,
        companionship: 1,
        session_date: "",
        summary: "",
        observations: "",
        objective_target: "",
        last_session_date: "",
        session_files: [{ name: "", url_file: null }],
        patient_id: "",
        professional_id: "",
      };
    },
    showModal(data) {
      this.modalData = data;
      const modal = document.getElementById("myModal");
      modal.classList.add("show");
      modal.style.display = "block";
    },
    closeModal() {
      const modal = document.getElementById("myModal");
      modal.classList.add("show");
      modal.style.display = "none";
      this.modalData = "";
    },
    async confirmDelete() {
      const payload = {
        id: this.modalData.id,
      };
      const data = {
        id: this.$route.params.id,
      };
      await this.deleteSesion(payload);
      await this.getSesionsById({ payload: data });
      this.$toast.show("Sesion eliminada ", {
        theme: "outline",
        position: "top-right",
        icon: "times",
        type: "success",
        duration: 2000,
      });
      this.closeModal();
    },
    async getDataById() {
      if (this.step !== "therapeuticSessions") return;
      if (this.$route.params.id !== undefined) {
        const data = {
          id: this.$route.params.id,
        };
        await this.getUserPatient({ payload: this.$route.params.id });
        await this.getSesionsById({ payload: data });
        this.sesiones = this.userSesions;
        console.log("sesiones", this.sesiones);
      }
    },
    showEdit(data) {
      this.isEdit = true;
      this.id = data.id;
      this.form.session_date = data.session_date;
      this.form.companionship = data.companionship;
      this.form.objective_target = data.objective_target;
      this.form.observations = data.observations;
      this.form.patient_id = data.patient_id;
      this.form.professional_id = data.professional_id;
      this.form.last_session_date = data.last_session_date;
      this.form.session_files = data.session_files;
      this.form.summary = data.summary;
    },
    addFile() {
      this.form.session_files.push({ name: "", url_file: null });
    },
    removeFile(index) {
      this.form.session_files.splice(index, 1);
    },
    handleFileChange(event, index) {
      this.photo_session = event.target.files[0];
      this.uploadFiles1(index);
    },
    uploadFiles1(index) {
      if (this.photo_session) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.photo_session
        )
          .then((data) => {
            this.form.session_files[index].url_file = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleFileChange2(event) {
      this.father_consent = event.target.files[0];
      this.uploadFiles2();
    },
    uploadFiles2() {
      if (this.father_consent) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.father_consent
        )
          .then((data) => {
            this.form.session_files.push({ father_consent: data.Location });
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleFileChange3(event) {
      this.toxicological = event.target.files[0];
      this.uploadFiles3();
    },
    uploadFiles3() {
      if (this.toxicological) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.toxicological
        )
          .then((data) => {
            this.form.session_files.push({ toxicological: data.Location });
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    getDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);

      // Obtener la hora y los minutos
      const horas = dateTime.getHours();
      const minutos = dateTime.getMinutes();

      // Formatear la hora como un string
      const horaFormateada = `${horas.toString().padStart(2, "0")}:${minutos
        .toString()
        .padStart(2, "0")}`;
      return horaFormateada;
    },
    async editSesion() {
      if (!this.form.session_date && !this.form.objective_target) {
        return;
      }
      try {
        this.loading = true;
        this.form.id = this.id;
        const response = await this.updateSesion({ payload: this.form });
        const data = {
          id: this.$route.params.id,
        };
        this.form = {
          session_mode: 1,
          companionship: 1,
          session_date: "",
          summary: "",
          observations: "",
          objective_target: "",
          last_session_date: "",
          session_files: [{ name: "", url_file: null }],
          patient_id: "",
          professional_id: "",
        };
        await this.getSesionsById({ payload: data });
        this.loading = false;
        this.isEdit = false;
        this.$refs.fileInput.value = "";
        this.$toast.show("Editado exitosamente ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
        this.sesiones = this.userSesions;
      } catch (e) {
        this.isEdit = false;
        this.loading = false;
        this.$toast.show("Verificar bien los datos de ingreso", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
    convertISOTo12HourFormatWithDate(isoDateTimeString) {
      const date = new Date(isoDateTimeString);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours();
      let minutes = date.getMinutes();

      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? hours : 12;

      minutes = minutes < 10 ? "0" + minutes : minutes;

      const twelveHourFormatWithDate = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;

      return twelveHourFormatWithDate;
    },
    async submitSesion() {
      if (!this.form.session_date && !this.form.objective_target) {
        this.clearData();
        return;
      }
      try {
        this.loading = true;
        this.form.patient_id = this.$route.params.id;
        this.form.professional_id = this.patient.psychologist_id;
        const response = await this.createSesion({ payload: this.form });
        let form = {
          title: this.form.summary,
          description: this.form.observations,
          date: this.form.session_date,
          start_hour: this.getDateTime(this.form.session_date),
          end_hour: this.getDateTime(this.form.session_date),
          user_id: this.patient.psychologist_id,
          last_session_date: this.form.session_date,
          patient_name: this.patient.first_name + " " + this.patient.last_name,
        };
        const res = await this.createCalendar({ payload: form });
        const data = {
          id: this.$route.params.id,
        };
        this.form = {
          session_mode: 1,
          companionship: 1,
          session_date: "",
          summary: "",
          observations: "",
          objective_target: "",
          last_session_date: "",
          session_files: [{ name: "", url_file: null }],
          patient_id: "",
          professional_id: "",
        };
        this.clearData();
        this.$refs.fileInput.value = "";
        await this.getUserPatient({ payload: this.$route.params.id });
        await this.getSesionsById({ payload: data });
        this.sesiones = this.userSesions;
        this.loading = false;
        this.$toast.show("Creado exitosamente ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
      } catch (error) {
        this.loading = false;
        this.$toast.show("Verificar bien los datos de ingreso", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>
<style scoped>
.error-message {
  color: #ff0000; /* Color rojo o el que prefieras */
  margin-top: 10px;
}
.small-cancel-button {
  width: 200px;
  font-size: 0.8rem;
  /* Adjust the font size to make it smaller */
  padding: 0px;
  /* Adjust the padding to make it smaller */
  background-color: #e0c342;
  /* Customize the background color */
  color: #fff;
  /* Customize the text color */
  border: none;
  /* Remove the border */
  cursor: pointer;
  font-weight: 700;
  border-radius: 20px;
  height: 40px;
  margin-right: 4px;
}
</style>
