<template>
     <div class="col-sm-6"> 
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Basic Progress Bars</h4><span>Progress components are built with two HTML elements, some CSS to set the width, and a few attributes.</span>
                  </div>
                  <div class="card-body progress-showcase">
                    <div class="row">
                      <div class="col">
                        <div class="progress">
                          <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress">
                          <div class="progress-bar bg-secondary" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress">
                          <div class="progress-bar bg-success" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress">
                          <div class="progress-bar bg-info" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>