import axios from "axios";

const HOST = process.env.VUE_APP_API_CALENDAR;

export default {
  async createCalendar(payload = {}) {
    try {
      const data = payload.payload || {};
      const response = await axios({
        url: `${HOST}/calendar`,
        method: "post",
        data,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getCalendar(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/calendar/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getWeekly(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/calendar/weekly/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getMonthly(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/calendar/monthly/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getWeeklyDirector(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/calendar/weekly/institution/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getMonthlyDirector(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/calendar/monthly/institution/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
