<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card">
          <div>
            <div class="text-center">
              <a
                class="logo text-center"
                style="height: 100%; display: flex; justifycontent: center"
                ><router-link to="/">
                  <img
                    class="img-fluid for-light"
                    style="width: 150px; margin-bottom: 2rem; margin-top: 4rem"
                    src="/img/logo.png"
                    alt="looginpage" /></router-link
              ></a>
            </div>
            <div class="login-main main_change_password">
              <div class="theme-form">
                <h4 class="text-center">Nueva Contraseña</h4>
                <p class="text-center">Ingresa tu nueva contraseña</p>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <input
                    v-model="form.password"
                    :type="showPasswordRecover ? 'text' : 'password'"
                    class="form-control"                    
                    placeholder="nuevo password"
                  />
                  <div class="show-hide" @click="togglePasswordVisibilityRecover">
                      <span :class="{ show: showPasswordRecover }">{{
                        showPasswordRecover ? "Ocultar" : "Mostrar"
                      }}</span>
                    </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Confirmar contraseña</label>
                  <input
                    v-model="form.conf_password"
                    class="form-control"
                    :type="showPasswordRecoverConfirm ? 'text' : 'password'"
                    placeholder="confirmar password"
                  />
                  <div class="show-hide" @click="togglePasswordVisibilityRecoverConfirm">
                      <span :class="{ show: showPasswordRecoverConfirm }">{{
                        showPasswordRecoverConfirm ? "Ocultar" : "Mostrar"
                      }}</span>
                    </div>
                </div>
                <div
                  v-if="passwordMismatch"
                  class="alert alert-danger"
                  role="alert"
                >
                  Las contraseñas no coinciden.
                </div>
                <div class="form-group">
                  <button
                    @click="submitUser"
                    class="btn btn-primary btn-block w-100 mt-3"
                  >
                    Crear nueva contraseña
                  </button>
                </div>
                <div class="center">
                  <a class="text-center"
                    ><router-link to="/"> ir al login</router-link></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_change_password .theme-form .form-group .show-hide{
  top: 70% !important;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showPasswordRecover: false,
      showPasswordRecoverConfirm: false,
      form: {
        password: "",
        conf_password: "",
        token: "",
      },
      passwordMismatch: false,
    };
  },

  methods: {
    ...mapActions({
      reset: "auth/reset",
    }),
    togglePasswordVisibilityRecover() {
      this.showPasswordRecover = !this.showPasswordRecover;
    },
    togglePasswordVisibilityRecoverConfirm() {
      this.showPasswordRecoverConfirm = !this.showPasswordRecoverConfirm;
    },
    async submitUser() {
      try {
        if (this.form.password !== this.form.conf_password) {
          this.passwordMismatch = true;
          return;
        }
        this.passwordMismatch = false;
        this.form.token = this.$route.params.token;
        const response = await this.reset({ payload: this.form });
        this.$toast.show("Se creo correctamente su contraseña ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
        this.$router.push("/auth/login");
      } catch (error) {
        this.$toast.show(error.response.data.msg, {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 20000,
        });
      }
    },
  },
};
</script>
