<template>
     <div class="col-xl-6 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Text Color</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.font-primary {
color: #5c61f2 !important;
}
.font-secondary {
color: #eeb82f !important;
}
.font-success {
color: #51bb25 !important;
}
.font-danger {
color: #dc3545 !important;
}
.font-info {
color: #a927f9 !important;
}
.font-light {
color: #f4f4f4 !important;
}
.font-dark {
color: #898989 !important;
}
.font-warning {
color: #f8d62b !important;
}</pre>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Badge Color</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes">.badge-primary {
background-color: #5c61f2;
}
.badge-secondary {
background-color: #eeb82f;
}
.badge-success {
background-color: #51bb25;
}
.badge-danger {
background-color: #dc3545;
}
.badge-info {
background-color: #a927f9;
}
.badge-light {
background-color: #f4f4f4;
}
.badge-dark {
background-color: #898989;
}
.badge-warning {
background-color: #f8d62b;
}</pre>
                  </div>
                </div>
              </div>
              
</template>