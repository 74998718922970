<template>
     <div class="col-lg-4 col-md-6 box-col-4">
                    <div class="card visitor-card">
                      <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                          <div class="flex-grow-1">
                            <p class="square-after f-w-600 header-text-info">Our Total Visitor<i class="fa fa-circle"> </i></p>
                            <h4>813K</h4>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <div class="profit-wrapper header-text-info icon-bg-info"><i class="fa fa-arrow-up"></i></div>
                            <h6 class="header-text-info">86.94%</h6>
                            <p class="mb-0">More Than last month</p>
                          </div>
                        </div>
                        <div class="right-side icon-right-info"><i class="fa fa-user"></i>
                          <div class="shap-block">
                            <div class="rounded-shap animate-bg-primary"><i></i><i></i></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
</template>