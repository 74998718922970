<template>
  <Breadcrumbs main="Home" title="MINSALAB" />

  <div class="minsalab mb-5">
    <div class="container">
      <div class="row">
        <h3 class="mb-lg-2 mb-2 title_h3_page">HOY (5)</h3>
        <div class="table-responsive theme-scrollbar">
          <table class="table table-hover mt-4 table_listado_pacientes">
            <thead class="table-color">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Hora</th>
                <th scope="col">Nombres y Apellidos</th>
                <th scope="col">Paciente</th>
                <th scope="col">Modalidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th><i class="fa fa-check-square-o"></i></th>
                <th>10:30am</th>
                <th>Ps. Luis Valvin</th>
                <th>Rosario Alcocer</th>
                <th>Presencial</th>
              </tr>
              <tr>
                <th><i class="fa fa-check-square-o"></i></th>
                <th>11:30am</th>
                <th>Ps. Luis Valvin</th>
                <th>Rosario Alcocer</th>
                <th>Presencial</th>
              </tr>
              <tr>
                <th><i class="fa fa-check-square-o"></i></th>
                <th>11:50am</th>
                <th>Ps. Luis Valvin</th>
                <th>Rosario Alcocer</th>
                <th>Presencial</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <h3 class="mt-lg-5 mt-4 mb-4 title_h3_page">BANDEJA DE MENSAJE</h3>

        <div class="container-fluid">
          <div class="row">
            <searchChat />
            <div class="col call-chat-body">
              <div class="card">
                <div class="card-body p-0">
                  <div class="row chat-box">
                    <chatRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <h3 class="mt-5 mb-3 p-0">Calendario Semanal/ manual de sesiones</h3>
        <calendarView />
      </div>

      <div class="row mb-5">
        <h3 class="mt-3 mb-4 title_h3_page">Todos tus pacientes</h3>
        <div class="table-responsive theme-scrollbar">
          <table class="table table-hover mt-3 table_listado_pacientes">
            <thead class="table-color">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Fecha Inicio</th>
                <th scope="col">Hist. Clin. (DNI)</th>
                <th scope="col">Nombres y Apellidos</th>
                <th scope="col">Edad</th>
                <th scope="col">Genero</th>
                <th scope="col">PreDX</th>
                <th scope="col">Conteo sesiones</th>
                <th scope="col">Ultima cita</th>
                <th scope="col">ESTADO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ap in activePatients">
                <th>{{ ap.id }}</th>
                <th>{{ ap.initDate }}</th>
                <th>{{ ap.clinicHistory }}</th>
                <th>{{ ap.fullName }}</th>
                <th>{{ ap.age }}</th>
                <th>{{ ap.genre }}</th>
                <th>{{ ap.preDx }}</th>
                <th>{{ ap.sessionCount }}</th>
                <th>{{ ap.lastAppointment }}</th>
                <th>{{ ap.state }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.minsalab {
  margin-bottom: 30px;
  .title_h3_page {
    font-size: 20px;
    color: #2b2b2b;
    margin: 0 0 10px 0;
    padding: 0px;
  }
  .list-appointments {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 20px;

    li {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}
.table-color tr th {
  background-color: #171829;
  color: #ffffff;
}
</style>

<script>
import searchChat from "@/components/common/searchChat.vue";
import chatRight from "@/components/chat/chatapp/chatRight.vue";
import calendarView from "@/components/calendar/calendarView.vue";

export default {
  components: {
    searchChat,
    chatRight,
    calendarView,
  },
  data() {
    return {
      activePatients: [
        {
          img: "https://media.licdn.com/dms/image/C4E03AQGlLNZfbT2-GA/profile-displayphoto-shrink_800_800/0/1632252436357?e=2147483647&v=beta&t=ZlVG_X0wsjhgaqts_AOYbsRXEpki43u_OdBB7FSamOo",
          id: "1254",
          initDate: "15-02-2023",
          clinicHistory: "52658874",
          fullName: "Franco Escobar Torres",
          age: "25",
          genre: "Masculino",
          preDx: "152",
          sessionCount: "52",
          lastAppointment: "25-05-2023",
          nextAppointment: "25-06-2023",
          state: "activo",
        },
        {
          img: "https://media.licdn.com/dms/image/C4E03AQGlLNZfbT2-GA/profile-displayphoto-shrink_800_800/0/1632252436357?e=2147483647&v=beta&t=ZlVG_X0wsjhgaqts_AOYbsRXEpki43u_OdBB7FSamOo",
          id: "1254",
          initDate: "15-02-2023",
          clinicHistory: "52658874",
          fullName: "Franco Escobar Torres",
          age: "25",
          genre: "Masculino",
          preDx: "152",
          sessionCount: "52",
          lastAppointment: "25-05-2023",
          nextAppointment: "25-06-2023",
          state: "activo",
        },
        {
          img: "https://media.licdn.com/dms/image/C4E03AQGlLNZfbT2-GA/profile-displayphoto-shrink_800_800/0/1632252436357?e=2147483647&v=beta&t=ZlVG_X0wsjhgaqts_AOYbsRXEpki43u_OdBB7FSamOo",
          id: "1254",
          initDate: "15-02-2023",
          clinicHistory: "52658874",
          fullName: "Franco Escobar Torres",
          age: "25",
          genre: "Masculino",
          preDx: "152",
          sessionCount: "52",
          lastAppointment: "25-05-2023",
          nextAppointment: "25-06-2023",
          state: "activo",
        },
      ],
    };
  },
};
</script>
