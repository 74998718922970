<template>
     <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Animated radio buttons</h4>
                  </div>
                  <div class="card-body animate-chk">
                    <div class="row">
                      <div class="col">
                        <label class="d-block" for="edo-ani">
                          <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani" checked="">Option 1
                        </label>
                        <label class="d-block" for="edo-ani1">
                          <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">Option 2
                        </label>
                        <label class="d-block" for="edo-ani2">
                          <input class="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" checked="">Option 3
                        </label>
                        <label class="d-block" for="edo-ani13">
                          <input class="radio_animated" id="edo-ani13" type="radio" name="rdo-ani">Option 4
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Animated checkbox buttons</h4>
                  </div>
                  <div class="card-body animate-chk">
                    <div class="row">
                      <div class="col">
                        <label class="d-block" for="chk-ani">
                          <input class="checkbox_animated" id="chk-ani" type="checkbox" checked="">Option 1
                        </label>
                        <label class="d-block" for="chk-ani1">
                          <input class="checkbox_animated" id="chk-ani1" type="checkbox">Option 2
                        </label>
                        <label class="d-block" for="chk-ani2">
                          <input class="checkbox_animated" id="chk-ani2" type="checkbox" checked="">Option 3
                        </label>
                        <label class="d-block" for="chk-ani3">
                          <input class="checkbox_animated" id="chk-ani3" type="checkbox">Option 4
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>