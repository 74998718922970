<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
                  <div class="card">
                    <div class="card-header pb-0">
                      <h4>Shape</h4>
                    </div>
                    <div class="card-body"> 
                      <div class="avatar-showcase">
                        <div class="avatars">
                          <div class="avatar"><img class="img-100 b-r-8" src="@/assets/images/user/6.jpg" alt="#"></div>
                          <div class="avatar"><img class="img-90 b-r-30" src="@/assets/images/user/6.jpg" alt="#"></div>
                          <div class="avatar"><img class="img-80 b-r-35" src="@/assets/images/user/6.jpg" alt="#"></div>
                          <div class="avatar"><img class="img-70 rounded-circle" src="@/assets/images/user/6.jpg" alt="#"></div>
                          <div class="avatar"><img class="img-60 b-r-25" src="@/assets/images/user/6.jpg" alt="#"></div>
                          <div class="avatar"><img class="img-50 b-r-15" src="@/assets/images/user/6.jpg" alt="#"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</template>