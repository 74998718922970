<template>
     <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 17</h6>
                        <div class="loader-box"><div class="loader-17"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 18</h6>
                        <div class="loader-box"><div class="loader-18"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 19</h6>
                        <div class="loader-box"><div class="loader-19"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 20</h6>
                        <div class="loader-box"><div class="loader-20"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 21</h6>
                        <div class="loader-box"><div class="loader-21"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 22</h6>
                        <div class="loader-box"><div class="loader-22"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 23</h6>
                        <div class="loader-box"><div class="loader-23"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 24</h6>
                        <div class="loader-box"><div class="loader-24"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 25</h6>
                        <div class="loader-box"><div class="loader-25"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 26</h6>
                        <div class="loader-box"><div class="loader-26"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 27</h6>
                        <div class="loader-box"><div class="loader-27"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 28</h6>
                        <div class="loader-box"><div class="loader-28"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 29</h6>
                        <div class="loader-box"><div class="loader-29"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 30</h6>
                        <div class="loader-box"><div class="loader-30"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 31</h6>
                        <div class="loader-box"><div class="loader-31"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 32</h6>
                        <div class="loader-box"><div class="loader-32"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 33</h6>
                        <div class="loader-box"><div class="loader-33"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 34</h6>
                        <div class="loader-box"> <div class="loader-34"></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h6 class="sub-title mb-0 text-center">Loader 35</h6>
                        <div class="loader-box"><div class="loader-35"></div>
                        </div>
                      </div>
</template>