<template>
     <div class="col-sm-12 col-xl-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Very Thin (100)</h4>
                  </div>
                  <div class="card-body typography">
                    <h1 class="f-w-100">Heading 1</h1>
                    <h2 class="f-w-100">Heading 2</h2>
                    <h3 class="f-w-100">Heading 3</h3>
                    <h4 class="f-w-100">Heading 4</h4>
                    <h5 class="f-w-100">Heading 5</h5>
                    <h6 class="f-w-100">Heading 6</h6>
                  </div>
                </div>
              </div>
               <div class="col-sm-12 col-xl-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Thin (300)</h4>
                  </div>
                  <div class="card-body typography">
                    <h1 class="f-w-300">Heading 1</h1>
                    <h2 class="f-w-300">Heading 2</h2>
                    <h3 class="f-w-300">Heading 3</h3>
                    <h4 class="f-w-300">Heading 4</h4>
                    <h5 class="f-w-300">Heading 5</h5>
                    <h6 class="f-w-300">Heading 6</h6>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-xl-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Normal (400)</h4>
                  </div>
                  <div class="card-body typography">
                    <h1 class="f-w-400">Heading 1</h1>
                    <h2 class="f-w-400">Heading 2</h2>
                    <h3 class="f-w-400">Heading 3</h3>
                    <h4 class="f-w-400">Heading 4</h4>
                    <h5 class="f-w-400">Heading 5</h5>
                    <h6 class="f-w-400">Heading 6</h6>
                  </div>
                </div>
              </div>
</template>