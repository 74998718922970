<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Link color</h4><span>Use the <code>.alert-link</code> utility class to quickly provide matching colored links within any alert</span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary" role="alert">
                      <p>This is a primary alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-secondary" role="alert">
                      <p>This is a secondary alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-success" role="alert">
                      <p>This is a success alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-info" role="alert">
                      <p>This is a info alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-warning" role="alert">
                      <p>This is a warning alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-danger" role="alert">
                      <p>This is a danger alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-light" role="alert">
                      <p>This is a light alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                    <div class="alert alert-dark" role="alert">
                      <p>This is a light alert with <a class="alert-link" href="javascript:void(0)">an example link</a>.Click It</p>
                    </div>
                  </div>
                </div>
              </div>
</template>