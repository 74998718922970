<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Contextual classes</h4>
                  </div>
                  <div class="card-body">
                    <ul class="list-group">
                      <li class="list-group-item">Dapibus ac facilisis in</li>
                      <li class="list-group-item list-group-item-primary">This is a primary list group item</li>
                      <li class="list-group-item list-group-item-secondary">This is a secondary list group item</li>
                      <li class="list-group-item list-group-item-success">This is a success list group item</li>
                      <li class="list-group-item list-group-item-danger">This is a danger list group item</li>
                      <li class="list-group-item list-group-item-warning">This is a warning list group item</li>
                      <li class="list-group-item list-group-item-info">This is a info list group item</li>
                      <li class="list-group-item list-group-item-light">This is a light list group item</li>
                      <li class="list-group-item list-group-item-dark">This is a dark list group item</li>
                    </ul>
                  </div>
                </div>
              </div>
</template>