<template>
      <div class="col-lg-4 col-md-6 box-col-4">
                    <div class="card profit-card">
                      <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                          <div class="flex-grow-1">
                            <p class="square-after f-w-600 header-text-primary">Our Total Profit<i class="fa fa-circle"> </i></p>
                            <h4>$8,55,462</h4>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <div class="profit-wrapper header-text-primary icon-bg-primary"><i class="fa fa-arrow-up"></i></div>
                            <h6 class="header-text-primary">79.21%</h6>
                            <p class="mb-0">More Than last month</p>
                          </div>
                        </div>
                        <div class="right-side icon-right-primary"><i class="fa fa-usd"></i>
                          <div class="shap-block">
                            <div class="rounded-shap animate-bg-primary"><i></i><i></i></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
</template>