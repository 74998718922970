<template>
    <div class="col-xl-4 col-md-6 box-col-33">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div class="d-flex faq-widgets">
                      <div class="flex-grow-1">
                        <h4>Articles</h4>
                        <p class="mb-0">How little experience or technical knowledge you currently have. The web is a very big place, and if you are the typical internet user, you probably visit several websites every day.</p>
                      </div><vue-feather type="book-open"></vue-feather>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-6 box-col-33">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div class="d-flex faq-widgets">
                      <div class="flex-grow-1">
                        <h4>Knowledgebase</h4>
                        <p class="mb-0">A Website Designing course enables learners to use essential designing and programming tools required to do the job. The curriculum is a blend of various themes.</p>
                      </div><vue-feather type="aperture"></vue-feather>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-12 box-col-33">
                <div class="card bg-primary">
                  <div class="card-body">
                    <div class="d-flex faq-widgets">
                      <div class="flex-grow-1">
                        <h4>Support</h4>
                        <p class="mb-0">The customer support industry is renaissance. Customer support as a specialty is coming into its own, offering companies a competitive advantage that’s difficult to copy.</p>
                      </div><vue-feather type="file-text"></vue-feather>
                    </div>
                  </div>
                </div>
              </div>
</template>