<template>
     <div class="col-lg-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Padding</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes theme-scrollbar">.p-0 {
padding: 0px;
}
.p-5 {
padding: 5px;
}
.p-10 {
padding: 10px;
}
.p-15 {
padding: 15px;
}
.p-20 {
padding: 20px;
}
.p-25 {
padding: 25px;
}
.p-30 {
padding: 30px;
}
.p-35 {
padding: 35px;
}
.p-40 {
padding: 40px;
}
.p-45 {
padding: 45px;
}
.p-50 {
padding: 50px;
}</pre>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Margins</h4>
                  </div>
                  <div class="card-body">
                    <pre class="helper-classes theme-scrollbar">.m-0 {
margin: 0px !important;
}
.m-5 {
margin: 5px !important;
}
.m-10 {
margin: 10px !important;
}
.m-15 {
margin: 15px !important;
}
.m-20 {
margin: 20px !important;
}
.m-25 {
margin: 25px !important;
}
.m-30 {
margin: 30px !important;
}
.m-35 {
margin: 35px !important;
}
.m-40 {
margin: 40px !important;
}
.m-45 {
margin: 45px !important;
}
.m-50 {
margin: 50px !important;
}</pre>
                  </div>
                </div>
              </div>
</template>