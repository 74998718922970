<template>
  <div
    class="col-md-6 col-lg-6 col-xl-4 box-col-4"
    v-for="item in psychologists"
    :key="item"
  >
    <div class="card custom-card">
      <div class="card-header">
        <img class="img-fluid" src="@/assets/images/user-card/1.jpg" alt="" />
      </div>
      <div class="card-profile">
        <img class="rounded-circle" :src="item.profile_photo" alt="" />
      </div>
      <div class="text-center profile-details">
        <router-link to="/users/profile">
          <h4>{{ item.first_name }} {{ item.last_name }}</h4></router-link
        >
        <h6>Psicologo</h6>
      </div>
      <div class="card-footer row">
        <div class="col-4 col-sm-4">
          <h6>Fecha de Inicio</h6>
          <span>{{ formattedDate(item.createdAt) }}</span>
        </div>
        <div class="col-4 col-sm-4">
          <h6>Colegia- tura</h6>
          <span>{{ item.collegiate }}</span>
        </div>
        <div class="col-4 col-sm-4">
          <h6>Modalidad de servicio</h6>
          <span>{{ item.onsite ? "Presencial" : "Remoto" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["psychologists"],
  computed: {
    ...mapState({
      friendItem: (state) => state.users.data,
    }),
  },
  methods: {
    formattedDate(date) {
      const timestamp = date;
      const fecha = new Date(timestamp * 1000);

      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const año = fecha.getFullYear();

      return `${dia}/${mes}/${año}`;
    },
  },
};
</script>
