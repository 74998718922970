import axios from "axios";

const HOST = process.env.VUE_APP_API_DIAGNOSTIC;
const HOST_USER = process.env.VUE_APP_API_USERS;

export default {
  async getDiags(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/list`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getDiagsPsico() {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST_USER}/user/psychologist/cloud/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getDiagsDirector() {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST_USER}/user/institution/cloud/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
