<template>
     <div class="card" id="default">
                  <div class="card-header pb-0">
                    <h4>Default buttons</h4><span>Bootstrap state buttons</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-primary" type="button">Primary Button</button>
                      <button class="btn btn-secondary" type="button">Secondary Button</button>
                      <button class="btn btn-success" type="button">Success Button</button>
                      <button class="btn btn-info" type="button">Info Button</button>
                      <button class="btn btn-warning" type="button">Warning Button</button>
                      <button class="btn btn-danger" type="button">Danger Button</button>
                      <button class="btn btn-light" type="button">Light Button</button>
                     
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Flat button</h4><span>Add <code>.btn-square</code> class for flat button</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-square btn-primary" type="button">Primary Button</button>
                      <button class="btn btn-square btn-secondary" type="button">Secondary Button</button>
                      <button class="btn btn-square btn-success" type="button">Success Button</button>
                      <button class="btn btn-square btn-info" type="button">Info Button</button>
                      <button class="btn btn-square btn-warning" type="button">Warning Button</button>
                      <button class="btn btn-square btn-danger" type="button">Danger Button</button>
                      <button class="btn btn-square btn-light" type="button">Light Button</button>
                     
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Raised with Edge buttons</h4><span>Add <code>.btn-pill</code> and <code>.btn-air-*</code> class button</span>
                  </div>
                  <div class="card-body"> 
                    <div class="btn-showcase">
                      <button class="btn btn-pill btn-primary btn-air-primary" type="button">Primary Button</button>
                      <button class="btn btn-pill btn-secondary btn-air-secondary btn-air-secondary" type="button">Secondary Button</button>
                      <button class="btn btn-pill btn-success btn-air-success btn-air-success" type="button">Success Button</button>
                      <button class="btn btn-pill btn-info btn-air-info btn-air-info" type="button">Info Button</button>
                      <button class="btn btn-pill btn-warning btn-air-warning btn-air-warning" type="button">Warning Button</button>
                      <button class="btn btn-pill btn-danger btn-air-danger" type="button">Danger Button</button>
                      <button class="btn btn-pill btn-light btn-air-light" type="button">Light Button</button>
                     
                    </div>
                  </div>
                </div>
               
</template>