<template>
  <Breadcrumbs main="Home" title="Mi Institución" />

  <div class="container-fluid dashboard-2">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <institutionCard :userInfo="userInfo" />
      </div>
      <div class="col-lg-12 col-md-12 col-12">
        <today :directorSesions="directorSesions" />
      </div>
    </div>
    <div class="row">
      <h3 class="mt-5 mb-5 h3_web">BANDEJA DE MENSAJE</h3>
      <div class="container-fluid">
        <div class="row">
          <searchChat />
          <div class="col call-chat-body">
            <div class="card">
              <div class="card-body p-0">
                <div class="row chat-box">
                  <chatRight />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <h3 class="mt-5 mb-5 h3_web">
        TU PERSONAL
        <button
          class="btn btn-type-list"
          type="button"
          v-on:click="listTab = false"
        >
          <i class="fa fa-list"></i>
        </button>
        <button
          class="btn btn-type-list"
          type="button"
          v-on:click="listTab = true"
        >
          <i class="fa fa-th"></i>
        </button>
      </h3>

      <div class="my-personal-card" v-if="listTab">
        <div class="row">
          <userCard :psychologists="psychologists" />
        </div>
      </div>
      <div v-else class="card p-4">
        <div
          class="my-personal-list table-responsive theme-scrollbar data-table"
        >
          <div class="mb-3 row justify-content-end">
            <label
              for="table-complete-search"
              class="col-xs-3 col-sm-auto col-form-label"
              >Buscar:</label
            >
            <div class="col-xs-3 col-sm-auto">
              <input
                id="table-complete-search"
                type="text"
                class="form-control"
                v-model="filterQuery"
              />
            </div>
          </div>
          <table class="table table-hover mt-4">
            <thead>
              <tr>
                <th scope="col">Profesional</th>
                <th scope="col">Colegiatura</th>
                <th scope="col">Fecha de Inicio</th>
                <th scope="col">Categoria</th>
                <th scope="col">Perfil</th>
                <th scope="col">Modalidad de servicio</th>
                <th scope="col">Usuarios asignados</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ps in psychologists" :key="ps.id">
                <th>{{ ps.first_name }} {{ ps.last_name }}</th>
                <th>{{ ps.collegiate }}</th>
                <th>{{ formattedDate(ps.createdAt) }}</th>
                <th>{{ ps.person_type }}</th>
                <th>{{ ps.mode }}</th>
                <th>{{ ps.onsite ? "Presencial" : "Remoto" }}</th>
                <th>1</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="card best-seller">
        <div class="card-header pb-0">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <p class="square-after f-w-600">
                Todos tus pacientes ACTIVOS ({{ directorPatients.length }})
              </p>
            </div>
          </div>
        </div>
        <div class="card-body p-4">
          <div class="theme-scrollbar data-table tabla_all_data">
            <table
              class="table table-striped table-bordered table_all"
              id="activess_patients"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th scope="col">Fecha Inicio</th>
                  <th scope="col">Hist. Clin. (DNI)</th>
                  <th>Nombres y Apellidos</th>
                  <th scope="col">Edad</th>
                  <th scope="col">Genero</th>
                  <th scope="col">PreDX</th>
                  <th scope="col">Conteo sesiones</th>
                  <th scope="col">Ultima cita</th>
                  <th scope="col">ESTADO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ap, idx) in directorPatients" :key="ap.id">
                  <th>{{ idx + 1 }}</th>
                  <th>{{ formattedDate(ap.createdAt) }}</th>
                  <th>{{ ap.DNI }}</th>
                  <th>{{ ap.first_name }} {{ ap.last_name }}</th>
                  <th>{{ ap.age }}</th>
                  <th>{{ isGender(ap.gender) }}</th>
                  <th>{{ ap.treatment.pre_diag }}</th>
                  <th>{{ ap.treatment.number_sessions }}</th>
                  <th></th>
                  <th><span class="badge badge-light-success">Activo</span></th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <h3 class="mt-5 mb-5 h3_web">Calendario Semanal/ manual de sesiones</h3>
    <div class="row mt-5">
      <div class="col-lg-6">
        <div class="container-fluid general-widget">
          <div class="col-xl-12 col-md-12">
            <div>
              <div class="card activity-widget">
                <div class="card-header pb-0">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h4>Productividad Mensual</h4>
                    </div>
                  </div>
                </div>
                <div class="card-body p-4 list-activity">
                  <div id="basic-bar">
                    <apexchart
                      type="bar"
                      height="350"
                      ref="chart"
                      :options="chartOptions"
                      :series="series"
                    >
                    </apexchart>
                  </div>
                  <div class="activity-media d-none">
                    <div
                      class="d-flex"
                      v-for="item in monthlyDirector"
                      :key="item.id"
                    >
                      <img
                        height="60"
                        width="60"
                        class="activity-img"
                        src="https://dev.sigma.net.pe/img/man.76101d57.png"
                      />
                      <div class="flex-grow-1">
                        <h6>{{ item.title }}</h6>
                        <span
                          ><i class="me-2 align-middle" data-feather="clock"></i
                          ><span class="align-middle"
                            >{{ item.date }} | {{ item.start_hour }}</span
                          ></span
                        >
                        <p class="ml-2">Remoto</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex">
              <div class="flex-grow-1">
                <h4>Agenda Mensual</h4>
              </div>
            </div>
          </div>
          <calendarView :type="'director'" />
        </div>
      </div>
    </div>
    <tagCloud :diags="diagsDirector" />
    <archived :directorPatients="directorPatients" />

    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="card activity-widget">
        <div class="">
          <div class="card-header pb-0">
            <div class="d-flex">
              <div class="flex-grow-1">
                <h4>Casos Asignados</h4>
              </div>
            </div>
          </div>
          <div
            class="card-body pt-0 mt-4 theme-scrollbar data-table tabla_all_data"
          >
            <table
              class="table table-striped table-bordered table_all mt-4 mb-4"
              id="table_casos_asignados"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">STAFF PROFESIONAL</th>
                  <th scope="col">ENE</th>
                  <th scope="col">FEB</th>
                  <th scope="col">MAR</th>
                  <th scope="col">ABR</th>
                  <th scope="col">MAY</th>
                  <th scope="col">JUN</th>
                  <th scope="col">JUL</th>
                  <th scope="col">AGO</th>
                  <th scope="col">SET</th>
                  <th scope="col">OCT</th>
                  <th scope="col">NOV</th>
                  <th scope="col">DIC</th>
                  <th scope="col">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in statiticsAsigned" :key="idx">
                  <th>{{ idx + 1 }}</th>
                  <th>{{ item.name }}</th>
                  <th>{{ countByMothAssign(1, item) }}</th>
                  <th>{{ countByMothAssign(2, item) }}</th>
                  <th>{{ countByMothAssign(3, item) }}</th>
                  <th>{{ countByMothAssign(4, item) }}</th>
                  <th>{{ countByMothAssign(5, item) }}</th>
                  <th>{{ countByMothAssign(6, item) }}</th>
                  <th>{{ countByMothAssign(7, item) }}</th>
                  <th>{{ countByMothAssign(8, item) }}</th>
                  <th>{{ countByMothAssign(9, item) }}</th>
                  <th>{{ countByMothAssign(10, item) }}</th>
                  <th>{{ countByMothAssign(11, item) }}</th>
                  <th>{{ countByMothAssign(12, item) }}</th>
                  <th>
                    {{ getTotalAsigned(item) }}
                  </th>
                </tr>
              </tbody>
            </table>
            <!-- <apexchart
              height="350"
              type="line"
              :options="chartOptions"
              :series="series"
            ></apexchart> -->
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="card">
        <div class="">
          <div class="card-header pb-0">
            <div class="d-flex">
              <div class="flex-grow-1">
                <h4>total Archivados</h4>
              </div>
            </div>
          </div>
          <div
            class="card-body pt-0 theme-scrollbar data-table tabla_all_data mt-4"
          >
            <table
              class="table table-striped table-bordered table_all mt-4"
              id="total_archivados"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">STAFF PROFESIONAL</th>
                  <th scope="col">ENE</th>
                  <th scope="col">FEB</th>
                  <th scope="col">MAR</th>
                  <th scope="col">ABR</th>
                  <th scope="col">MAY</th>
                  <th scope="col">JUN</th>
                  <th scope="col">JUL</th>
                  <th scope="col">AGO</th>
                  <th scope="col">SET</th>
                  <th scope="col">OCT</th>
                  <th scope="col">NOV</th>
                  <th scope="col">DIC</th>
                  <th scope="col">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in statiticsArchived" :key="idx">
                  <th>{{ idx + 1 }}</th>
                  <th>{{ item.name }}</th>
                  <th>{{ countByMoth(1, item) }}</th>
                  <th>{{ countByMoth(2, item) }}</th>
                  <th>{{ countByMoth(3, item) }}</th>
                  <th>{{ countByMoth(4, item) }}</th>
                  <th>{{ countByMoth(5, item) }}</th>
                  <th>{{ countByMoth(6, item) }}</th>
                  <th>{{ countByMoth(7, item) }}</th>
                  <th>{{ countByMoth(8, item) }}</th>
                  <th>{{ countByMoth(9, item) }}</th>
                  <th>{{ countByMoth(10, item) }}</th>
                  <th>{{ countByMoth(11, item) }}</th>
                  <th>{{ countByMoth(12, item) }}</th>
                  <th>
                    {{ getTotalArchived(item) }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="card">
        <div class="">
          <div class="card-header pb-0">
            <div class="d-flex">
              <div class="flex-grow-1">
                <h4>Total de servicios</h4>
              </div>
            </div>
          </div>
          <div class="card-body pt-0 theme-scrollbar data-table tabla_all_data">
            <table class="table table-striped table-bordered table_all" id="">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">SERVICIOS</th>
                  <th scope="col">ENE</th>
                  <th scope="col">FEB</th>
                  <th scope="col">MAR</th>
                  <th scope="col">ABR</th>
                  <th scope="col">MAY</th>
                  <th scope="col">JUN</th>
                  <th scope="col">JUL</th>
                  <th scope="col">AGO</th>
                  <th scope="col">SET</th>
                  <th scope="col">OCT</th>
                  <th scope="col">NOV</th>
                  <th scope="col">DIC</th>
                  <th scope="col">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1</th>
                  <th>CASOS NUEVOS</th>
                  <th v-for="(item, idx) in asignedTotal" :key="idx + 1">
                    {{ item }}
                  </th>
                  <th>
                    {{ asigned }}
                  </th>
                </tr>
                <tr>
                  <th>2</th>
                  <th>TOTAL DE SESIONES</th>
                  <th v-for="(item, idx) in servicesTotal" :key="idx + 1">
                    {{ item }}
                  </th>
                  <th>
                    {{ services }}
                  </th>
                </tr>
                <tr>
                  <th>3</th>
                  <th>ARCHIVADOS</th>
                  <th v-for="(item, idx) in archivedTotal" :key="idx + 1">
                    {{ item }}
                  </th>
                  <th>
                    {{ archived }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="modalPatients"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="personal-prof">
            <img src="https://dev.sigma.net.pe/img/man.76101d57.png" />
            <p class="name">Franco Escobar Torres</p>
            <p class="name">25 años</p>
            <p>Masculino</p>
            <p>Fecha de Inicio : 15-02-2023</p>
            <p>Hist. Clinica: 52658874</p>
            <p>Pre DX: 152</p>
            <p>Conteo: 52 Sesiones</p>
            <p>Ultima cita: 15-03-2023</p>
            <p>Prox. Cita: 25-05-2023</p>
            <p>ACTIVO</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn"
            data-bs-dismiss="modal"
            style="color: #fff"
          >
            Cerrar ventana
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.h3_web {
  font-size: 20px;
  color: #2b2b2b;
  margin: 0 0 10px 0;
}
.my-institution {
  .list-appointments {
    background-color: #d2d2d2;
    padding: 1.5rem;
    border-radius: 20px;

    li {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .my-personal-card {
    background-color: #f2f2f2;
    padding: 2rem;

    .personal-prof {
      text-align: center;

      .name {
        margin-top: 1rem;
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 0px;
      }

      .colegiatura {
        color: #0070c0;
        font-weight: bold;
        font-size: 1.1rem;
      }

      .point-personal {
        font-size: 1rem;
        margin-bottom: 0.4rem;
      }

      .number-user {
        color: #fe0606;
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }
}

.modal-content {
  background-color: white !important;
  border: none !important;

  .personal-prof {
    text-align: center;

    img {
      margin-top: 1rem;
      border-radius: 50%;
      height: 250px;
      width: 250px;
      margin-bottom: 2rem;
    }

    p {
      color: #fff;
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 5px;
    }
  }
}

.list-patient-cal {
  .img-patient {
    width: 60px;
  }

  span {
    background-color: #022b95;
    color: #fff;
    font-weight: bold;
    padding: 0.5rem;
    margin: 10px 5px;
    border-radius: 10px;
  }
}

.d-frec {
  p {
    font-weight: bold;

    span {
      padding: 0rem 0.5rem;
    }
  }
}
</style>

<script>
import institutionCard from "@/components/institution/institutionCard.vue";
import basicChart from "@/components/chart/apexchart/basicChart.vue";
import searchChat from "@/components/common/searchChat.vue";
import chatRight from "@/components/chat/chatapp/chatRight.vue";
import calendarView from "@/components/calendar/calendarView.vue";
import userCard from "@/components/users/cards/userCard.vue";
import archived from "@/components/institution/archived.vue";
import tagCloud from "@/components/institution/tagCloud.vue";
import today from "@/components/institution/today.vue";
import { mapState, mapActions } from "vuex";

var primary = localStorage.getItem("primary_color") || "#5c61f2";
var secondary = localStorage.getItem("secondary_color") || "#eeb82f";
var success = localStorage.getItem("success_color") || "#61ae41";

export default {
  components: {
    institutionCard,
    today,
    basicChart,
    searchChat,
    chatRight,
    calendarView,
    userCard,
    archived,
    tagCloud,
  },
  data() {
    return {
      showModal: false,
      archivedTotal: [],
      asignedTotal: [],
      servicesTotal: [],
      archived: 0,
      services: 0,
      asigned: 0,
      listTab: true,
      chartOptions: {
        colors: ["#008FFB", "#00E396", "#FEB019"],
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Total de Usuarios",
          data: [],
        },
        {
          name: "Total de Sesiones",
          data: [],
        },
        {
          name: "Total de Archivados",
          data: [],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      archiveCases: [
        {
          name: "Ps. Luis Ortega",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        {
          name: "Int. Karen Saenz",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        {
          name: "Ps. Luisa Rodriguez",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
      ],
      sesionTotal: [
        {
          name: "Ps. Luis Ortega",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        {
          name: "Int. Karen Saenz",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        {
          name: "Ps. Luisa Rodriguez",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
      ],
      totalArchive: [
        {
          name: "Ps. Luis Ortega",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        {
          name: "Int. Karen Saenz",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        {
          name: "Ps. Luisa Rodriguez",
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
      ],
      totalServices: {
        newCases: {
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        totalSesions: {
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
        archives: {
          ene: "2",
          feb: "3",
          mar: "3",
          abr: "4",
          may: "5",
          jun: "6",
          jul: "2",
          ago: "0",
          set: "0",
          oct: "0",
          nov: "0",
          dic: "0",
        },
      },
    };
  },
  computed: {
    ...mapState({
      psychologists: (state) => state.psyco.psychologists,
      dashbord: (state) => state.widget.activity,
      userInfo: (state) => state.user.user,
      directorPatients: (state) => state.director.directorPatients,
      statiticsArchived: (state) => state.statitics.statiticsArchived,
      statiticsAsigned: (state) => state.statitics.statiticsAsigned,
      statiticsArchivedTotal: (state) => state.statitics.statiticsArchivedTotal,
      statiticsAsignedTotal: (state) => state.statitics.statiticsAsignedTotal,
      statiticsServicesTotal: (state) => state.statitics.statiticsServicesTotal,
      statiticsServices: (state) => state.statitics.statiticsServices,
      dashbord: (state) => state.widget.activity,
      diagsDirector: (state) => state.diags.diagsDirectors,
      patientsArchived: (state) => state.patients.patientsArchived,
      monthlyDirector: (state) => state.calendar.monthlyDirector,
      initialEventsDirector: (state) => state.calendar.initialEventsDirector,
      directorSesions: (state) => state.director.directorSesions,
      sendMsg: (state) => state.chats.sendMsg,
      recivedMsg: (state) => state.chats.recivedMsg,
    }),
  },
  async mounted() {
    const data = {
      id: localStorage.getItem("userId"),
    };
    await this.getUser({ payload: data });
    await this.loadDataForChartIns();
    const requests = [
      this.getPatients({ payload: this.userInfo }),
      this.getPatientsArchived(),
      this.getPsycos(),
      this.getSesionsByDirector({ payload: this.userInfo }),
      this.getStatiticsArchived({ payload: this.userInfo }),
      this.getStatiticsAsigned({ payload: this.userInfo }),
      this.getStatiticsArchivedTotal({ payload: this.userInfo }),
      this.getStatiticsAsignedTotal({ payload: this.userInfo }),
      this.getStatiticsServices({ payload: this.userInfo }),
      this.getStatiticsServicesTotal({ payload: this.userInfo }),
      this.getSendMsg({ payload: this.userInfo }),
      this.getRecivedMsg({ payload: this.userInfo }),
    ];

    const results = await Promise.all(requests);
    this.getTotalArchivedByMonth();
    this.getTotalAsignedByMonth();
    this.getServicesByMonth();
    this.getDiagsDirector();
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      getPsycos: "psyco/getPsyco",
      getPatients: "director/getPatientsById",
      getPatientsArchived: "patients/getPatientsArchived",
      createPatient: "patients/createPatient",
      getMonthlyDirector: "calendar/getMonthlyDirector",
      getDiagsDirector: "diags/getDiagsDirector",
      getSesionsByDirector: "director/getSesionsById",
      getStatiticsArchived: "statitics/getStatiticsArchived",
      getStatiticsAsigned: "statitics/getStatiticsAsigned",
      getStatiticsServices: "statitics/getStatiticsServices",
      getStatiticsArchivedTotal: "statitics/getStatiticsArchivedTotal",
      getStatiticsAsignedTotal: "statitics/getStatiticsAsignedTotal",
      getStatiticsServicesTotal: "statitics/getStatiticsServicesTotal",
      createMsg: "chats/createMsg",
      getSendMsg: "chats/getSendMsg",
      getRecivedMsg: "chats/getRecivedMsg",
      updateUser: "user/updateUser",
      getChartsInstitutions: "psyco/getChartsInstitution",
    }),
    async loadDataForChartIns() {
      try {
        const chartsData = await this.getChartsInstitutions();

        if (chartsData.data.length === 0) {
          console.error("No hay datos en chartsData.data.");
          return;
        }

        const monthNames = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        let monthLabels = [];
        let totalUsersData = [];
        let totalSessionsData = [];
        let totalArchivedData = [];

        for (const data of chartsData.data) {
          const monthNumber = parseInt(data.month.split("-")[1], 10);
          const monthLabel = monthNames[monthNumber - 1];
          monthLabels.unshift(monthLabel);

          // Asumiendo que los datos necesarios están en las propiedades correctas.
          totalUsersData.unshift(data.total_users);
          totalSessionsData.unshift(data.total_sessions);
          totalArchivedData.unshift(data.total_archives);
        }

        console.log("Month User:", totalUsersData);
        console.log("Month Sessions:", totalSessionsData);
        console.log("Month Archived:", totalArchivedData);
        // Actualiza las categorías del eje x y las series
        this.chartOptions.xaxis.categories = monthLabels;
        this.series[0].data = totalUsersData;
        this.series[1].data = totalSessionsData;
        this.series[2].data = totalArchivedData;

        // Actualiza ApexCharts
        this.$refs.chart.updateOptions(this.chartOptions);
        this.$refs.chart.updateSeries(this.series);

        console.log("chartOptions:", this.chartOptions);
        console.log("Existing categories:", this.chartOptions.xaxis.categories);
      } catch (error) {
        console.error("Error al cargar datos para el gráfico", error);
      }
    },
    isGender(gender) {
      return gender === "female" ? "Femenino" : "Masculino";
    },
    formattedDate(date) {
      const timestamp = date;
      const fecha = new Date(timestamp * 1000);

      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const año = fecha.getFullYear();

      return `${dia}/${mes}/${año}`;
    },
    countByMoth(month_id, item) {
      for (const month of item.months) {
        if (month.month_id === month_id) {
          return month.count_arch;
        }
      }
      return 0;
    },
    countByMothAssign(month_id, item) {
      for (const month of item.months) {
        if (month.month_id === month_id) {
          return month.count_assign;
        }
      }
      return 0;
    },
    getTotalArchived(item) {
      const total = item.months.reduce(
        (acumulador, mes) => acumulador + mes.count_arch,
        0
      );
      return total;
    },
    getTotalAsigned(item) {
      const total = item.months.reduce(
        (acumulador, mes) => acumulador + mes.count_assign,
        0
      );
      return total;
    },
    getTotalArchivedByMonth() {
      let data = [];
      for (let i = 0; i < 12; i++) {
        const item = this.statiticsArchivedTotal.filter(
          (arch) => arch.month_id === i + 1
        );
        if (item.length > 0) {
          data.push(item[0].total_count_arch);
        } else {
          data.push(0);
        }
      }
      const sum = data.reduce((accumulator, value) => accumulator + value, 0);
      this.archived = sum;
      this.archivedTotal = data;
      this.series[1].data = data;
    },
    getTotalAsignedByMonth() {
      let data = [];
      for (let i = 0; i < 12; i++) {
        const item = this.statiticsAsignedTotal.filter(
          (arch) => arch.month_id === i + 1
        );
        if (item.length > 0) {
          data.push(item[0].total_count_assigns);
        } else {
          data.push(0);
        }
      }
      const sum = data.reduce((accumulator, value) => accumulator + value, 0);
      this.asigned = sum;
      this.asignedTotal = data;
      this.series[0].data = data;
    },
    getServicesByMonth() {
      let data = [];
      for (let i = 0; i < 12; i++) {
        console.log("ssasas", this.statiticsServicesTotal);
        const item = this.statiticsServicesTotal.filter(
          (arch) => arch.month_id === i + 1
        );
        if (item.length > 0) {
          data.push(item[0].total_count_sessions);
        } else {
          data.push(0);
        }
      }
      const sum = data.reduce((accumulator, value) => accumulator + value, 0);
      this.services = sum;
      this.servicesTotal = data;
    },
  },
};
</script>
