import * as types from "../mutation-types";
import patientAPI from "@/api/patient";

const state = {
  patients: [],
  step: "generalInfo",
  patientsArchived: [],
};

const getters = {
  patients: (state) => state.patients,
};

const actions = {
  async getPatients({ commit }, payload) {
    try {
      const response = await patientAPI.getPatients(payload);
      const patients = response.data;
      commit(types.SET_PATIENTS, { patients });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getPatientsArchived({ commit }, payload) {
    try {
      const response = await patientAPI.getPatientsArchived(payload);
      const patients = response.data;
      commit(types.SET_PATIENTS_ARCHIVED, { patients });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createPatient({ commit }, payload) {
    try {
      const response = await patientAPI.createPatient(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  setStep({ commit }, payload) {
    const step = payload.payload;
    commit(types.SET_STEP, { step });
  },
};

const mutations = {
  [types.SET_PATIENTS](state, { patients }) {
    state.patients = patients;
  },
  [types.SET_PATIENTS_ARCHIVED](state, { patients }) {
    state.patientsArchived = patients;
  },
  [types.SET_STEP](state, { step }) {
    state.step = step;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
