<template>
     <div class="col-sm-12 col-xl-6">
                <div class="card height-equal">
                  <div class="card-header pb-0">
                    <h4>Alert With Icon outline</h4><span>Just add Any icon before text</span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary outline alert-dismissible fade show" role="alert"><vue-feather type="clock"></vue-feather>
                      <p>Your time Over after <b>5</b> miniute</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-secondary outline alert-dismissible fade show" role="alert"><vue-feather type="heart"></vue-feather>
                      <p> Oh snap! Change a few things up and submit again.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-success outline alert-dismissible fade show" role="alert"><vue-feather type="thumbs-up"></vue-feather>
                      <p><b> Well done! </b>You successfully read this important alert message.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-info outline alert-dismissible fade show" role="alert"><vue-feather type="help-circle"></vue-feather>
                      <p><b>welcome!</b> Start your day with some alerts.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-warning outline alert-dismissible fade show" role="alert"><vue-feather type="bell"></vue-feather>
                      <p><b> Congratulation! </b>Your Product Added in Like List</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-danger outline alert-dismissible fade show" role="alert"><vue-feather type="thumbs-down"></vue-feather>
                      <p>Your  <b> Perfomance </b> is down on this week</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-light outline alert-dismissible fade show" role="alert"><vue-feather type="alert-triangle"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-dark outline alert-dismissible fade show" role="alert"><vue-feather type="alert-circle"></vue-feather>
                      <p> You can check in on some of those fields below.</p>
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  </div>
                </div>
              </div>
</template>