import axios from "axios";

const HOST = process.env.VUE_APP_API_AUTH;
const HOSTS = process.env.VUE_APP_API_CDR;

export default {
  async login(payload = {}) {
    try {
      const data = payload.payload || {};
      const response = await axios({
        url: `${HOST}/login`,
        method: "post",
        data,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async recovery(payload = {}) {
    try {
      const data = payload.payload || {};
      const response = await axios({
        url: `${HOST}/password_recovery`,
        method: "post",
        data,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async reset(payload = {}) {
    try {
      const data = payload.payload || {};
      const response = await axios({
        url: `${HOST}/password_reset/${data.token}`,
        method: "post",
        data,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async logout(payload = {}) {
    try {
      const data = {
        token: localStorage.getItem("token"),
      };
      const response = await axios({
        url: `${HOST}/logout`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async getCdrs(payload = {}) {
    try {
      const response = await axios({
        url: `${HOSTS}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
