<template>
     <div class="card-header pb-0">
                    <form class="search-form">
                      <div class="form-group m-0">
                        <label class="sr-only">Email</label>
                      </div>
                      <div class="form-group mb-0">
                        <div class="input-group"><span class="input-group-text"><i class="icon-search"></i></span>
                          <input class="form-control-plaintext" type="search" placeholder="Search..">
                        </div>
                      </div>
                    </form>
                  </div>
</template>