import * as types from "../mutation-types";
import psycoAPI from "@/api/psyco";

const state = {
  psychologists: [],
  psycology:null,
};

const getters = {
  psychologists: (state) => state.psychologists,
};

const actions = {
  async getPsyco({ commit }, payload) {
    try {
      const response = await psycoAPI.getPsycos(payload);
      const psychologists = response.data;
      commit(types.SET_PSYCHOLOGISTS, { psychologists });
      return response;
    } catch (error) {
      throw error;
    }
  },
  setPsyco({ commit }, payload) {
    const  psycology = payload;
    commit(types.SET_PSYCHOLOGY, { psycology });
  },
  
  async getChart({ commit }, payload) {
    try {
      const response = await psycoAPI.getCharts(payload);
      const psychologists = response.data;
      commit(types.SET_PSYCHOLOGISTS, { psychologists });
      return response;
    } catch (error) {
      throw error;
    }
  },
  setChart({ commit }, payload) {
    const  psycology = payload;
    commit(types.SET_PSYCHOLOGY, { psycology });
  },
  async getChartsInstitution({ commit }, payload) {
    try {
      const response = await psycoAPI.getChartsInstitutions(payload);
      const psychologists = response.data;
      commit(types.SET_PSYCHOLOGISTS, { psychologists });
      return response;
    } catch (error) {
      throw error;
    }
  },
  setChartInstitution({ commit }, payload) {
    const  psycology = payload;
    commit(types.SET_PSYCHOLOGY, { psycology });
  },
};

const mutations = {
  [types.SET_PSYCHOLOGISTS](state, { psychologists }) {
    state.psychologists = psychologists;
  },
  [types.SET_PSYCHOLOGY](state, { psycology }) {
    state.psycology = psycology;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
