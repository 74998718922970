<template>
  <Breadcrumbs main="Home" title="" />
  <div class="container-fluid dashboard-2">
    <div class="col-lg-12 col-md-12 box-col-12">
      <div class="card best-seller">
        <div class="card-header pb-0">
          <div class="h3_titles_p mb-4">
            <h3>Mi consultorio</h3>
            <!-- <span>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos.</span> -->
          </div>
          <div class="d-lg-flex justify-content-between align-items-center">
            <div class="flex-grow-1">
              <p class="square-after f-w-600">
                Para hoy, {{ fechaHoy }} <i class="fa fa-circle"> </i>
              </p>
            </div>
            <div class="text-right">
              <router-link to="/nuevo-paciente">
                <button
                  class="btn btn-pill btn-primary btn-air-primary"
                  type="button"
                >
                  Nuevo Paciente
                </button></router-link
              >
            </div>
          </div>
        </div>
        <div class="card-body p-lg-4 p-2">
          <div
            class="theme-scrollbar data-table tabla_all_data table-responsive"
          >
            <table
              class="table table-striped table-bordered table_all"
              id="activePatientsTable"
            >
              <thead>
                <tr>
                  <th scope="col" sortable="createdAt">Hora</th>
                  <th scope="col" sortable="DNI">DNI</th>
                  <th scope="col" sortable="first_name">Nombres y Apellidos</th>
                  <th scope="col" sortable="sesion"># Sesión</th>
                  <th scope="col" sortable="categoria">Categoria</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sesionList" :key="item">
                  <td style="cursor: pointer" @click="showModal(item)">
                    {{ obtenerHoraDesdeFecha(item.session.session_date) }}
                  </td>
                  <td style="cursor: pointer" @click="showModal(item)">
                    {{ item.DNI }}
                  </td>
                  <td style="cursor: pointer" @click="showModal(item)">
                    {{ item.first_name }} {{ item.last_name }}
                  </td>
                  <td style="cursor: pointer" @click="showModal(item)">
                    <span>1</span>
                  </td>
                  <td style="cursor: pointer" @click="showModal(item)">
                    remote
                  </td>
                  <td>
                    <button class="btn" @click="showModal(item)">
                      <i class="icon-eye text-primary"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-2">
      <div class="row">
        <div class="col-lg-12 col-md-12 box-col-12">
          <div class="card best-seller">
            <div
              class="card-header d-lg-flex justify-content-between align-items-center"
            >
              <div class="col-lg-9 col-md-9 col-12">
                <h3 class="h3_norm">
                  Tus pacientes activos
                  <button
                    class="btn btn-type-list"
                    type="button"
                    v-on:click="listTab = true"
                  >
                    <i class="fa fa-list"></i>
                  </button>
                  <button
                    class="btn btn-type-list"
                    type="button"
                    v-on:click="listTab = false"
                  >
                    <i class="fa fa-th"></i>
                  </button>
                </h3>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="text-right new_paciente_btn_pa">
                  <router-link to="/nuevo-paciente">
                    <button
                      class="btn btn-pill btn-primary btn-air-primary"
                      type="button"
                    >
                      Nuevo Paciente
                    </button></router-link
                  >
                </div>
              </div>
              <div class="d-lg-flex justify-content-between align-items-center">
                <div class="flex-grow-1">
                  <!-- <p class="square-after f-w-600 mb-lg-0 mb-3">
                    Pacientes Activos
                  </p> -->
                </div>
              </div>
            </div>
            <!--<br />-->
            <div class="card-body p-4">
              <div
                v-if="listTab"
                class="theme-scrollbar data-table tabla_all_data table-responsive"
              >
                <table
                  class="table table-striped mt-2 table-bordered table_all"
                  id="activePatients"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th scope="col">Fecha Inicio</th>
                      <th scope="col">Hist. Clin. (DNI)</th>
                      <th>Nombres y Apellidos</th>
                      <th scope="col">Edad</th>
                      <th scope="col">Genero</th>
                      <th scope="col">PreDX</th>
                      <th scope="col">Conteo sesiones</th>
                      <th scope="col">ESTADO</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ap, idx) in patients" :key="ap.id">
                      <th>{{ idx + 1 }}</th>
                      <th>{{ ap.date_hour }}</th>
                      <th>{{ ap.DNI }}</th>
                      <th>{{ ap.first_name }} {{ ap.last_name }}</th>
                      <th>{{ ap.age }}</th>
                      <th>{{ isGender(ap.gender) }}</th>
                      <th>{{ ap.treatment?.pre_diag }}</th>
                      <th>{{ ap.number_session }}</th>
                      <th>
                        <span class="badge rounded-pill badge-success"
                          >Activo</span
                        >
                      </th>
                      <th>
                        <button class="btn" @click="changeStatus(ap)">
                          <i class="icon-archive text-primary"></i>
                        </button>
                        <span class="edit">
                          <router-link
                            :to="`/nuevo-paciente/${ap.id}?step=generalInfo`"
                            ><i class="icon-pencil-alt"></i></router-link
                        ></span>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <div class="row mt-3">
                  <div
                    v-for="(ap, idx) in patients"
                    :key="idx + 1"
                    class="col-md-6 col-lg-6 col-xl-3 box-col-3"
                  >
                    <div class="card custom-card">
                      <div class="card-profile">
                        <img
                          class="rounded-circle"
                          src="https://dev.sigma.net.pe/img/man.76101d57.png"
                          alt=""
                        />
                      </div>
                      <div class="text-center profile-details">
                        <h4>{{ ap.first_name }}</h4>
                        <h6>{{ ap.DNI }}</h6>
                        <h6>Inicio: {{ ap.createdAt }}</h6>
                        <h6>{{ isGender(ap.gender) }}, {{ ap.age }} años</h6>
                        <h6>Activo</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-6 col-md-4 col-12">
        <div class="container-fluid general-widget">
          <div class="col-xl-12 col-md-12">
            <div>
              <div class="card activity-widget">
                <div class="card-header pb-0">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h4>Productividad Mensual</h4>
                    </div>
                  </div>
                </div>
                <div class="card-body p-4 list-activity">
                  <div id="basic-bar">
                    <apexchart
                      type="bar"
                      height="350"
                      ref="chart"
                      :options="chartOptions"
                      :series="series"
                    >
                    </apexchart>
                  </div>
                  <div class="activity-media d-none">
                    <div class="d-flex" v-for="item in monthly" :key="item">
                      <img
                        height="60"
                        width="60"
                        class="activity-img"
                        src="https://dev.sigma.net.pe/img/man.76101d57.png"
                      />
                      <div class="flex-grow-1">
                        <h6>{{ item.patient_name }}</h6>
                        <span
                          ><i class="me-2 align-middle" data-feather="clock"></i
                          ><span class="align-middle"
                            >{{ item.date }} | {{ item.start_hour }}</span
                          ></span
                        >
                        <p class="ml-2">Remoto</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-8 col-12">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex">
              <div class="flex-grow-1">
                <h4>Agenda Mensual</h4>
              </div>
            </div>
          </div>
          <calendarView :type="'psico'" />
        </div>
      </div>
    </div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
      <div class="col-12">
        <div
          class="card activity-widget p-4 theme-scrollbar data-table tabla_all_data table-responsive"
        >
          <h3>
            Archivado
            <span class="d-none">({{ patientsArchived.length }} )</span>
          </h3>

          <table
            class="table table-striped table-bordered table_all mt-4"
            id="tableArchived"
          >
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Historia Clinica (HC) = (DNI)</th>
                <th>Nombres y Apellidos</th>
                <th scope="col">Edad</th>
                <th scope="col">Genero</th>
                <th scope="col">Pre DX</th>
                <th scope="col">Conteo Sesiones</th>
                <th scope="col">Alta</th>
                <th scope="col">Desarchivar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ap, idx) in patientsArchived" :key="ap.id">
                <th>{{ idx + 1 }}</th>
                <th>{{ ap.DNI }}</th>
                <th>{{ ap.first_name }}</th>
                <th>{{ ap.age }}</th>
                <th>{{ isGender(ap.gender) }}</th>
                <th>{{ ap.treatment.pre_diag }}</th>
                <th>{{ ap.treatment.number_sessions }}</th>
                <th></th>
                <th>
                  <button class="btn" @click="changeStatusActive(ap)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-rotate-ccw text-primary"
                    >
                      <polyline points="1 4 1 10 7 10"></polyline>
                      <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                    </svg>
                  </button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <tag-cloud :diags="diagsPsico" />
      <div class="row mt-5 justify-content-center"></div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade modal_consulting"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="margin-top: 10px; background: white">
        <div class="modal-content">
          <div v-if="modalData" class="card custom-card">
            <div
              class="close_modal_consulting"
              style="cursor: pointer; margin: 10px"
              @click="closeModal"
            >
              X
            </div>
            <div class="card-profile">
              <img
                class="rounded-circle"
                src="https://dev.sigma.net.pe/img/man.76101d57.png"
                alt=""
              />
            </div>
            <div class="text-center profile-details mt-10 mb-3">
              <h4>{{ modalData.first_name }} {{ modalData.last_name }}</h4>
            </div>
            <div class="card-footer row mt-10">
              <div class="col-4 col-sm-4">
                <h6>Edad :</h6>
                <span>{{ modalData?.age }} años</span>
              </div>
              <div class="col-4 col-sm-4">
                <h6>Sexo :</h6>
                <span>{{
                  modalData?.gender === "male" ? "Masculino" : "Femenino"
                }}</span>
              </div>
              <div class="col-4 col-sm-4">
                <h6>Fecha de inicio :</h6>
                <span>{{ modalData.date_hour }}</span>
              </div>
              <div class="col-4 col-sm-4">
                <h6>Pre DX :</h6>

                <span
                  v-for="item in modalData.treatment.title"
                  :key="item.id"
                  style="margin-left: 3px"
                  >{{ item.title }}</span
                >
              </div>
              <div class="col-4 col-sm-4">
                <h6>Sesiones :</h6>
                <span>{{ modalData?.number_sessions }} 1 Sesion</span>
              </div>
              <div class="col-4 col-sm-4">
                <h6>Estado :</h6>
                <span>Activo</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
table tr td.dataTables_empty {
  text-align: center !important;
}
.new_paciente_btn_pa button {
  float: right !important;
}
.h3_titles_p h3 {
  font-size: 23px;
}
.h3_titles_p span {
  font-size: 13px;
}
.tabla_all_data .table_all {
  margin-top: 30px !important;
}
.tabla_all_data .table_all .dataTables_empty {
  background-color: #ffffff !important;
  --bs-table-bg-type: rgb(0 0 0 / 0%) !important;
}
.tabla_all_data .dataTables_length {
  display: inline;
}
.tabla_all_data .dataTables_filter {
  display: inline;
  float: right;
}
.tabla_all_data .dataTables_filter input {
  border: 1px solid #cecece !important;
  outline-offset: unset;
  outline-color: #f2f2f2;
}
.tabla_all_data .dataTables_length select {
  border: 1px solid #cecece !important;
  outline-offset: unset !important;
  outline-color: #f2f2f2 !important;
}
.tabla_all_data .dataTables_info {
  margin: 20px 0px;
}
.tabla_all_data .dataTables_paginate {
  border: none;
  float: right;
  margin: 15px 0px 0px;
}
.tabla_all_data .dataTables_paginate .paginate_button {
  padding: 8px 10px;
  color: #000000 !important;
}
.page-wrapper
  .page-body-wrapper
  .dashboard-2
  .tabla_all_data
  .dataTables_paginate
  .paginate_button:hover {
  border: none !important;
}
.tabla_all_data .dataTables_paginate span .paginate_button {
  margin: 0px 20px !important;
  padding: 10px 10px 10px !important;
  color: #ffffff !important;
}
.tabla_all_data .dataTables_paginate span .paginate_button:hover {
  color: #000000 !important;
}

.edit i {
  color: #61ae41;
}
.h3_norm {
  font-size: 23px;
}
.modal_consulting .card-footer div:nth-child(1),
.modal_consulting .card-footer div:nth-child(4),
.modal_consulting .card-footer div:nth-child(7) {
  border-left: 1px solid #eeeeee !important;
}
.modal_consulting {
  background-color: #000000aa;
  .modal-dialog {
    border-radius: 42px;
    .modal-content {
      border-radius: 30px;
      background-color: #4f9cd6 !important;
      .card-footer div {
        border-bottom: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        border-left: 0px;
      }
      .close_modal_consulting {
        position: absolute;
        top: 6px;
        right: 8px;
        background-color: #4f9cd6;
        padding: 7px 12px;
        border-radius: 24px;
        color: #ffffff;
        font-weight: 700;
      }
    }
  }
}

.modal_consulting .card-footer div:nth-child(1),
.modal_consulting .card-footer div:nth-child(4),
.modal_consulting .card-footer div:nth-child(7) {
  border-left: 1px solid #eeeeee !important;
}
.modal_consulting {
  background-color: #000000aa;
  .modal-dialog {
    border-radius: 42px;
    .modal-content {
      border-radius: 30px;
      background-color: #4f9cd6 !important;
      .card-footer div {
        border-bottom: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        border-left: 0px;
      }
      .close_modal_consulting {
        position: absolute;
        top: 6px;
        right: 8px;
        background-color: #4f9cd6;
        padding: 7px 12px;
        border-radius: 24px;
        color: #ffffff;
        font-weight: 700;
      }
    }
  }
}

.modal_confirm .card-footer div:nth-child(1),
.modal_confirm .card-footer div:nth-child(4),
.modal_confirm .card-footer div:nth-child(7) {
  border-left: 1px solid #eeeeee !important;
}
.modal_confirm {
  background-color: #000000aa;
  .modal-dialog {
    border-radius: 42px;
    .modal-content {
      border-radius: 30px;
      background-color: white !important;
      color: #ffffff;
      .card-footer div {
        border-bottom: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        border-left: 0px;
      }
      .close_modal_confirm {
        position: absolute;
        top: 6px;
        right: 8px;
        background-color: #4f9cd6;
        padding: 7px 12px;
        border-radius: 24px;
        color: #ffffff;
        font-weight: 700;
      }
    }
  }
}

.list-activity {
  height: 600px;
  overflow-y: auto;
}
.activity-img {
  border-radius: 50%;
}
.my-consulting {
  .img-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-left: 1.5rem;
  }
  .card {
    background: transparent;
    border-color: transparent;
    box-shadow: none;

    .card-body {
      .hello {
        font-size: 1.3rem;
        margin-bottom: 0;
      }
      .name {
        font-size: 1.7rem;
        margin-bottom: 0;
      }
      .tag {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }

  .text-right {
    text-align: right;
  }

  .btn-primary {
    font-size: 2rem;
    margin-top: 3rem;
  }

  .btn-type-list {
    cursor: pointer;
  }

  .custom-card {
    background: #fff;

    .card-profile {
      img {
        height: 170px;
      }
    }

    .profile-details {
      h6 {
        color: black;
      }
    }
  }

  .list-patient-cal {
    .img-patient {
      width: 60px;
    }
    span {
      background-color: #022b95;
      color: #fff;
      font-weight: bold;
      padding: 0.5rem;
      margin: 10px 5px;
      border-radius: 10px;
    }
  }

  .d-frec {
    p {
      font-weight: bold;

      span {
        padding: 0rem 0.5rem;
      }
    }
  }
}
</style>

<script>
import calendarView from "@/components/calendar/calendarView.vue";
import basicChart from "@/components/chart/apexchart/basicChart.vue";
import bestSeller from "@/components/dashboards/e-commerce/bestSeller.vue";

import tagCloud from "./tagCloud.vue";
import { mapState, mapActions } from "vuex";
var primary = localStorage.getItem("primary_color") || "#5c61f2";
var secondary = localStorage.getItem("secondary_color") || "#eeb82f";
var success = localStorage.getItem("success_color") || "#61ae41";
export default {
  components: {
    calendarView,
    basicChart,
    bestSeller,
    tagCloud,
  },
  data() {
    return {
      user: {
        photo: "https://dev.sigma.net.pe/img/man.76101d57.png",
        name: "Rolando Escobar",
        nickname: "leonardoescobar",
      },
      modalShow: true,
      modalData: "",
      form: {
        first_name: "laura",
        last_name: "vanesa",
        gender: "female",
        email: "laura1@gmail.com",
        user_type: "patient",
        password: "password123",
        person_type: "natural",
        DNI: "12345678",
        date_of_birth: "1990-01-01",
        age: 33,
        civil_state: "Single",
        education: "Bachelor's Degree",
        nationality: "American",
        address: "123 Main St",
        state: "California",
        province: "Los Angeles",
        district: "Downtown",
        phone: "+1 234 567 8900",
      },
      datePatients: "martes, 06 de junio",
      patientsList: [],
      activePatients: [],
      archivePatients: [],
      listTab: true,
      chartOptions: {
        colors: ["#008FFB", "#00E396", "#FEB019"],
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Total de Usuarios",
          data: [],
        },
        {
          name: "Total de Sesiones",
          data: [],
        },
        {
          name: "Total de Archivados",
          data: [],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
      },
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
      patients: (state) => state.patients.patients,
      dashbord: (state) => state.widget.activity,
      diagsPsico: (state) => state.diags.diagsPsico,
      patientsArchived: (state) => state.patients.patientsArchived,
      monthly: (state) => state.calendar.monthly,
      sesionList: (state) => state.sesion.sesionList,
      initialEvents: (state) => state.calendar.initialEvents,
    }),
    fechaHoy() {
      const fecha = new Date();
      const opciones = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return fecha.toLocaleDateString("es-ES", opciones);
    },
  },
  async mounted() {
    const data = {
      id: localStorage.getItem("userId"),
    };

    await this.loadDataForChart();
    await this.getUser({ payload: data });
    await this.getPatients();
    await this.getPatientsArchived();
    await this.getSesionsByProfesionalId({ payload: data });
    const chartsData = await this.getCharts();
    this.getDiagsPsico();
    $("#activePatientsTable").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });

    $("#activePatients").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });

    $("#tableArchived").DataTable({
      bFilter: true,
      bSearchable: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
      },
    });
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      getPatients: "patients/getPatients",
      getPatientsArchived: "patients/getPatientsArchived",
      createPatient: "patients/createPatient",
      getMonthly: "calendar/getMonthly",
      getDiagsPsico: "diags/getDiagsPsico",
      updateUser: "user/updateUser",
      getSesionsByProfesionalId: "sesion/getSesionsByProfesionalId",
      getCharts: "psyco/getChart",
    }),
    obtenerHoraDesdeFecha(fechaCompleta) {
      const fecha = new Date(fechaCompleta);

      const horas = fecha.getHours();
      const minutos = fecha.getMinutes();
      const segundos = fecha.getSeconds();

      const horaFormateada = `${horas}:${minutos}:${segundos}`;
      return horaFormateada;
    },
    async loadDataForChart() {
      try {
        const chartsData = await this.getCharts();

        if (chartsData.data.length === 0) {
          s;
          return;
        }

        const monthNames = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        let monthLabels = [];
        let totalUsersData = [];
        let totalSessionsData = [];
        let totalArchivedData = [];

        for (const data of chartsData.data) {
          const monthNumber = parseInt(data.month.split("-")[1], 10);
          const monthLabel = monthNames[monthNumber - 1];
          monthLabels.unshift(monthLabel);

          // Asumiendo que los datos necesarios están en las propiedades correctas.
          totalUsersData.unshift(data.total_users);
          totalSessionsData.unshift(data.total_sessions);
          totalArchivedData.unshift(data.total_archives);
        }

        // Actualiza las categorías del eje x y las series
        this.chartOptions.xaxis.categories = monthLabels;
        this.series[0].data = totalUsersData;
        this.series[1].data = totalSessionsData;
        this.series[2].data = totalArchivedData;

        // Actualiza ApexCharts
        this.$refs.chart.updateOptions(this.chartOptions);
        this.$refs.chart.updateSeries(this.series);

        console.log("chartOptions:", this.chartOptions);
        console.log("Existing categories:", this.chartOptions.xaxis.categories);
      } catch (error) {
        console.error("Error al cargar datos para el gráfico", error);
      }
    },
    async changeStatus(patient) {
      try {
        const change = {
          id: patient.id,
          user_type: patient.user_type,
          psychologist_id: patient.psychologist_id,
          person_type: patient.person_type,
          enable_sys: false,
        };
        const response = await this.updateUser({ payload: change });
        await this.getPatients();
        await this.getPatientsArchived();
        this.$toast.show("Se cambio de estado exitosamente", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
      } catch (e) {
        this.$toast.show("No se pudo cambiar de estado", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
    async changeStatusActive(patient) {
      try {
        const change = {
          id: patient.id,
          user_type: patient.user_type,
          psychologist_id: patient.psychologist_id,
          person_type: patient.person_type,
          enable_sys: true,
        };
        const response = await this.updateUser({ payload: change });
        await this.getPatients();
        await this.getPatientsArchived();
        this.$toast.show("Se cambio de estado exitosamente", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
      } catch (e) {
        this.$toast.show("No se pudo cambiar de estado", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
    showModal(data) {
      // Puedes establecer cualquier contenido aquí
      this.modalData = data;
      const modal = document.getElementById("myModal");
      modal.classList.add("show");
      modal.style.display = "block";
    },
    closeModal() {
      const modal = document.getElementById("myModal");
      modal.classList.add("show");
      modal.style.display = "none";
      this.modalData = "";
    },
    isGender(gender) {
      return gender === "female" ? "Femenino" : "Masculino";
    },
    getHour(timestamp) {
      const date = new Date(timestamp * 1000);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const ampm = hours >= 12 ? "PM" : "AM";
      return `${hours}:${minutes}:${seconds} ${ampm}`;
    },

    async addPatient() {
      try {
        const data = { ...this.form, psychologist_id: this.userInfo.id };
        await this.createPatient({ payload: data });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
