<template>
  <div class="notification-box">
    <vue-feather type="bell"></vue-feather>
  </div>
  <ul class="notification-dropdown onhover-show-div">
    <li>
      <vue-feather type="bell"></vue-feather>
      <h6 class="f-18 mb-0">Notificaciones</h6>
    </li>
    <li v-for="notification in notifications" :key="notification.id">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0"><vue-feather type="bell"></vue-feather></div>
        <div class="flex-grow-1">
          <p>
            <router-link :to="`/invitation/${notification.institution_id}`"
              >Invitación de una institución</router-link
            >
            <span class="pull-right">{{
              formatTime(notification.createdAt)
            }}</span>
          </p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["notifications"],
  data() {
    return {};
  },
  methods: {
    formatTime(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleString();
    },
  },
};
</script>
