<template>
  <div class="notification-box">
    <vue-feather type="help-circle"></vue-feather>
  </div>
  <ul class="notification-dropdown onhover-show-div">
    <li>
      <vue-feather type="message-square"></vue-feather>
      <a :href="whatsappLink" target="_blank">Soporte WhatsApp</a>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      phoneNumber: "51955720185",
    };
  },
  computed: {
    whatsappLink() {
      return `https://wa.me/${this.phoneNumber}`;
    },
  },
};
</script>
