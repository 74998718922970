import email from "../../data/email.json"

const state = {
	data : email.data,
   
};


export default {
	namespaced: true,
	state,
	
};