import * as types from "../mutation-types";
import treatmentAPI from "@/api/treatment";

const state = {
  treatments: [],
};

const getters = {
  treatments: (state) => state.treatments,
};

const actions = {
  async createTreatment({ commit }, payload) {
    try {
      const response = await treatmentAPI.createTreatment(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getTreatments({ commit }, payload) {
    try {
      const response = await treatmentAPI.getTreatments(payload);
      const treatments = response.data;
      commit(types.SET_TREATMENTS, { treatments });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_TREATMENTS](state, { treatments }) {
    state.treatments = treatments;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
