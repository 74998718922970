<template>
     <Breadcrumbs main="Gallery" title="Image Hover Effects"/>
       <div class="container-fluid">
           <hoverEffect1/>
           <hoverEffect2/>
           <hoverEffect3/>
           <hoverEffect4/>
           <hoverEffect5/>
           <hoverEffect6/>
           <hoverEffect7/>
           <hoverEffect8/>
           <hoverEffect9/>
           <hoverEffect10/>
           <hoverEffect11/>
           <hoverEffect12/>
           <hoverEffect13/>
           <hoverEffect14/>
           <hoverEffect15/>
       </div>
</template>
<script>
import hoverEffect1 from "@/components/gallery/hovergallery/hoverEffect1.vue"
import hoverEffect2 from "@/components/gallery/hovergallery/hoverEffect2.vue"
import hoverEffect3 from "@/components/gallery/hovergallery/hoverEffect3.vue"
import hoverEffect4 from "@/components/gallery/hovergallery/hoverEffect4.vue"
import hoverEffect5 from "@/components/gallery/hovergallery/hoverEffect5.vue"
import hoverEffect6 from "@/components/gallery/hovergallery/hoverEffect6.vue"
import hoverEffect7 from "@/components/gallery/hovergallery/hoverEffect7.vue"
import hoverEffect8 from "@/components/gallery/hovergallery/hoverEffect8.vue"
import hoverEffect9 from "@/components/gallery/hovergallery/hoverEffect9.vue"
import hoverEffect10 from "@/components/gallery/hovergallery/hoverEffect10.vue"
import hoverEffect11 from "@/components/gallery/hovergallery/hoverEffect11.vue"
import hoverEffect12 from "@/components/gallery/hovergallery/hoverEffect12.vue"
import hoverEffect13 from "@/components/gallery/hovergallery/hoverEffect13.vue"
import hoverEffect14 from "@/components/gallery/hovergallery/hoverEffect14.vue"
import hoverEffect15 from "@/components/gallery/hovergallery/hoverEffect15.vue"
export default {
    components:{
        hoverEffect1,
        hoverEffect2,
        hoverEffect3,
        hoverEffect4,
        hoverEffect5,
        hoverEffect6,
        hoverEffect7,
        hoverEffect8,
        hoverEffect9,
        hoverEffect10,
        hoverEffect11,
        hoverEffect12,
        hoverEffect13,
        hoverEffect14,
        hoverEffect15
    }
}
</script>