<template>

<div class="row">
    <div class="card">
      <div class="social-tab">
        <!-- Rótulo con los tags -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: showtab === 'all' }" @click="filterFriends('all')">Todos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: showtab === 'friends' }" @click="filterFriends('friends')">Amigos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: showtab === 'coworkers' }" @click="filterFriends('coworkers')">Red de Trabajo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: showtab === 'companions' }" @click="filterFriends('companions')">Compañeros</a>
          </li>
        </ul>


      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" v-for="f in filteredFriends" :key="f.id">
      <div class="card custom-card" v-if="!hasParams || f.status !== 'pending'">
        <div class="card-header"><img class="img-fluid" :src="f.photo_large" alt=""></div>
        <div class="card-profile"><img class="rounded-circle" :src="f.profile_photo" alt=""></div>
        <div class="text-center profile-details">
          <h4>{{ f.name }}</h4>
          <h6>Psicologo</h6>
        </div>

        <div class="card-footer row">
          <div class="col-6 col-sm-6">
            <h6>Amigos</h6>
            <h3><span class="counter">1</span></h3>
          </div>
          <div class="col-6 col-sm-6">
            <h6>Likes</h6>
            <h3><span class="counter">{{ f.likes }}</span>M</h3>
          </div>
        </div>
        <button  @click="confirmedFriend(f.id)" v-if=" f.status === 'pending'" class="btn btn-secondary">Confirmar Amistad</button>
          <div class="dropdown">
            <!-- <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Agregar a
            </button> -->
          <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" @click="selectCategory('friends', f.id)">Amigos</a>
                <a class="dropdown-item" @click="selectCategory('coworkers', f.id)">Red de Trabajo</a>
                <a class="dropdown-item" @click="selectCategory('companions', f.id)">Compañeros</a>
          </div> -->
       
    <a class="nav-link dropdown-toggle bg-transparent border-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Agregar a</a>
      <div class="dropdown-menu">
        <a class="dropdown-item" @click="selectCategory('friends', f.id)">Amigos</a>
        <a class="dropdown-item" @click="selectCategory('coworkers', f.id)">Red de Trabajo</a>
        <a class="dropdown-item" @click="selectCategory('companions', f.id)">Compañeros</a>
      </div>
     
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import imageMixin from "@/mixins/commen/imageMixin"
import { mapActions, mapState } from "vuex";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  mixins: [imageMixin],
  data() {
    return {
      showtab: "all",
      pending: false,
      isFriend: false,
      friends: [
        { id: 1, profile_photo: "https://via.placeholder.com/50", position: "Psicologo", country: "PE", likes: 1000, type: 'friend' },
        { id: 2, profile_photo: "https://via.placeholder.com/50", position: "Psicologo", country: "PE", likes: 1000, type: 'coworker' },
        { id: 3, profile_photo: "https://via.placeholder.com/50", position: "Psicologo", country: "VE", likes: 1000, type: 'companion' },
        { id: 4, profile_photo: "https://via.placeholder.com/50", position: "Psicologo", country: "VE", likes: 1000, type: 'friend' },
      ],
    }
  },
  components: {
  },
  computed: {
    ...mapState({
      friends: (state) => state.friends.friends,
    }),
    hasParams() {
      return Object.keys(this.$route.params).length > 0;
    },
    filteredFriends() {
      if (this.showtab === 'friends') {
        return this.friends.filter(f => f.type === 'friend');
      } else if (this.showtab === 'coworkers') {
        return this.friends.filter(f => f.type === 'coworker');
      } else if (this.showtab === 'companions') {
        return this.friends.filter(f => f.type === 'companion');
      }
      return this.friends; // Todos
    },

  },
  methods: {
    ...mapActions({
      updateFriend: "friends/updateFriend",
      getFriends:"friends/getFriends"
    }),

    
    filterFriends(tab) {
      this.showtab = tab;
    },

    async confirmedFriend(id){
      const data = {
        id:id,
        status_of_friend: "approved"
      }
      await this.updateFriend({payload:data});
      const info = {
        id: localStorage.getItem("userId"),
      };
      await this.getFriends({payload:info})
    },
    selectCategory(category, friendId) {
        // Aquí puedes agregar lógica para actualizar el amigo con la nueva categoría
        console.log(`Agregar a ${category} para el amigo con ID ${friendId}`);

        // Lógica para asignar la categoría
        const friendToUpdate = this.friends.find(f => f.id === friendId);
        if (friendToUpdate) {
            friendToUpdate.type = category; // Actualiza el tipo del amigo
            // Aquí podrías hacer una llamada para actualizar en el backend si es necesario
            this.updateFriend({ payload: { id: friendId, type: category } });
        }

    },

  }
}
</script>

<style lang="scss">
.nav-item {
  cursor: pointer;
}


.nav-link {
  display: block;
  padding: 10px 15px;
  border-radius: 4px;
  margin-right: 10px;
  transition: background-color 0.3s;

  &.active {
    background-color: #007bff;
    color: white;
  }

  &:hover {
    background-color: #0056b3;
    color: black;
  }
}

.custom-btn {
  background-color: #00a400;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: not-allowed;
}

.custom-btn:hover {
  background-color: #00a400;
}
</style>