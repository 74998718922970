<template>
  <div class="card_tab">
    <div class="content-header">
      <Breadcrumbs main="" title="Plan de tratamiento" />
      <button class="small-primary-button" @click="submitTreatment">
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span v-else>
          <i class="fa fa-bookmark-o mx-2" aria-hidden="true"></i>
          Guardar y continuar
        </span>
      </button>
      <button class="small-primary-button" @click="submitAndExit">
      <i class="fa fa-sign-out mx-1" aria-hidden="true"></i>
       Guardar y Salir
      </button>
    </div>
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="row">
            <h4 class="h4_pre mb-3">Pre diagnostico</h4>

            <div class="col-3 mb-4">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="inlineRadio1"
                  value="DSM"
                  v-model="form.treatment.pre_diag"
                  name="radioGroup"
                />
                <label class="form-check-label" for="inlineRadio1">DSM.</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="inlineRadio2"
                  value="CIE"
                  v-model="form.treatment.pre_diag"
                  name="radioGroup"
                />
                <label class="form-check-label" for="inlineRadio2">CIE.</label>
              </div>
            </div>
            <div class="col-9">
              <div class="form-group">
                <multiselect
                  v-model="form.treatment.title"
                  placeholder="Titulo"
                  label="title"
                  :options="diags"
                  track-by="id"
                  :multiple="true"
                  :taggable="true"
                  :clear-on-select="true"
                ></multiselect>
              </div>
            </div>
            <div class="col-12">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea4"
                rows="3"
                v-model="form.treatment.detail"
                placeholder="Detallar presunción diagnostica"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="row mt-4">
            <h4 class="h4_pre mb-3">Objetivos a lograr en el tratamiento</h4>
            <div
              v-for="(item, idx) in goals"
              :key="idx"
              class="col-lg-4 col-md-4 col-12"
              style="margin-bottom: 15px"
            >
              <div class="input-group">
                <input
                  v-model="item.name"
                  type="text"
                  class="form-control"
                  aria-label="Objetivo"
                  :placeholder="`Objetivo ` + parseInt(idx + 1)"
                />
                <span
                  v-if="idx !== 0"
                  class="input-group-text"
                  style="cursor: pointer; color: #bb0c0c; border: none"
                  @click="removeGoal(idx)"
                  ><i class="fa fa-minus"></i
                ></span>
              </div>
            </div>
            <div class="col-12 mb-4">
              <button class="btn p-0" @click="addGoal(goal)">
                + Agregar Objetivo
              </button>
            </div>

            <div class="col-12">
              <div class="form-check-inline">
                <label class="col-form-label"
                  >Número de sesiones programadas</label
                >
                <input
                  class="form-control"
                  type="text"
                  placeholder="Número de sesiones"
                  v-model="form.treatment.number_sessions"
                />
              </div>
              <div class="form-check form-check-inline">
                <label class="col-form-label">Modalidad de atencion</label>
                <select
                  class="form-select digits"
                  id="exampleFormControlSelect9"
                  placeholder="Seleccionar"
                  v-model="form.treatment.mode"
                >
                  <option value="1">Presencial</option>
                  <option value="0">Virtual</option>
                  <option value="2">Ambos</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.h4_pre {
  font-size: 23px;
}
</style>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["patient"],
  data() {
    return {
      form: {
        treatment: {
          title: [],
          pre_diag: "DSM",
          detail: "",
          objective: [],
          number_sessions: 0,
          mode: 1,
        },
      },
      loading: false,
      submit: false,
      goals: [
        {
          name: "",
        },
      ],
      goal: {
        name: "",
      },
      options: [
        {
          id: "1",
          title: "Duelo",
        },
        { id: "2", title: "Pareja y Familia" },
        { id: "3", title: "Drogas y addicciones" },
        { id: "4", title: "Estimulacion temprana" },
        { id: "5", title: "Problemas de aprendizaje" },
        { id: "6", title: "Transtornos emocionales" },
        {
          id: "788f314a-6915-11ee-aa48-e765df0b89db",
          title: "T. de estrés postraumático",
        },
      ],
    };
  },
  watch: {
    patient: {
      handler(newValue, oldValue) {
        this.form.treatment.title = newValue.treatment.title;
        this.form.treatment.pre_diag = newValue.treatment.pre_diag;
        this.form.treatment.mode = newValue.treatment.mode;
        this.form.treatment.number_sessions =
          newValue.treatment.number_sessions;
        this.form.treatment.detail = newValue.treatment.detail;
        this.form.treatment.objective = newValue.treatment.objective;
        this.goals = newValue.treatment.objective.map((item) => {
          let goal = {
            name: item,
          };
          return goal;
        });
      },
    },
    goalsCount(newVal, oldVal) {
      this.form.treatment.number_sessions = newVal;
    },
  },
  computed: {
    ...mapState({
      diags: (state) => state.diags.diags,
    }),
    goalsCount() {
      return this.goals.length;
    },
  },
  mounted() {
    this.getDiags();
  },
  methods: {
    ...mapActions({
      createTreatment: "treatment/createTreatment",
      setStep: "patients/setStep",
      getDiags: "diags/getDiags",
    }),
    addGoal(goal) {
      this.goals.push({ ...goal });
    },
    removeGoal(idx) {
      this.goals.splice(idx, 1);
    },
    async submitTreatment() {
      try {
        this.loading = true;
        const objectives = this.goals.map((goal) => goal.name);
        this.form.treatment.objective = objectives;
        this.$emit("create-patient", this.form);
        setTimeout(function () {
          this.loading = false;
        }, 2000);
      } catch (error) {
        this.$toast.show("Verificar bien los datos de ingreso", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
    async submitAndExit() {
      try {
        this.submit = true;
        const objectives = this.goals.map((goal) => goal.name);
        this.form.treatment.objective = objectives;
        this.$emit("create-patient", this.form);
        this.$toast.show("Guardado exitosamente ", {
            theme: "outline",
            position: "top-right",
            icon: "times",
            type: "success",
            duration: 2000,
          });
        setTimeout(() => {
          this.$router.push({ name: "myConsultingRoom" });
          }, 1000); 
      } catch (error) {
        this.$toast.show("Verificar bien los datos de ingreso", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },

  },
};
</script>
