<template>
      <div class="col-xl-4 col-md-6 box-col-33">
                <div class="card">
                  <div class="card-header pb-0">
                    <div class="d-flex justify-content-between">
                      <div class="flex-grow-1"> 
                        <p class="square-after f-w-600 header-text-primary">Recent Activity<i class="fa fa-circle"> </i></p>
                        <h4>New & Update</h4>
                      </div>
                     
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="activity-timeline">
                      <div class="d-flex">
                        <div class="activity-line"></div>
                        <div class="activity-dot-primary"></div>
                        <div class="flex-grow-1"><span class="f-w-600 d-block">Updated Product</span>
                          <p class="mb-0">I like to be real. I don't like things to be staged or fussy.</p>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="activity-dot-primary"></div>
                        <div class="flex-grow-1"><span class="f-w-600 d-block">You liked James products</span>
                          <p class="mb-0">If you have it, you can make anything look good.</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-start">
                        <div class="activity-dot-secondary"></div>
                        <div class="flex-grow-1"><span class="f-w-600 d-block">James just like your product</span>
                          <p class="mb-0">I like to design everything to do with the body.</p>
                        </div><i class="fa fa-circle circle-dot-primary"></i>
                      </div>
                      <div class="d-flex">
                        <div class="activity-dot-primary"></div>
                        <div class="flex-grow-1"><span class="f-w-600 d-block">Jenna commented on your product</span>
                          <p class="mb-0">Fashion fades, only style remain the same.</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-start">
                        <div class="activity-dot-secondary"></div>
                        <div class="flex-grow-1"><span class="f-w-600 d-block">Jihan Doe just like your product</span>
                          <p class="mb-0">Design and style should work toward making you look good and feel good without lot of effort.</p>
                        </div><i class="fa fa-circle circle-dot-secondary"></i>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
</template>