<template>
    <Breadcrumbs main="Home" title="Dashboard" />
    <div class="container-fluid dashboard-default">
        <div class="row">
            <welcomeBack />
            <!-- <div class="col-xl-3 col-md-6 box-col-25">
                <totalRevenue />
                <totalInvestment />
            </div>
            <ourUser />
            <totalEarning />
            <appointmentDetail />
            <useCountry />
            <totalGrowth />
            <newUpdate />
            <chatView />
            <todoList /> -->
        </div>
    </div>
</template>
<script>
import welcomeBack from "@/components/dashboards/default/welcomeBack.vue"
import totalRevenue from "@/components/dashboards/default/totalRevenue.vue"
import totalInvestment from "@/components/dashboards/default/totalInvestment.vue"
import ourUser from "@/components/dashboards/default/ourUser.vue"
import totalEarning from "@/components/dashboards/default/totalEarning.vue"
import appointmentDetail from "@/components/dashboards/default/appointmentDetail.vue"
import useCountry from "@/components/dashboards/default/useCountry.vue"
import totalGrowth from "@/components/dashboards/default/totalGrowth.vue"
import newUpdate from "@/components/dashboards/default/newUpdate.vue"
import chatView from "@/components/dashboards/default/chatView.vue"
import todoList from "@/components/dashboards/default/todoList.vue"
export default {
    components:{
        welcomeBack,
        totalRevenue,
        totalInvestment,
        ourUser,
        totalEarning,
        appointmentDetail,
        useCountry,
        totalGrowth,
        newUpdate,
        chatView,
        todoList
    },
   
}
</script>