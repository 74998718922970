<template>
      <div class="col-sm-12 col-xl-6">
                <div class="card height-equal">
                  <div class="card-header pb-0">
                    <h4>Dismissing</h4><span>To animate alerts when dismissing them, be sure to add the .fade and .show classes.</span>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-primary alert-dismissible fade show" role="alert"><strong>Holy ! </strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"><strong>Holy !</strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-success alert-dismissible fade show" role="alert"><strong>Holy !</strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-info alert-dismissible fade show" role="alert"><strong>Holy !</strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Holy !</strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Holy !</strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-light alert-dismissible fade show" role="alert"><strong>Holy !</strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-dark alert-dismissible fade show" role="alert"><strong>Holy !</strong> You can check in on some of those fields below.
                      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                  </div>
                </div>
              </div>
</template>