<template>
     <div class="col-sm-12 col-xl-4">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Switch With icon</h4>
                  </div>
                  <div class="card-body"> 
                    <div class="row switch-showcase">
                      <div class="col-sm-12">
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Large Size</label>
                          <div class="flex-grow-1 text-end switch-lg icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Large Uncheked</label>
                          <div class="flex-grow-1 text-end switch-lg icon-state">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Normal Size</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Normal Uncheked</label>
                          <div class="flex-grow-1 text-end icon-state">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex mb-2">
                          <label class="col-form-label m-r-10">Small Size</label>
                          <div class="flex-grow-1 text-end switch-sm icon-state">
                            <label class="switch">
                              <input type="checkbox" checked=""><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                        <div class="d-flex">
                          <label class="col-form-label m-r-10">Small Size Uncheked</label>
                          <div class="flex-grow-1 text-end switch-sm icon-state">
                            <label class="switch">
                              <input type="checkbox"><span class="switch-state"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>