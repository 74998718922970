import axios from "axios";

const HOST = process.env.VUE_APP_API_INVITATION;

export default {
  async createInvitation(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/auth/internal/invitation`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async getNotifications(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/notify/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
