<template>
  <Breadcrumbs main="Home" title="Añadir nuevo psicologo" />

  <div class="container">
    <div class="row mb-5">
      <div class="card_fondo_blanco">
        <h3 class="h3_web">Invitación para unir a nuevo psicologo</h3>
        <div class="mb-3">
          <label for="anadir" class="form-label">Añadir</label>
          <multiselect
            v-model="form.user"
            placeholder="@usuario"
            label="nickname"
            :options="users"
            track-by="nickname"
            :multiple="false"
            :taggable="true"
            :clear-on-select="true"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Asunto</label>
          <textarea
            v-model="form.subject"
            class="form-control"
            placeholder="Invitación a unirse a la red de colaboradores de @ceasperú"
          >
          </textarea>
        </div>
        <div class="mb-3">
          <label for="mensaje" class="form-label">Mensaje</label>
          <textarea
            v-model="form.message"
            class="form-control"
            placeholder="Opcional"
          >
          </textarea>
        </div>
        <div class="mb-3 form-check">
          <input type="checkbox" class="form-check-input" id="terminos" />
          <label class="form-check-label" for="terminos"
            >Acepto terminos y condiciones</label
          >
        </div>
        <button class="btn btn-primary mb-5" @click="sendInvitation">
          Enviar
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.card_fondo_blanco {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  textarea {
    min-height: 150px !important;
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";
import Autocomplete from "./autcomplete.vue";
export default {
  components: {
    Autocomplete,
  },
  data() {
    return {
      form: {
        email: "",
        institution_id: "",
        user: "",
        message: "",
        subject: "",
      },
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
    }),
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    ...mapActions({
      createInvitation: "invitation/createInvitation",
      getUsers: "user/getUsers",
    }),
    async sendInvitation() {
      try {
        this.form.institution_id = localStorage.getItem("userId");
        let form = {
          institution_id: this.form.institution_id,
          internal_user_id: this.form.user.id,
          nickname: this.form.user.nickname,
          email: this.form.user.email,
          content: this.form.message,
          subject: this.form.subject,
        };
        await this.createInvitation({ payload: form });
        this.form = {
          email: "",
          institution_id: "",
          user: "",
          message: "",
          subject: "",
        };
        this.$toast.show("Se envio exitosamente ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "success",
          duration: 2000,
        });
        this.$router.push({
          path: `/mi-consultorio`,
        });
      } catch (error) {
        this.$toast.show("Error al hacer la invitaciòn", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
        console.log(error);
      }
    },
  },
};
</script>
