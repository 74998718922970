<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 p-0 footer-left">
          <p class="mb-0">Copyright © 2023 Sigma todos los derechos reservados.</p>
        </div>
        <div class="col-md-6 p-0 footer-right">
          <!-- <p class="mb-0">Hand-crafted & made with <i class="fa fa-heart font-danger"></i></p> -->
        </div>
      </div>
    </div>
  </footer>
</template>