<template>
  <div class="col-xl-12 col-lg-12 col-md-12 box-col-50 cols-xl-50">
    <div class="card product-slider">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <div class="flex-grow-1">
            <p class="square-after f-w-600">
              Diagnósticos más frecuentes<i class="fa fa-circle"> </i>
            </p>
            <ul class="cloud" role="navigation" aria-label="Webdev word cloud">
              <li v-for="item in diags" :key="item.id">
                <a href="#" :data-weight="item.counter_diag"
                  >{{ item.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css">
ul.cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.75rem;
  /* width: 450px; */
}

ul.cloud a {
  /*
  Not supported by any browser at the moment :(
  --size: attr(data-weight number);
  */
  --size: 4;
  --color: #a33;
  color: var(--color);
  font-size: calc(var(--size) * 0.25rem + 0.5rem);
  display: block;
  padding: 0.125rem 0.25rem;
  position: relative;
  text-decoration: none;
  /*
  For different tones of a single color
  opacity: calc((15 - (9 - var(--size))) / 15);
  */
}

ul.cloud a[data-weight="1"] {
  --size: 1;
}
ul.cloud a[data-weight="2"] {
  --size: 2;
}
ul.cloud a[data-weight="3"] {
  --size: 3;
}
ul.cloud a[data-weight="4"] {
  --size: 4;
}
ul.cloud a[data-weight="5"] {
  --size: 6;
}
ul.cloud a[data-weight="6"] {
  --size: 8;
}
ul.cloud a[data-weight="7"] {
  --size: 10;
}
ul.cloud a[data-weight="8"] {
  --size: 13;
}
ul.cloud a[data-weight="9"] {
  --size: 16;
}

ul[data-show-value] a::after {
  content: " (" attr(data-weight) ")";
  font-size: 1rem;
}

ul.cloud li:nth-child(2n + 1) a {
  --color: #181;
}
ul.cloud li:nth-child(3n + 1) a {
  --color: #33a;
}
ul.cloud li:nth-child(4n + 1) a {
  --color: #c38;
}

ul.cloud a:focus {
  outline: 1px dashed;
}

ul.cloud a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background: var(--color);
  transform: translate(-50%, 0);
  opacity: 0.15;
  transition: width 0.25s;
}

ul.cloud a:focus::before,
ul.cloud a:hover::before {
  width: 100%;
}

@media (prefers-reduced-motion) {
  ul.cloud * {
    transition: none !important;
  }
}
</style>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["diags"],
  setup() {
    return {
      modules: [Pagination],
    };
  },
};
</script>
