<template>
  <div class="page-login container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 col-md-5 p-0">
        <div class="login-card">
          <div>
            <div class="text-center mb-4">
              <a class="logo">
                <img
                  class="img-fluid logo"
                  src="/img/logo.png"
                  alt="login page"
                />
              </a>
              <p class="text-center logo-info">
                Sistema Integral de Gestión y Monitoreo de Atención Psicológica
              </p>
            </div>
            <div class="login-main">
              <div class="theme-form">
                <h4 class="text-center mb-2">Bienvenido de vuelta</h4>

                <div class="form-group">
                  <label class="col-form-label">Email</label>
                  <input
                    v-model="email"
                    class="form-control"
                    type="email"
                    placeholder="Ingresa tu email"
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <div class="form-input position-relative">
                    <input
                      v-model="password"
                      :type="showPasswordLogin ? 'text' : 'password'"
                      class="form-control"
                      name="login[password]"
                      placeholder="Ingresa tu contraseña"
                    />
                    <div class="show-hide" @click="togglePasswordVisibilitys">
                      <span :class="{ show: showPasswordLogin }">{{
                        showPasswordLogin ? "Ocultar" : "Mostrar"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox" />
                    <label class="text-muted" for="checkbox1">Recordarme</label>
                  </div>
                  <router-link class="link" to="/auth/password-recovery"
                    >Olvidé mi contraseña</router-link
                  >
                  <div class="text-end mt-3">
                    <button
                      class="btn btn-primary btn-block w-100"
                      type="submit"
                      @click="doLogin"
                    >
                      Iniciar Sesión
                    </button>
                  </div>
                </div>
                <p class="mt-4 mb-0 text-center">
                  ¿No tienes cuenta?<router-link
                    class="ms-2"
                    to="/auth/register"
                    >Regístrate</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7 px-3 py-5 scrollable-container">
        <h3 class="text-center h3_login">ENCUENTRA TU PSICÓLOGO EN PERÚ</h3>
        <div class="card profit-card p-4">
          <div class="form">
            <div class="row">
              <div class="col-4 mb-3">
                <label class="d-block">Departmento</label>
                <select
                  v-model="state_country"
                  @change="updateProvincias"
                  class="form-control"
                >
                  <option value="" disabled selected>Elige una opción</option>
                  <option
                    v-for="(departamento, idx) in departamentos"
                    :key="idx"
                    :value="departamento"
                  >
                    {{ departamento.nombre_ubigeo }}
                  </option>
                </select>
              </div>
              <div class="col-4 mb-3">
                <label class="d-block">Provincia</label>
                <select
                  v-model="province"
                  @change="updateDistritos"
                  class="form-control"
                >
                  <option value="" disabled selected>Elige una opción</option>
                  <option
                    v-for="(provincia, idx) in provincias"
                    :key="idx"
                    :value="provincia"
                  >
                    {{ provincia.nombre_ubigeo }}
                  </option>
                </select>
              </div>
              <div class="col-4 mb-3">
                <label class="d-block">Distrito</label>
                <select v-model="district" class="form-control">
                  <option value="" disabled selected>Elige una opción</option>
                  <option
                    v-for="(distrito, idx) in distritos"
                    :key="idx"
                    :value="distrito"
                  >
                    {{ distrito.nombre_ubigeo }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-4 col-md-2 mb-3">
                <label class="d-block">Genero</label>
                <select v-model="form.gender" class="form-control">
                  <option value="" disabled selected>Elige</option>
                  <option
                    v-for="(genre, idx) in genres"
                    :key="idx"
                    :value="genre.id"
                  >
                    {{ genre.name }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-md-3 mb-3">
                <label class="d-block">Modalidad</label>
                <select v-model="form.modality" class="form-control">
                  <option value="" disabled selected>Elige una opción</option>
                  <option
                    v-for="(modalty, idx) in modalties"
                    :key="idx"
                    :value="modalty.id"
                  >
                    {{ modalty.name }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-md-3 mb-3">
                <label class="d-block">Abordaje en:</label>
                <select v-model="form.approach_id" class="form-control">
                  <option value="" disabled selected>Elige una opción</option>
                  <option
                    v-for="value in approachs"
                    :value="value.id"
                    :key="value.id"
                  >
                    {{ value.name }}
                  </option>
                </select>
              </div>
              <div class="col-2 col-md-2 mb-3">
                <label class="d-block">Atiende a:</label>
                <select v-model="form.terapeutic_fomation" class="form-control">
                  <option value="" disabled selected>Elige una opción</option>
                  <option
                    v-for="(attends_to, idx) in attends_tos"
                    :key="idx"
                    :value="attends_to.id"
                  >
                    {{ attends_to.name }}
                  </option>
                </select>
              </div>
              <div class="col-3 col-md-2 mb-3">
                <label class="d-block">Instituciones:</label>
                <input v-model="form.institutions" type="checkbox" value="1" />
              </div>
              <div class="col-12 mb-3">
                <button class="btn btn-primary" @click="getResults">
                  Buscar <i class="fa fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card profit-card p-4">
          <div class="h4 text-center">Resultados :</div>
          <ul>
            <li v-for="(result, idx) in results" :key="idx">
              <ResultCard :result="result" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.card-modal {
  width: 100%;
  height: 400px;
}

.scrollable-container {
  height: 100vh;
  overflow-y: auto;
}

.scrollable-container input,
.scrollable-container select {
  border: 1px solid #17182947 !important;
}

.h3_login {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
}
</style>
<script>
import { mapState, mapActions } from "vuex";
import ResultCard from "./results.vue";
import animationMixin from "@/mixins/commen/animationMixin";
import StarRating from "vue-star-rating";
export default {
  mixins: [animationMixin],
  components: {
    ResultCard,
  },
  data() {
    return {
      showPasswordLogin: false,
      type: "password",
      StarRating,
      email: "",
      password: "",
      showModal: true,
      district: "",
      province: "",
      state_country: "",
      form: {
        gender: "",
        state_country: "",
        district: "",
        approach_id: "",
        terapeutic_fomation: "",
        institution_id: "",
        province: "",
      },
      user: {
        type: 1,
      },
      departamentos: [],
      provincias: [],
      distritos: [],
      detailShow: true,
      genres: [
        { id: "male", name: "Masculino" },
        { id: "female", name: "Femenino" },
        { id: "other", name: "Otro" },
      ],
      modalties: [
        { id: 1, name: "Prescencial" },
        { id: 0, name: "Remoto" },
        { id: 3, name: "Ambos" },
      ],
      boarding_ins: [
        { id: 1, name: "Duelo" },
        { id: 2, name: "Pareja y familia" },
        { id: 3, name: "Drogas y adicciones" },
        { id: 3, name: "Neurodesarrollo" },
        { id: 3, name: "Estimulación temprana" },
        { id: 3, name: "Problemas de aprendizaje" },
        { id: 3, name: "Riesgo suicida" },
        { id: 3, name: "Trastorno alimenticio" },
        { id: 3, name: "Trastornos emocionales" },
        { id: 3, name: "Desarollo Infantil" },
        { id: 3, name: "Otros" },
      ],
      attends_tos: [
        { id: 1, name: "Niños y adolescentes" },
        { id: 2, name: "Jóvenes" },
        { id: 3, name: "Adultos" },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
      results: (state) => state.search.results,
      approachs: (state) => state.approach.approachs,
    }),
    enterClass() {
      return `animated ${this.enterAnimation}`;
    },
  },
  mounted() {
    this.loadDepartamentos();
    this.getApproachs();
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      getUser: "user/getUser",
      searchPsycos: "search/searchPsycos",
      getApproachs: "approach/getApproachs",
    }),
    togglePasswordVisibilitys() {
      this.showPasswordLogin = !this.showPasswordLogin;
    },
    currentRating() {
      return this.rating;
    },
    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    async getResults() {
      try {
        if (typeof this.state_country === "object") {
          this.form.state_country = this.state_country.nombre_ubigeo;
        }

        if (typeof this.province === "object") {
          this.form.province = this.province.nombre_ubigeo;
        }

        if (typeof this.district === "object") {
          this.form.district = this.district.nombre_ubigeo;
        }
        await this.searchPsycos({ payload: this.form });
      } catch (error) {
        console.log(error);
      }
    },
    async doLogin() {
      try {
        let form = {
          email: this.email,
          password: this.password,
        };
        const response = await this.login({ payload: form });

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.user_id);
        localStorage.setItem("user", this.email);
        localStorage.setItem("userData", JSON.stringify(this.user));
        const data = {
          id: localStorage.getItem("userId"),
        };
        await this.getUser({ payload: data });
        if (this.userInfo.user_type === 3) {
          this.$router.push("/mi-institucion");
        } else {
          this.$router.push("/mi-consultorio");
        }
      } catch (error) {
        this.$toast.show("invalid email and password ", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
    async loadDepartamentos() {
      try {
        const response = await fetch("/data/ubigee/peru/departamentos.json");
        const data = await response.json();
        this.departamentos = data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateProvincias() {
      try {
        const response = await fetch("/data/ubigee/peru/provincias.json");
        const data = await response.json();
        this.provincias = data[this.state_country.id_ubigeo] || [];
      } catch (error) {
        console.error(error);
      }
    },
    async updateDistritos() {
      try {
        const response = await fetch("/data/ubigee/peru/distritos.json");
        const data = await response.json();
        this.distritos = data[this.province.id_ubigeo] || [];
      } catch (error) {
        console.error(error);
      }
    },
    getDistritoById(distritoId) {
      // Busca el objeto del distrito por su ID en la lista de distritos
      return this.distritos.find(
        (distrito) => distrito.id_ubigeo === distritoId
      );
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
};
</script>
