<template>
  <div class="col call-chat-sidebar">
    <div class="card">
      <div class="card-body chat-body">
        <div class="chat-box">
          <div class="chat-left-aside">
            <div class="d-flex">
              <img
                class="rounded-circle user-image"
                :src="userInfo?.profile_photo"
                alt=""
              />
              <div class="flex-grow-1">
                <div class="about">
                  <div class="name f-w-600">{{ userInfo?.first_name }}</div>
                  <div class="status">active</div>
                </div>
              </div>
            </div>
            <div class="people-list" id="people-list">
              <div class="search">
                <form class="theme-form">
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="search"
                        v-on:keyup="setSerchUsers"
                        type="text"
                        placeholder="Buscar"
                      /><span class="input-group-text">
                        <i class="fa fa-search"></i
                      ></span>
                    </div>
                  </div>
                </form>
              </div>
              <ul class="list custom-scrollbar" v-if="search === ''">
                <li
                  class="clearfix"
                  v-for="(user, index) in psychologists"
                  :key="index"
                  @click="setActiveuser(user)"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="rounded-circle user-image"
                      :src="user.profile_photo"
                      alt=""
                    />
                    <div class="status-circle"></div>
                    <div class="flex-grow-1">
                      <div class="about">
                        <div class="name cursor-pointer">
                          {{ user.first_name }}
                        </div>
                        <div class="status">active</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul class="list" v-if="search != ''">
                <li
                  class="clearfix"
                  v-for="(user, index) in serchUser"
                  :key="index"
                  @click="setActiveuserSerch(user)"
                >
                  <img
                    class="rounded-circle user-image"
                    :src="getImgUrl(user.thumb)"
                    alt=""
                  />
                  <div class="status-circle" :class="user.StatusClass"></div>
                  <div class="about">
                    <div class="name">{{ user.name }}</div>
                    <div class="status">{{ user.status }}</div>
                  </div>
                </li>
                <div v-if="!serchUser.length">
                  <div class="search-not-found chat-search text-center">
                    <p>
                      Sorry, We didn't find any results matching this search
                    </p>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imageMixin from "@/mixins/commen/imageMixin";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [imageMixin],
  data() {
    return {
      text: "",
      search: "",
    };
  },
  computed: {
    ...mapState({
      psychologists: (state) => state.psyco.psychologists,
      userInfo: (state) => state.user.user,
      users: (state) =>
        state.chat.users.filter(function (user) {
          if (user.id !== 0) return user;
        }),
      serchUser: (state) => state.chat.serchUser,
      chats: (state) =>
        state.chat.chats.find(function (chat) {
          if (chat.id === state.chat.activeuser) {
            return chat;
          }
        }),
    }),
  },
  onMounted() {
    // await this.getPsycos();
    console.log(this.psychologists[0]);
    this.$store.dispatch("chats/setActiveUser", this.psychologists[0]);
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      getPsycos: "psyco/getPsyco",
      getSendMsg: "chats/getSendMsg",
      getRecivedMsg: "chats/getRecivedMsg",
    }),
    async setActiveuser(user) {
      this.$store.dispatch("chats/setActiveUser", user);
      let payload = {
        id: localStorage.getItem("userId"),
      };
      console.log("llego?");
      await this.getSendMsg({ payload: payload });
      await this.getRecivedMsg({ payload: payload });
    },
    async setActiveuserSerch(user) {
      this.$store.dispatch("chats/activeUser", user);
      this.search = "";
      let payload = {
        id: localStorage.getItem("userId"),
      };
      console.log("llego?");
      await this.getSendMsg({ payload: payload });
    },
    setSerchUsers: function () {
      if (this.search !== "")
        this.$store.dispatch("chat/setSerchUsers", this.search);
    },
  },
};
</script>
