<template>
     <div class="col-sm-12 col-lg-6 col-xl-4 col-md-12 box-col-6 xl-50">
                <div class="card">
                  <div class="calender-widget">
                    <div class="cal-img"></div>
                    <div class="cal-date">
                      <h5>25<br>APRIL</h5>
                    </div>
                    <div class="cal-desc text-center card-body">
                      <h3>I must explain to you how? </h3>
                      <p class="mb-0">We are excited about continuing to build a complete ecosystem around value commerce, where we can serve Bharat consumers wherever they are on their shopping journey. Our mission is to become India’s value lifestyle omni-channel leader.</p>
                    </div>
                  </div>
                </div>
              </div>
</template>