import axios from "axios";

const HOST = process.env.VUE_APP_API_PATIENT;

export default {
  async getPatients(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/user/patient/list/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getPatientsArchived(payload = {}) {
    try {
      const userID = localStorage.getItem("userId");
      const response = await axios({
        url: `${HOST}/user/patient/notlist/${userID}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createPatient(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/patient`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
};
