<template>
     <div class="row">
                          <div class="info-block m-b-20 m-t-30">
                            <nav aria-label="...">
                              <ul class="pagination pagination-primary">
                                <li class="page-item disabled"><a class="page-link" href="javascript:void(0)" tabindex="-1">Previous</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                                <li class="page-item active"><a class="page-link" href="javascript:void(0)">2 <span class="sr-only">(current)</span></a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:void(0)">Next</a></li>
                              </ul>
                            </nav>
                          </div>
                        </div>
</template>