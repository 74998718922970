<template>
     <div class="col-sm-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>Headings</h4><span>Use the included utility classes to recreate the small secondary heading text.  </span>
                  </div>
                  <div class="card-body typography">
                    <h3>Fancy display heading<small class="text-muted">With faded secondary text</small></h3>
                  </div>
                </div>
              </div>
</template>