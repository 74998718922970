<template>
     <div class="col-xl-2 col-md-6 box-col-5 xl-35 proorder-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <!-- side-bar colleps block stat-->
                      <div class="filter-block">
                        <h4>Brand </h4>
                        <ul>
                          <li>
                            <div class="form-check">
                              <input class="form-check-input" id="Raymond" type="checkbox" value="">
                              <label class="form-check-label" for="Raymond">Raymond</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input class="form-check-input" id="Pepe-Jeans" type="checkbox" value="">
                              <label class="form-check-label" for="Pepe-Jeans">Pepe-Jeans</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input class="form-check-input" id="Celio" type="checkbox" value="">
                              <label class="form-check-label" for="Celio">Celio</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input class="form-check-input" id="aime" type="checkbox" value="">
                              <label class="form-check-label" for="aime">aime</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input class="form-check-input" id="aliff" type="checkbox" value="">
                              <label class="form-check-label" for="aliff">aliff</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div class="collection-filter-block">
                        <ul class="pro-services">
                          <li>
                            <div class="d-flex"><vue-feather type="truck"></vue-feather>
                              <div class="flex-grow-1">
                                <h5>Free Shipping                                    </h5>
                                <p class="mb-0">Free Shipping World Wide</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex"><vue-feather type="clock"></vue-feather>
                              <div class="flex-grow-1">
                                <h5>24 X 7 Service                                    </h5>
                                <p class="mb-0">Online Service For New Customer                                 </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- silde-bar colleps block end here-->
                  </div>
                </div>
</template>