<template>
    <div class="col-sm-12 col-xl-4">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>With Images</h4>
                  </div>
                  <div class="card-body">
                    <div class="list-group"><a class="list-group-item list-group-item-action d-flex" href="javascript:void(0)"><img src="@/assets/images/user/1.jpg" alt="">
                        <div class="flex-grow-1 align-self-center">Cras justo odio</div></a><a class="list-group-item list-group-item-action d-flex" href="javascript:void(0)"><img src="@/assets/images/user/1.jpg" alt="">
                        <div class="flex-grow-1 align-self-center">Dapibus ac facilisis in</div></a><a class="list-group-item list-group-item-action d-flex" href="javascript:void(0)"><img src="@/assets/images/user/1.jpg" alt="">
                        <div class="flex-grow-1 align-self-center">Morbi leo risus</div></a><a class="list-group-item list-group-item-action d-flex disabled" href="javascript:void(0)"></a></div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-xl-4">
                <div class="card">
                  <div class="card-header pb-0">
                    <h4>With Image Rounded</h4>
                  </div>
                  <div class="card-body">
                    <div class="list-group"><a class="list-group-item list-group-item-action d-flex" href="javascript:void(0)"><img class="rounded-circle" src="@/assets/images/user/1.jpg" alt="">
                        <div class="flex-grow-1 align-self-center">Cras justo odio</div></a><a class="list-group-item list-group-item-action d-flex" href="javascript:void(0)"><img class="rounded-circle" src="@/assets/images/user/1.jpg" alt="">
                        <div class="flex-grow-1 align-self-center">Dapibus ac facilisis in</div></a><a class="list-group-item list-group-item-action d-flex" href="javascript:void(0)"><img class="rounded-circle" src="@/assets/images/user/1.jpg" alt="">
                        <div class="flex-grow-1 align-self-center">Morbi leo risus</div></a><a class="list-group-item list-group-item-action d-flex disabled" href="javascript:void(0)"></a></div>
                  </div>
                </div>
              </div>
</template>