<template>
  <div class="col-xl-8 col-lg-7 card">
    <div class="card-body">
      <h5>Datos Generales</h5>
      <div class="row g-3">
        <div class="col-md-4">
          <label class="form-label">Nombres</label>
          <input
            type="text"
            class="form-control"
            id="name"
            placeholder="Nombres"
            v-model="form.first_name"
          />
        </div>
        <div class="col-md-4">
          <label class="form-label" for="validationCustom02">Apellidos</label>
          <input
            class="form-control"
            type="text"
            id="name"
            placeholder="Apellidos"
            v-model="form.last_name"
          />
        </div>
        <div class="col-md-4 mb-3">
          <label class="form-label" for="validationCustomUsername">Edad</label>
          <input
            class="form-control"
            id="validationCustomUsername"
            type="text"
            placeholder="Edad"
            v-model="form.age"
          />
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-4">
          <label class="form-label" for="validationCustom03">Sexo</label>
          <select
            class="form-select"
            id="validationCustom04"
            v-model="form.gender"
            readonly
          >
            <option
              v-for="(gender, index) in gendersValues"
              :value="gender.value"
              :key="gender.value + index"
            >
              {{ gender.label }}
            </option>
          </select>
        </div>
        <div class="col-md-8">
          <label class="form-label" for="validationCustom04">Universidad</label>
          <select
            v-model="form.university_id"
            id="inputGenre"
            class="form-select"
          >
            <option
              v-for="item in universidades"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="form-label" for="validationCustom05"
            >Formación Terapéutica</label
          >
          <select
            class="form-select"
            id="validationCustom04"
            v-model="form.terapeutic_fomation"
            required=""
          >
            <option
              v-for="(value, index) in specialitiesValues"
              :value="value"
              :key="value + index"
            >
              {{ value }}
            </option>
          </select>
        </div>
        <div class="col-md-8 ml-4">
          <label class="form-label">Instrucción: </label>
          <br />
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input ml-4"
              type="checkbox"
              id="bach"
              value="true"
              v-model="form.undergraduate"
            />
            <label class="form-check-label" for="bach"
              >Pre grado/bachiller</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="maestria"
              value="true"
              v-model="form.master"
            />
            <label class="form-check-label" for="maestria">Maestria</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="doctorado"
              value="true"
              v-model="form.doctorade"
            />
            <label class="form-check-label" for="doctorado">Doctorado</label>
          </div>
        </div>
      </div>
      <br />
      <div v-if="form.user_type !== 3">
        <label class="form-label">Adjuntar Sello y Firma (jpg/png)</label>
        <input
          class="form-control"
          type="file"
          @change="handleFileChange"
          accept="image/*"
        />
        <div v-if="form.url_file !== ''" class="mt-4">
          <img
            :src="form.url_file"
            alt="Descripción de la imagen"
            width="200"
            height="100"
          />
        </div>
      </div>
      <div class="mt-4">
        <label class="form-label">Subir nueva imagen de portada</label>
        <input
          class="form-control"
          type="file"
          @change="handleFileChange2"
          accept="image/*"
        />
        <div v-if="form.photo_large !== ''" class="mt-4">
          <img
            :src="form.photo_large"
            alt="Descripción de la imagen"
            width="200"
            height="100"
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <h5 class="mt-2">Perfil Laboral</h5>
      <div class="row g-3 mt-2">
        <div class="col-md-6">
          <br />
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="collegiate"
              value="option1"
              v-model="colegiate_state"
            />
            <label class="form-check-label" for="collegiate">Colegiado</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox2"
              value="true"
              v-model="form.enable_sys"
            />
            <label class="form-check-label" for="inlineCheckbox2"
              >Habilitado</label
            >
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label" for="validationCustom05">Colegiatura</label>
          <input
            class="form-control"
            id="validationCustom05"
            type="text"
            v-model="form.collegiate"
            placeholder="colegiatura"
            required=""
          />
        </div>
      </div>
      <div v-if="form.user_type !== 3" class="row g-3 mt-2">
        <div class="col-md-8">
          <label class="form-label" for="validationCustom05">Atiendo a:</label>
          <select
            class="form-select"
            id="validationCustom04"
            v-model="form.attend_id"
          >
            <option
              v-for="(value, index) in attens"
              :value="value.id"
              :key="value.id + index"
            >
              {{ value.title }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="form-label" for="validationCustom05"
            >Abordaje en:</label
          >
          <select
            class="form-select"
            id="validationCustom04"
            v-model="form.approach_id"
            required=""
          >
            <option
              v-for="value in approachs"
              :value="value.id"
              :key="value.id"
            >
              {{ value.name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="form.user_type !== 3" class="row g-3 mt-2">
        <div class="col-md-8">
          <label class="form-label">Modalidad: </label>
          <br />
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input ml-4"
              type="checkbox"
              id="presencial"
              value="option1"
              v-model="form.onsite"
            />
            <label class="form-check-label" for="presencial">Presencial</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="remote"
              value="true"
              v-model="form.remote"
            />
            <label class="form-check-label" for="remote">Remota</label>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label" for="validationCustom05"
            >Precio de 1 sesion S/.</label
          >
          <input
            class="form-control"
            id="validationCustom05"
            type="number"
            v-model="form.price_session"
            placeholder="S/ 10"
            required=""
          />
        </div>
      </div>
      <div class="row g-3 mt-2">
        <div class="col-md-12">
          <label class="form-label" for="validationCustom05">Dirección</label>
          <input
            class="form-control"
            id="validationCustom05"
            type="text"
            v-model="form.address"
            placeholder="dirección"
            required=""
          />
        </div>
      </div>

      <div class="row g-3 mt-2">


        <div class="col-md-3">
          <label class="form-label" for="validationCustom05"
            >Nacionalidad</label
          >
          <input
            class="form-control"
            id="validationCustom05"
            type="text"
            v-model="form.nationality"
            placeholder="Nacionalidad"
            required=""
          />
        </div>

      </div>
      <div class="row g-3 mt-2">

        <div class="col-md-3">
          <label class="form-label" for="country">País</label>
          <select
            id="country"
            v-model="form.country"
             class="form-select"
          >
            <option value="" disabled selected>Selecciona un país</option>
            <option
              v-for="(pais, idx) in paises"
              :key="idx"
              :value="pais.codigo"         
            >
              {{ pais.nombre }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <label class="form-label" for="validationCustom05"
            >Departamento</label
          >
          <select
            v-model="form.state_country"
            @change="updateProvincias"
            class="form-control"
          >
            <option value="" disabled selected>Elige una opcion</option>
            <option
              v-for="(departamento, idx) in departamentos"
              :key="idx"
              :value="departamento.nombre_ubigeo"
            >
              {{ departamento.nombre_ubigeo }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="form-label" for="validationCustom05">Provincia</label>
          <select
            v-model="form.province"
            @change="updateDistritos"
            class="form-control"
          >
            <option value="" disabled selected>Elige una opcion</option>
            <option
              v-for="(provincia, idx) in provincias"
              :key="idx"
              :value="provincia.nombre_ubigeo"
            >
              {{ provincia.nombre_ubigeo }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="form-label" for="validationCustom05">Distrito</label>
          <select v-model="form.district" class="form-control">
            <option value="" disabled selected>Elige una opcion</option>
            <option
              v-for="(distrito, idx) in distritos"
              :key="idx"
              :value="distrito.nombre_ubigeo"
            >
              {{ distrito.nombre_ubigeo }}
            </option>
          </select>
        </div>
      </div>
      <div class="row g-3 mt-2">
        <div class="col-md-4">
          <label class="form-label" for="validationCustom05">Whatsapp</label>
          <input
            class="form-control"
            id="validationCustom05"
            type="text"
            v-model="form.phone"
            placeholder="+51 929118922"
            required=""
          />
        </div>
      </div>
      <br />
      <div class="form-check form-check-inline">
        <input
          class="form-check-input ml-4"
          type="checkbox"
          id="buscador"
          value="true"
          v-model="form.searh_visible"
        />
        <label class="form-check-label" for="buscador"
          >Perfil visible en el buscador de psicólogos.</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input ml-4"
          type="checkbox"
          id="descuento"
          value="true"
          v-model="form.offer_30_firts_date"
        />
        <label class="form-check-label" for="descuento"
          >Dar el 30% de descuento en la primera consulta.</label
        >
      </div>
      <br />
      <div v-if="errors.length > 0" class="alert alert-danger">
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </div>
      <br />
      <button class="btn btn-primary mt-2" @click="editUser">
        Guardar cambios
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/mixins/commen/validationMixin";
import { mapState, mapActions } from "vuex";
import { uploadFile } from "../../../utils/s3Service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      user: {
        first_name: "",
        last_name: "",
        gender: "",
        email: "",
      },
      form: {
        id: "",
        age: 0,
        nationality: "",
        first_name: "",
        last_name: "",
        address: "",
        country: "", 
        state_country: "",
        province: "",
        district: "",
        phone: "",
        undergraduate: false,
        master: false,
        doctorade: false,
        collegiate: "",
        user_type: 0,
        enable_sys: false,
        university_id: "",
        onsite: false,
        remote: false,
        terapeutic_fomation: null,
        url_file: "",
        approach_id: "",
        price_session: "",
        attend_id: "",
        searh_visible: false,
        offer_30_firts_date: false,
      },
      specialitiesValues: [
        "Ninguno",
        "Cognitivo",
        "Contextual",
        "Humanista",
        "Psicoanalista",
        "Otros",
      ],
      gendersValues: [
        { label: "Masculino", value: "male" },
        { label: "Femenino", value: "female" },
      ],
      attens: [
        { id: 1, title: "Niños y Adolescentes" },
        { id: 2, title: "Jovenes" },
        { id: 3, title: "Adultos" },
      ],
      paises: [],
      departamentos: [],
      provincias: [],
      distritos: [],
      formSubmitted: false,
      colegiate_state: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.user,
      universidades: (state) => state.user.universidades,
      approachs: (state) => state.approach.approachs,
    }),
  },
  async mounted() {
    const data = {
      id: localStorage.getItem("userId"),
    };
    await this.loadPaises();
    await this.loadDepartamentos();
    await this.getUser({ payload: data });

    this.getApproachs();
    this.setData();
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      updateUser: "user/updateUser",
      getApproachs: "approach/getApproachs",
    }),
    validateForm() {
      this.errors = [];

      if (!this.form.first_name.trim()) {
        this.errors.push("El campo Nombres es requerido");
      }

      if (!this.form.last_name.trim()) {
        this.errors.push("El campo Apellidos es requerido");
      }

      if (!this.form.phone.trim()) {
        this.errors.push("El campo Whatsapp es requerido");
      }
      return this.errors.length === 0;
    },
    async setData() {
      for (const prop in this.userInfo) {
        if (this.userInfo.hasOwnProperty(prop)) {
          if (prop === "state_country") {
            this.form.state_country = this.userInfo.state_country;
            await this.updateProvincias();
          } else if (prop === "province") {
            this.form.province = this.userInfo.province;
            await this.updateDistritos();
          } else {
            this.form[prop] = this.userInfo[prop];
          }
        }
      }
      this.colegiate_state = this.form.collegiate !== "" ? true : false;
    },
      async loadPaises() {
      try {
          const response = await fetch("/data/ubigee/peru/paises.json");
          const data = await response.json();
          console.log(data); 
          this.paises = data; 
        } catch (error) {
          console.error("Error al cargar los países:", error);
        }
      },
    async loadDepartamentos() {
      try {
        const response = await fetch("/data/ubigee/peru/departamentos.json");
        const data = await response.json();
        this.departamentos = data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateProvincias() {
      try {
        const response = await fetch("/data/ubigee/peru/provincias.json");
        const data = await response.json();
        const departamento = this.departamentos.find(
          (item) => item.nombre_ubigeo === this.form.state_country
        );
        this.provincias = data[departamento.id_ubigeo] || [];
      } catch (error) {
        console.error(error);
      }
    },
    async updateDistritos() {
      try {
        const response = await fetch("/data/ubigee/peru/distritos.json");
        const data = await response.json();
        const province = this.provincias.find(
          (item) => item.nombre_ubigeo === this.form.province
        );
        this.distritos = data[province.id_ubigeo] || [];
      } catch (error) {
        console.error(error);
      }
    },
    getDistritoById(distritoId) {
      return this.distritos.find(
        (distrito) => distrito.nombre_ubigeo === distritoId
      );
    },
    fomattedData() {
      let payload = {};

      if (typeof this.form.country === "object") {
        this.form.country = this.form.country.name;
      }
      if (typeof this.form.state_country === "object") {
        this.form.state_country = this.form.state_country.nombre_ubigeo;
      }

      if (typeof this.form.province === "object") {
        this.form.province = this.form.province.nombre_ubigeo;
      }

      if (typeof this.form.district === "object") {
        this.form.district = this.form.district.nombre_ubigeo;
      }
      if (this.userInfo.user_type === 3) {
        payload = {
          id: this.form.id,
          age: this.form.age,
          nationality: this.form.nationality,
          gender: this.form.gender,
          address: this.form.address,
          country: this.form.country,
          state_country: this.form.state_country,
          province: this.form.province,
          district: this.form.district,
          phone: this.form.phone,
          undergraduate: this.form.undergraduate,
          master: this.form.master,
          doctorade: this.form.doctorade,
          collegiate: this.form.collegiate,
          user_type: this.form.user_type,
          searh_visible: this.form.searh_visible,
          offer_30_firts_date: this.form.offer_30_firts_date,
          university_id: this.form.university_id,
          photo_large: this.form.photo_large,
          enable: this.form.enable_sys,
        };
        return payload;
      } else {
        payload = {
          id: this.form.id,
          age: this.form.age,
          user_type: this.form.user_type,
          nationality: this.form.nationality,
          address: this.form.address,
          gender: this.form.gender,
          country: this.form.country,
          state_country: this.form.state_country,
          province: this.form.province,
          district: this.form.district,
          phone: this.form.phone,
          undergraduate: this.form.undergraduate,
          master: this.form.master,
          doctorade: this.form.doctorade,
          collegiate: this.form.collegiate,
          enable_sys: this.form.enable_sys,
          university_id: this.form.university_id,
          onsite: this.form.onsite,
          remote: this.form.remote,
          terapeutic_fomation: this.form.terapeutic_fomation,
          url_file: this.form.url_file,
          approach_id: this.form.approach_id,
          price_session: this.form.price_session,
          attend_id: this.form.attend_id,
          searh_visible: this.form.searh_visible,
          offer_30_firts_date: this.form.offer_30_firts_date,
          photo_large: this.form.photo_large,
          enable: this.form.enable_sys,
        };
        return payload;
      }
    },
    async editUser() {
      try {
        const data = this.fomattedData();
        if (this.validateForm()) {
          const response = await this.updateUser({ payload: data });
          this.$router.push("/mi-perfil");
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.uploadFiles();
    },
    uploadFiles() {
      if (this.selectedFile) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile
        )
          .then((data) => {
            this.form.url_file = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
    handleFileChange2(event) {
      this.selectedFile2 = event.target.files[0];
      this.uploadFiles2();
    },
    uploadFiles2() {
      if (this.selectedFile2) {
        uploadFile(
          "uploads-sigma-pro",
          event.target.files[0].name,
          this.selectedFile2
        )
          .then((data) => {
            this.form.photo_large = data.Location;
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      }
    },
  },
};
</script>
