<template>
  <div class="col-xl-6 col-md-7 box-col-40 xl-40">
    <div class="row">
      <div class="col-sm-12" v-for="c in myPosts">
        <div class="card">
          <div class="card-body">
            <div class="new-users-social">
              <div class="d-flex align-items-start"><img class="rounded-circle image-radius m-r-15"
                  :src="userInfo.profile_photo" alt="">
                <div class="flex-grow-1">
                  <h6 class="mb-0 f-w-700">{{ userInfo.first_name }} {{ userInfo.last_name }}</h6>
                  <p>{{ formattedDate(c.createdAt) }}</p>
                </div><span class="pull-right mt-0"><i data-feather="more-vertical"> </i></span>
              </div>
            </div>
            <img  v-if="c.image_url" class="img-social-full" alt="" :src="c.image_url">
            <div class="timeline-content">
              <p class="mb-0" v-html=" c.content"></p>
              <div class="like-content mt-2"><span><i class="fa fa-heart font-danger"></i></span><span
                  class="pull-right comment-number"><span> {{ c.likes }} </span><span><i
                      class="fa fa-share-alt me-0"></i></span></span><span class="pull-right comment-number"><span>10
                  </span><span><i class="fa fa-comments-o"></i></span></span></div>
                  
              <div class="social-chat">
                <div class="mb-3" v-for="com in c.comments">
                  <div class="d-flex align-items-start"><img class="img-50 img-fluid m-r-20 rounded-circle" alt=""
                      :src="com.photo">
                    <div class="flex-grow-1"><span class="f-w-600">{{ com.name }} <span>{{ com.date}} <i
                            class="fa fa-reply font-primary"></i></span></span>
                      <p class="mb-0" v-html="com.comment"></p>
                    </div>
                  </div>
                </div>
                <div class="text-center"><a class="f-w-600" href="javascript:void(0)">More Commnets</a></div>
              </div>
              <div class="comments-box">
                <div class="d-flex align-items-center"><img class="img-50 img-fluid m-r-20 rounded-circle" alt=""
                    :src="userInfo.profile_photo">
                  <div class="flex-grow-1">
                    <div class="input-group text-box">
                      <input class="form-control input-txt-bx" type="text" name="message-to-send"
                      placeholder="Escribe tu comentario">
                      <div class="input-group-text"> <img src="@/assets/images/smiley.png" alt=""></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.img-social-full {
  width: 100%;
  object-fit: cover;
  height: 200px; 
  cursor: pointer;
}
</style>

<script>
import imageMixin from "@/mixins/commen/imageMixin"
import socialPost1 from "./socialPost1.vue"
import { mapState, mapActions } from 'vuex';

export default {
  mixins:[imageMixin],
    components:{
    socialPost1
    },
    data(){
      return {
            isActive: true,
            img: "https://dev.sigma.net.pe/img/man.76101d57.png",
            myPosts:[],
            posts1: [
              {
                date: '12 de Enero 2023',
                photo: 'https://media.licdn.com/dms/image/D4E22AQFhh6xkHSF8Pw/feedshare-shrink_800/0/1696180943461?e=1700092800&v=beta&t=O6KzhriNcstIzLq6WKk7H8ujxBlF-JGh8hw_A-OMND0',
                description: '¿Qué cita más poderosa conoces? Steve Jobs(1955- 2011): Para mí, una de las personas más grandes y notables del siglo XX, un pionero increíble, un visionario, simplemente un genio...Desgraciadamente, murió demasiado pronto.',
                comments: [
                  {
                    photo: 'https://images.pexels.com/photos/6844385/pexels-photo-6844385.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                    name: 'Jason Borne',
                    date: '13 de Enero',
                    comment: 'Muy buenas palabras.'
                  },
                  {
                    photo: 'https://images.pexels.com/photos/18401224/pexels-photo-18401224/free-photo-of-ciudad-nubes-edificio-arquitectura.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                    name: 'Estibens Manchego',
                    date: '13 de Enero',
                    comment: 'Muy buenas.'
                  },
                ],
              },
              {
                date: '10 de Enero 2023',
                photo: 'https://media.licdn.com/dms/image/D4E10AQEG6C_pUwS7nw/image-shrink_1280/0/1696782467421?e=1697612400&v=beta&t=1BoE-cWoL8PXStdtwPyb7e4nOBlQm_wP4IaWRaKQd-w',
                description: 'Nuevo trabajo...',
                comments: [
                  {
                    photo: 'https://images.pexels.com/photos/6844385/pexels-photo-6844385.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                    name: 'Jason Borne',
                    date: '13 de Enero',
                    comment: 'Estoy interesado.'
                  },
                ],
              },
            ],
      };
    },
    computed: {
      ...mapState({
         post: state => state.social.post,
         userInfo: (state) => state.user.user || {},
        posts: state => state.feed.posts
      })
    },
    async mounted() {
    await this.getPosts();
    this.myPosts = this.posts.filter((post) => post.user_id === this.userInfo.id)
  },

    methods:{
      ...mapActions({
      getPosts: "feed/getPosts",
      createPost: "feed/createPost",
    }),
    formattedDate(date) {
      const timestamp = date; // El valor proporcionado
      const fecha = new Date(timestamp * 1000); // Multiplicamos por 1000 para convertirlo a milisegundos

      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const año = fecha.getFullYear();
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      return `${meses[fecha.getMonth()]}, ${dia}, ${año}`;
    },
       toggle() {
      this.isActive = !this.isActive
    },
    }
}
</script>