<template>
  <div class="col-sm-12 col-xl-12">
    <div class="card">
      <div class="card-header pb-0">
        <h4>Basic Color Dropdown</h4>
      </div>
      <div class="card-body">
        <div class="dropdown-basic">
          <div class="dropdown">
            <div class="btn-group mb-0">
              <button class="dropbtn btn-pill btn-primary" type="button">Action <span><i
                    class="icofont icofont-arrow-down"></i></span></button>
              <div class="dropdown-content"><a href="#">Action</a><a href="#">Another Action</a><a href="#">Something Else
                  Here</a><a href="#">Separated Link </a></div>
            </div>
          </div>
          <div class="dropdown">
            <div class="btn-group mb-0">
              <button class="dropbtn btn-pill btn-secondary" type="button">Action <span><i
                    class="icofont icofont-arrow-down"></i></span></button>
              <div class="dropdown-content"><a href="#">Action</a><a href="#">Another Action</a><a href="#">Something Else
                  Here</a><a href="#">Separated Link </a></div>
            </div>
          </div>
          <div class="dropdown">
            <div class="btn-group mb-0">
              <button class="dropbtn btn-pill btn-success" type="button">Action <span><i
                    class="icofont icofont-arrow-down"></i></span></button>
              <div class="dropdown-content"><a href="#">Action</a><a href="#">Another Action</a><a href="#">Something Else
                  Here</a><a href="#">Separated Link </a></div>
            </div>
          </div>
          <div class="dropdown">
            <div class="btn-group mb-0">
              <button class="dropbtn btn-pill btn-info" type="button">Action <span><i
                    class="icofont icofont-arrow-down"></i></span></button>
              <div class="dropdown-content"><a href="#">Action</a><a href="#">Another Action</a><a href="#">Something Else
                  Here</a><a href="#">Separated Link </a></div>
            </div>
          </div>
          <div class="dropdown">
            <div class="btn-group mb-0">
              <button class="dropbtn btn-pill btn-warning txt-dark" type="button">Action <span><i
                    class="icofont icofont-arrow-down"></i></span></button>
              <div class="dropdown-content"><a href="#">Action</a><a href="#">Another Action</a><a href="#">Something Else
                  Here</a><a href="#">Separated Link </a></div>
            </div>
          </div>
          <div class="dropdown">
            <div class="btn-group mb-0">
              <button class="dropbtn btn-pill btn-danger" type="button">Action <span><i
                    class="icofont icofont-arrow-down"></i></span></button>
              <div class="dropdown-content"><a href="#">Action</a><a href="#">Another Action</a><a href="#">Something Else
                  Here</a><a href="#">Separated Link </a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
