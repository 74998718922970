import axios from "axios";

const HOST = process.env.VUE_APP_API_FEED;

export default {
  async getPosts(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/comunity/list`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createPost(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/comunity`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async updatePost(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/comunity/${data.id}`,
        method: "put",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  async getComments(payload = {}) {
    try {
      const id = payload.payload.id;
      const response = await axios({
        url: `${HOST}/coment/list/${id}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getTags(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/coment/list/tag`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createComment(payload = {}) {
    try {
      const data = payload.payload;
      const response = await axios({
        url: `${HOST}/coment`,
        method: "post",
        data,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  async getPostsForTags(payload = {}) {
    try {
      const response = await axios({
        url: `${HOST}/comunity/list/tag/${payload.tag}`,
        method: "get",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
