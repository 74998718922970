<template>
      <div class="col-lg-4 col-md-12 box-col-4">
                    <div class="card sell-card">
                      <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                          <div class="flex-grow-1">
                            <p class="square-after f-w-600 header-text-success">Our Total Sold<i class="fa fa-circle"> </i></p>
                            <h4>2,41,658</h4>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            <div class="profit-wrapper header-text-success icon-bg-success"><i class="fa fa-arrow-up"></i></div>
                            <h6 class="header-text-success">94.40%</h6>
                            <p class="mb-0">Look Pretty Good</p>
                          </div>
                        </div>
                        <div class="right-side icon-right-success"><i class="fa fa-shopping-basket"></i>
                          <div class="shap-block">
                            <div class="rounded-shap animate-bg-secondary"><i></i><i></i></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
</template>