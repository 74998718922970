<template>
     <div class="row invo-profile">
                        <div class="col-xl-4">
                          <div class="invo-profile-left">
                            <div class="d-flex">
                              <div class="flex-shrink-0"><img class="d-flex-object rounded-circle img-60" src="@/assets/images/user/1.jpg" alt=""></div>
                              <div class="flex-grow-1">
                                <h4 class="d-flex-heading f-w-600">Johan Deo</h4>
                                <p class="mb-0">JohanDeo@gmail.com<span class="digits">852-963-7532</span></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-8">
                          <div class="invo-profile-right">
                            <div id="project">
                              <h6>Project Description</h6>
                              <p class="mb-0">You're Only As Good As Your Last Collection, Which Is An Enormous Pressure. Jeans Represent Democracy In Fashion.Fashion Is About Dressing According To What's Fashionable.</p>
                            </div>
                          </div>
                        </div>
                      </div>
</template>