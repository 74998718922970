<template>
    <svg class="unlock-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                         
                          <g id="freepik--background-complete--inject-33">
                            <rect class="shp0" y="382.4" width="500" height="0.25"></rect>
                            <rect class="shp0" x="416.78" y="398.49" width="33.12" height="0.25"></rect>
                            <rect class="shp0" x="292.75" y="398.62" width="8.69" height="0.25"></rect>
                            <rect class="shp0" x="354.33" y="389.21" width="61.44" height="0.25"></rect>
                            <rect class="shp0" x="212.45" y="395.11" width="12.69" height="0.25"></rect>
                            <rect class="shp0" x="166.72" y="395.11" width="36.83" height="0.25"></rect>
                            <rect class="shp0" x="52.46" y="390.89" width="25.43" height="0.25"></rect>
                            <rect class="shp0" x="83.89" y="390.89" width="74.89" height="0.25"></rect>
                            <path class="shp0" d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"></path>
                            <path class="shp0" d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"></path>
                            <rect class="shp10" x="116.98" y="122" width="1.49" height="260.4"></rect>
                            <rect class="shp7" x="118.47" y="122" width="264.56" height="260.4"></rect>
                            <rect class="shp8" x="60.32" y="197.82" width="254.49" height="114.67" transform="translate(442.72 67.59) rotate(90)"></rect>
                            <polygon class="shp3" points="146.23 382.4 183.01 127.91 192.78 127.91 156 382.4 146.23 382.4"></polygon>
                            <polygon class="shp3" points="161.96 382.4 198.75 127.91 220.64 127.91 183.86 382.4 161.96 382.4"></polygon>
                            <rect class="shp8" x="186.69" y="197.82" width="254.49" height="114.67" transform="translate(569.08 -58.78) rotate(90)"></rect>
                            <polygon class="shp3" points="275.28 382.4 312.07 127.91 334.19 127.91 297.41 382.4 275.28 382.4"></polygon>
                            <polygon class="shp3" points="303.37 382.4 340.16 127.91 362.05 127.91 325.27 382.4 303.37 382.4"></polygon>
                            <rect class="shp9" x="51.63" y="130" width="49.36" height="85.66"></rect>
                            <rect class="shp7" x="54.65" y="130" width="49.36" height="85.66"></rect>
                            <rect class="shp8" x="42.57" y="153.56" width="73.5" height="38.53" transform="translate(252.16 93.5) rotate(90)"></rect>
                            <polygon class="shp3" points="70.14 209.58 81.75 136.08 88.51 136.08 76.9 209.58 70.14 209.58"></polygon>
                            <rect class="shp9" x="60.7" y="171.69" width="73.5" height="2.28" transform="translate(270.28 75.38) rotate(90)"></rect>
                            <rect class="shp7" x="56.88" y="187.58" width="44.88" height="8.16"></rect>
                            <rect class="shp9" x="395.99" y="130" width="49.36" height="85.66"></rect>
                            <rect class="shp7" x="399.01" y="130" width="49.36" height="85.66"></rect>
                            <rect class="shp8" x="386.94" y="153.56" width="73.5" height="38.53" transform="translate(596.52 -250.86) rotate(90)"></rect>
                            <polygon class="shp3" points="414.51 209.58 426.11 136.08 432.87 136.08 421.27 209.58 414.51 209.58"></polygon>
                            <rect class="shp9" x="405.07" y="171.69" width="73.5" height="2.28" transform="translate(614.65 -268.99) rotate(90)"></rect>
                            <rect class="shp7" x="401.25" y="187.58" width="44.88" height="8.16"></rect>
                            <polygon class="shp0" points="272.78 105.74 271.86 107.15 227.22 107.15 227.22 80.33 272.78 105.74"></polygon>
                            <polygon class="shp8" points="228.94 105.74 272.78 105.74 271.06 80.33 227.22 80.33 228.94 105.74"></polygon>
                            <polygon class="shp0" points="197.96 105.74 197.05 107.15 152.41 107.15 152.41 80.33 197.96 105.74"></polygon>
                            <polygon class="shp8" points="154.13 105.74 197.96 105.74 196.24 80.33 152.41 80.33 154.13 105.74"></polygon>
                            <polygon class="shp0" points="347.59 105.74 346.68 107.15 302.04 107.15 302.04 80.33 347.59 105.74"></polygon>
                            <polygon class="shp8" points="303.76 105.74 347.59 105.74 345.87 80.33 302.04 80.33 303.76 105.74"></polygon>
                          </g>
                          <g id="freepik--Shadow--inject-33">
                            <ellipse class="shp7" id="freepik--path--inject-33" cx="250" cy="416.24" rx="193.89" ry="11.32"></ellipse>
                          </g>
                          <g id="freepik--Ribbon--inject-33">
                            <path class="shp1" d="M90,229.32h0a2.79,2.79,0,0,0-2.78,2.78V416.24h5.56V232.1A2.79,2.79,0,0,0,90,229.32Z"></path>
                            <path class="shp1" d="M410,229.32h0a2.79,2.79,0,0,0-2.78,2.78V416.24h5.56V232.1A2.79,2.79,0,0,0,410,229.32Z"></path>
                            <path class="shp4" d="M345.58,262.59c3.33,4.23-4.64,6.88-9.22,8.76-5.56,1.5-10.86-20.15-48.14-26.68,5.55-7.94,10.79-9.85,10.79-9.85C306,233.51,331.23,244.41,345.58,262.59Z"></path>
                            <path class="shp4" d="M316.6,237c-2.11,3.82,33.51,24.86,19.76,34.33,0,0,15-3.06,16.45-12.55-.52-10.52-30.43-27.66-22.46-32.84"></path>
                            <path class="shp5" d="M316.6,237c-2.11,3.82,33.51,24.86,19.76,34.33,0,0,15-3.06,16.45-12.55-.52-10.52-30.43-27.66-22.46-32.84"></path>
                            <path class="shp4" d="M407.22,239.19c-10.86-6.76-51.66-15.82-76.87-13.23-8,1.69-13.75,11.06-13.75,11.06,33.17-.69,79.06,9.11,90.62,20.8Z"></path>
                            <path class="shp4" d="M155.93,240.52c-3.33-4.23,2.69-5.6,7.28-7.47,5.56-1.51,18.23,18.59,52.29,18.75-5.55,7.94-10.78,9.85-10.78,9.85C197.75,263,170.28,258.7,155.93,240.52Z"></path>
                            <path class="shp4" d="M184.91,266.08c2.11-3.81-35.45-23.57-21.7-33,0,0-13.09,1.77-14.52,11.26.53,10.52,30.44,27.66,22.47,32.84"></path>
                            <path class="shp5" d="M184.91,266.08c2.11-3.81-35.45-23.57-21.7-33,0,0-13.09,1.77-14.52,11.26.53,10.52,30.44,27.66,22.47,32.84"></path>
                            <path class="shp4" d="M92.78,260.09c10.86,6.76,53.17,19.65,78.38,17.06,8-1.69,13.75-11.07,13.75-11.07-33.17.7-80.57-15.21-92.13-26.89Z"></path>
                          </g>
                          <g id="freepik--Character--inject-33">
                            <polygon class="shp11" points="277.59 402.69 285.17 400.6 282.35 382.49 274.77 384.58 277.59 402.69"></polygon>
                            <polygon class="shp11" points="221.64 407.73 229.51 407.73 231.46 389.52 223.6 389.52 221.64 407.73"></polygon>
                            <path class="shp1" d="M230.15,406.82h-9.58a.69.69,0,0,0-.67.54l-1.56,7a1.16,1.16,0,0,0,1.15,1.4c3.09-.05,5.34-.24,9.22-.24,2.39,0,9.6.25,12.9.25s3.73-3.26,2.38-3.56c-6.06-1.32-10.62-3.15-12.56-4.89A1.9,1.9,0,0,0,230.15,406.82Z"></path>
                            <path class="shp1" d="M284.87,399.74,276.6,402a.71.71,0,0,0-.51.7l.36,7.15a1.15,1.15,0,0,0,1.47,1.05c3-.88,7.23-2.24,11-3.28,4.39-1.2,8.24-2,13.39-3.43,3.11-.86,3.11-4.24,1.73-4.17-6.29.34-11.17,1.54-17.17-.21A3.64,3.64,0,0,0,284.87,399.74Z"></path>
                            <path class="shp11" d="M263.24,162c.38.36.93.85,1.42,1.28l1.54,1.33c1,.88,2.09,1.74,3.17,2.57a77.45,77.45,0,0,0,6.59,4.61,44.46,44.46,0,0,0,6.79,3.53,20.12,20.12,0,0,0,6.52,1.55c.48,0,1,0,1.4,0-.69.17-.66.33-.49.21a9.22,9.22,0,0,0,1.94-2,53.23,53.23,0,0,0,4.19-6.76c2.63-4.91,5.1-10.19,7.47-15.39l5.65,1.72A108.52,108.52,0,0,1,304.62,172a52.86,52.86,0,0,1-4.08,8.68,18.84,18.84,0,0,1-3.76,4.6,9.45,9.45,0,0,1-4.38,2.14,22.92,22.92,0,0,1-3,.31,29,29,0,0,1-10.6-1.69,49.78,49.78,0,0,1-8.95-4.12,70.46,70.46,0,0,1-7.86-5.28c-1.24-.95-2.44-1.94-3.63-2.95-.59-.51-1.19-1-1.76-1.56s-1.12-1.05-1.78-1.71Z"></path>
                            <path class="shp4" d="M250.19,168c1.11,7.43,23.66,18.07,23.66,18.07l9-15.48s-9-6-17.35-11C259.85,156.29,249,159.9,250.19,168Z"></path>
                            <path class="shp2" d="M250.19,168c1.11,7.43,23.66,18.07,23.66,18.07l9-15.48s-9-6-17.35-11C259.85,156.29,249,159.9,250.19,168Z"></path>
                            <path class="shp11" d="M303.6,154.17l1.55-8.35,7.21,4.79a8.45,8.45,0,0,1-3.92,5.67Z"></path>
                            <polygon class="shp11" points="306.66 140.31 312.99 144.76 312.36 150.61 305.15 145.82 306.66 140.31"></polygon>
                            <path class="shp4" d="M304,125.18,305,138l0,.36.54,7.08a1.7,1.7,0,0,0-1.31-.53c-1.18.09-2,1.63-1.89,3.43s1.21,3.21,2.39,3.12,2-1.63,1.88-3.44a3.55,3.55,0,0,0-.06-.47c-.14-1.28-.39-4.89.78-7.07a1.18,1.18,0,0,1,.11-.19,3.15,3.15,0,0,1,1-1.07C308.11,134.91,307,127.39,304,125.18Zm1.34,21a2.56,2.56,0,0,1,.28.46,4,4,0,0,1,.39,1.49,3.81,3.81,0,0,1-.37,2,1.09,1.09,0,0,1-2,.15,3.73,3.73,0,0,1-.68-1.95,3.69,3.69,0,0,1,.38-2,1.34,1.34,0,0,1,1-.77A1.37,1.37,0,0,1,305.36,146.15Z"></path>
                            <path class="shp4" d="M310.77,141.64a1.73,1.73,0,0,0-.34,1.37L295,129.75c0,3.71,5.42,9.06,8.72,11.89,2.7-1,6.64,2.36,7.82,3.44l.34.32c1.38,1.18,3.12,1.41,3.89.52s.27-2.59-1.1-3.77S311.54,140.74,310.77,141.64Zm4.67,2.67a1.36,1.36,0,0,1-.14,1.22,1.31,1.31,0,0,1-1.18.31,3.72,3.72,0,0,1-1.86-.89,4,4,0,0,1-1-1.2,2.75,2.75,0,0,1-.21-.51,1.34,1.34,0,0,1,.14-1.21,1.31,1.31,0,0,1,1.18-.32,4,4,0,0,1,3,2.6Z"></path>
                            <path class="shp6" d="M306.64,140.21a.65.65,0,0,1,.07.92.66.66,0,0,1-.92.07.65.65,0,0,1-.07-.92A.64.64,0,0,1,306.64,140.21Z"></path>
                            <polygon class="shp5" points="282.35 382.5 283.8 391.83 276.22 393.92 274.76 384.59 282.35 382.5"></polygon>
                            <polygon class="shp5" points="223.6 389.53 231.46 389.53 230.45 398.92 222.59 398.92 223.6 389.53"></polygon>
                            <path class="shp4" d="M219,161.3a177.3,177.3,0,0,1,5,25.87c.75,6.09,1.19,11.9,1.47,17.18.65,12.36.38,21.78.75,25,6.15-.42,29.32-2,42-2.92,5.71-44.73-5.2-67.77-5.2-67.77a82.41,82.41,0,0,0-10-1.25c-.71,0-1.44-.07-2.17-.08a155.11,155.11,0,0,0-17.84,1.24c-1.12.13-2.26.3-3.36.48C223.9,159.93,219,161.3,219,161.3Z"></path>
                            <path class="shp2" d="M219,161.3a177.3,177.3,0,0,1,5,25.87c.75,6.09,1.19,11.9,1.47,17.18.65,12.36.38,21.78.75,25,6.15-.42,29.32-2,42-2.92,5.71-44.73-5.2-67.77-5.2-67.77a82.41,82.41,0,0,0-10-1.25c-.71,0-1.44-.07-2.17-.08a155.11,155.11,0,0,0-17.84,1.24c-1.12.13-2.26.3-3.36.48C223.9,159.93,219,161.3,219,161.3Z"></path>
                            <path class="shp5" d="M229.55,172.62a33.18,33.18,0,0,1-5.19,18.18c-.12-1.2-.26-2.41-.41-3.63-.56-4.58-1.28-9.32-2.24-14.11Z"></path>
                            <path class="shp11" d="M233.25,138.33c1.52,5.66,3.38,16.1-.37,20.21A17.88,17.88,0,0,0,247,163.05c4.93-.45,4.93-3.92,3.7-5.75-6.51-1.08-6.67-5.84-5.87-10.38Z"></path>
                            <path class="shp5" d="M238,141.84l6.86,5.07a18.67,18.67,0,0,0-.32,3.21c-2.47-.18-6-2.63-6.46-5.18A8.45,8.45,0,0,1,238,141.84Z"></path>
                            <path class="shp1" d="M254.39,119.51c1.66,4.37,1.27,17.29-4.41,14.64s-4.37-12.38-4.37-12.38S250.49,109.28,254.39,119.51Z"></path>
                            <path class="shp11" d="M232,129.75c1,7.73,1.23,12.31,5.43,16,6.32,5.59,15.66,1.36,17-6.53,1.18-7.1-.69-18.56-8.51-21.12A10.65,10.65,0,0,0,232,129.75Z"></path>
                            <path class="shp1" d="M237.62,133.25c1.77-6.38,1.22-12.91-4.5-14.91s-9.74.68-10.5,4.71S235,142.55,237.62,133.25Z"></path>
                            <path class="shp1" d="M234.2,122.53c10.75,1,24.07,1.85,23.37-7.19s-13.2-8.89-15.49-6.29c0,0,4.07,2.76-.17,2.33-8.19-.84-20.95.18-17.14,8.87C227.58,126.64,234.2,122.53,234.2,122.53Z"></path>
                            <path class="shp11" d="M229.05,138.56a7.41,7.41,0,0,0,4.84,2.94c2.57.38,3.54-2,2.55-4.26-.89-2-3.3-4.68-5.75-4S227.65,136.6,229.05,138.56Z"></path>
                            <path class="shp1" d="M286.16,389.3l-12.84,3.53s-9.17-49.27-11.48-73.66c-.7-7.42-2.66-17.76-5.13-28.82-3.11-13.93-7-29-10.22-40.8-3.37-12.43-5.94-21.22-5.94-21.22l27.58-1.93s16.72,65.21,17.53,89.2C286.51,340.54,286.16,389.3,286.16,389.3Z"></path>
                            <path class="shp5" d="M256.71,290.35c-3.11-13.93-7-29-10.22-40.8l2.87-6.17C256.2,251.51,258,276.68,256.71,290.35Z"></path>
                            <path class="shp1" d="M253.25,227.44s-3,67.32-6.3,90.12c-3.63,25-12.86,79.54-12.86,79.54H219.68s-.24-51.74,4.27-75.93c5.14-27.54,2.23-91.84,2.23-91.84Z"></path>
                            <polygon class="shp4" points="235.81 397.41 218.85 397.41 218.85 392.34 237.81 392.01 235.81 397.41"></polygon>
                            <polygon class="shp4" points="287.86 389.15 272.56 393.37 271.2 388.43 288.23 383.45 287.86 389.15"></polygon>
                            <path class="shp1" d="M243.9,131.1c0,.63.42,1.12.83,1.09s.71-.57.67-1.2-.42-1.12-.83-1.09S243.86,130.46,243.9,131.1Z"></path>
                            <path class="shp1" d="M251.05,130.6c0,.63.42,1.12.83,1.09s.71-.57.67-1.2-.41-1.12-.83-1.09S251,130,251.05,130.6Z"></path>
                            <path class="shp12" d="M248.8,131.07a22.57,22.57,0,0,0,3.4,5.2,3.67,3.67,0,0,1-3,.78Z"></path>
                            <path class="shp1" d="M241.52,128.94a.39.39,0,0,0,.35-.17,3,3,0,0,1,2.3-1.41.37.37,0,0,0,0-.74,3.69,3.69,0,0,0-2.92,1.74.37.37,0,0,0,.11.52A.44.44,0,0,0,241.52,128.94Z"></path>
                            <path class="shp1" d="M246.45,140.14h0a5.74,5.74,0,0,1-4.64-2.52.2.2,0,0,1,.07-.27.2.2,0,0,1,.28.07,5.41,5.41,0,0,0,4.3,2.32.19.19,0,0,1,.19.21A.19.19,0,0,1,246.45,140.14Z"></path>
                            <path class="shp1" d="M254,127.92a.36.36,0,0,0,.22,0,.37.37,0,0,0,.14-.51,3.69,3.69,0,0,0-2.82-1.9.37.37,0,0,0-.39.35.38.38,0,0,0,.35.39h0a3,3,0,0,1,2.21,1.54A.4.4,0,0,0,254,127.92Z"></path>
                            <path class="shp4" d="M232.75,408.2a2.4,2.4,0,0,0,1.52-.4,1.12,1.12,0,0,0,.38-1,.56.56,0,0,0-.32-.52c-.87-.45-3.4,1.11-3.69,1.29a.19.19,0,0,0-.09.18.21.21,0,0,0,.14.16A9.5,9.5,0,0,0,232.75,408.2Zm1.17-1.63a.43.43,0,0,1,.24.05c.05,0,.11.07.12.22a.75.75,0,0,1-.25.67,3.79,3.79,0,0,1-2.79.14A7.52,7.52,0,0,1,233.92,406.57Z"></path>
                            <path class="shp4" d="M230.74,407.92l.09,0c.81-.44,2.39-2.17,2.22-3a.61.61,0,0,0-.59-.49,1,1,0,0,0-.8.24c-.91.74-1.1,3-1.11,3.11a.2.2,0,0,0,.09.17A.17.17,0,0,0,230.74,407.92Zm1.59-3.19h.08c.24,0,.26.14.27.19.1.51-.9,1.82-1.71,2.43a4.41,4.41,0,0,1,.93-2.46A.65.65,0,0,1,232.33,404.73Z"></path>
                            <path class="shp4" d="M285.9,400.58h0c1.29-.09,3.55-.39,4.18-1.36a1,1,0,0,0,.09-1,.58.58,0,0,0-.43-.4c-1-.24-3.68,2.13-4,2.4a.18.18,0,0,0,.1.32Zm3.73-2.36h.06a.22.22,0,0,1,.16.16.66.66,0,0,1-.06.64c-.35.54-1.58,1-3.33,1.14C287.52,399.25,289,398.15,289.63,398.22Z"></path>
                            <path class="shp4" d="M285.9,400.58a.18.18,0,0,0,.14,0c.8-.66,2.16-2.8,1.79-3.62-.09-.19-.3-.4-.81-.32a1.39,1.39,0,0,0-.91.58c-.75,1.06-.39,3.16-.37,3.25a.19.19,0,0,0,.12.14Zm1.4-3.62a.19.19,0,0,1,.19.12c.22.49-.65,2.11-1.43,2.93a4.33,4.33,0,0,1,.35-2.61,1,1,0,0,1,.67-.43A.56.56,0,0,1,287.3,397Z"></path>
                            <path class="shp11" d="M226.58,173.54l-2.85,3c-1,1-1.91,2-2.85,3.09-1.88,2.07-3.7,4.19-5.47,6.33s-3.46,4.32-5,6.54c-.79,1.11-1.49,2.24-2.22,3.36l-1,1.7-.12.21c0,.05,0-.06,0-.08a1,1,0,0,0,0-.22c0-.08,0-.14,0-.08a5.72,5.72,0,0,0,.38,2.27,24.42,24.42,0,0,0,1.4,3.39,70.53,70.53,0,0,0,4,7.13c3,4.73,6.34,9.49,9.72,14.09l-4.3,4a114.65,114.65,0,0,1-12.58-12.92,69.22,69.22,0,0,1-5.5-7.56,35.93,35.93,0,0,1-2.35-4.51,15.46,15.46,0,0,1-1.42-6.25,10.42,10.42,0,0,1,.27-2.25,7.86,7.86,0,0,1,.4-1.25l.25-.58.13-.28,1.05-2.17c.74-1.4,1.48-2.82,2.32-4.14a82.9,82.9,0,0,1,5.21-7.69c1.83-2.46,3.77-4.81,5.79-7.09,1-1.13,2-2.25,3.1-3.35s2.09-2.15,3.3-3.29Z"></path>
                            <path class="shp11" d="M219.89,223.14l8-.24L224,232.15s-6.84-1.31-6.55-5Z"></path>
                            <polygon class="shp11" points="233.19 226.75 229.6 234.25 223.96 232.15 227.87 222.9 233.19 226.75"></polygon>
                            <path class="shp4" d="M221.14,160.89c-7.51.31-19.15,15.93-19.15,15.93l12.25,16.62s8.7-9,14.06-17.08C234.14,167.53,230.24,160.5,221.14,160.89Z"></path>
                            <path class="shp2" d="M221.14,160.89c-7.51.31-19.15,15.93-19.15,15.93l12.25,16.62s8.7-9,14.06-17.08C234.14,167.53,230.24,160.5,221.14,160.89Z"></path>
                          </g>
                        </svg>
</template>
<style lang="scss" scoped>
 tspan { white-space:pre }
                            .shp0 { fill: #ebebeb }
                            .shp1 { fill: #263238 }
                            .shp2 { fill: #ffffff;opacity: 0.5 }
                            .shp3 { fill: #ffffff }
                            .shp4 { fill: #5c61f2 }
                            .shp5 { opacity: 0.2 }
                            .shp6 { opacity: 0.3000 }
                            .shp7 { fill: #f5f5f5 }
                            .shp8 { fill: #fafafa }
                            .shp9 { fill: #e6e6e6 }
                            .shp10 { fill: #f0f0f0 }
                            .shp11 { fill: #ffc3bd }
                            .shp12 { fill: #ed847e }
</style>