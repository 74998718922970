import * as types from "../mutation-types";
import sesionAPI from "@/api/sesion";

const state = {
  sesions: [],
  userSesions: [],
  sesionList: [],
};

const getters = {
  sesions: (state) => state.sesions,
};

const actions = {
  async createSesion({ commit }, payload) {
    try {
      const response = await sesionAPI.createSesion(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateSesion({ commit }, payload) {
    try {
      const response = await sesionAPI.updateSesion(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getSesions({ commit }, payload) {
    try {
      const response = await sesionAPI.getSesions(payload);
      const sesions = response.data;
      commit(types.SET_SESIONS, { sesions });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getSesionsById({ commit }, payload) {
    try {
      const response = await sesionAPI.getSesionsById(payload);
      const userSesions = response.data;
      commit(types.SET_USER_SESIONS, { userSesions });
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getSesionsByProfesionalId({ commit }, payload) {
    try {
      const response = await sesionAPI.getSesionsByProfesionalId(payload);
      const sesionList = response.data;
      commit(types.SET_LIST_SESIONS, { sesionList });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async deleteSesion({ commit }, payload) {
    try {
      const response = await sesionAPI.deleteSesion(payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [types.SET_SESIONS](state, { sesions }) {
    state.sesions = sesions;
  },
  [types.SET_USER_SESIONS](state, { userSesions }) {
    state.userSesions = userSesions;
  },
  [types.SET_LIST_SESIONS](state, { sesionList }) {
    state.sesionList = sesionList;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
