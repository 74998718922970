<template>
  <Breadcrumbs main="" title="Nuevo Paciente" />
  <div class="container-fluid new-consulting">
    <div class="col-sm-12 box-col-12">
      <div class="card profit-card">
        <div class="social-tab social-tabs">
          <ul class="nav nav-tabs" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('generalInfo')">
              <a
                class="nav-link"
                :class="{ 'show active': step == 'generalInfo' }"
                id="top-timeline"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="generalInfo"
                aria-selected="false"
                >Datos generales</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('queryReason')">
              <a
                class="nav-link cursor-pointer"
                :class="{ 'show active': step == 'queryReason' }"
                id="top-queryReason"
                data-toggle="tab"
                role="tab"
                aria-controls="queryReason"
                aria-selected="false"
                >Motivo de consulta
              </a>
            </li>
            <li
              class="nav-item cursor-pointer"
              v-on:click="changetab('personalHistory')"
            >
              <a
                class="nav-link"
                :class="{ 'show active': step == 'personalHistory' }"
                id="top-personalHistory"
                data-toggle="tab"
                role="tab"
                aria-controls="personalHistory"
                aria-selected="false"
                >Historia personal</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('treatmentPlan')">
              <a
                class="nav-link"
                :class="{ 'show active': step == 'treatmentPlan' }"
                id="top-treatmentPlan"
                data-toggle="tab"
                role="tab"
                aria-controls="treatmentPlan"
                aria-selected="false"
                >Plan de tratamiento</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('therapeuticSessions')">
              <a
                class="nav-link"
                :class="{ 'show active': step == 'therapeuticSessions' }"
                id="top-therapeuticSessions"
                data-toggle="tab"
                role="tab"
                aria-controls="therapeuticSessions"
                aria-selected="false"
                >Nueva Sesión</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('print')">
              <a
                class="nav-link"
                :class="{ 'show active': step == 'print' }"
                id="top-print"
                data-toggle="tab"
                role="tab"
                aria-controls="print"
                aria-selected="false"
                >Imprimir</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tab-content" id="top-tabContent">
      <div
        class="tab-pane fade"
        :class="{ 'show active': step == 'generalInfo' }"
        id="generalInfo"
        role="tabpanel"
        aria-labelledby="generalInfo"
      >
        <generalInfo @join-data="joinData" />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': step == 'queryReason' }"
        id="queryReason"
        role="tabpanel"
        aria-labelledby="queryReason"
      >
        <queryReason @join-data="joinData" :patient="patient" />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': step == 'personalHistory' }"
        id="personalHistory"
        role="tabpanel"
        aria-labelledby="personalHistory"
      >
        <personalHistory @join-data="joinData" :patient="patient" />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': step == 'treatmentPlan' }"
        id="photos"
        role="tabpanel"
        aria-labelledby="treatmentPlan"
      >
        <treatmentPlan @create-patient="createPatientForm" :patient="patient" />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': step == 'therapeuticSessions' }"
        id="photos"
        role="tabpanel"
        aria-labelledby="therapeuticSessions"
      >
        <newTherapeuticSessions />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'show active': step == 'print' }"
        id="photos"
        role="tabpanel"
        aria-labelledby="print"
      >
        <printDocs />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.carosel-tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
}

.social-tabs {
  padding: 15px !important;
  cursor: pointer;
}

.new-consulting {
  .img-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-left: 1.5rem;
  }

  .card {
    background: transparent;
    border-color: transparent;
    box-shadow: none;

    .card-body {
      .hello {
        font-size: 1.3rem;
        margin-bottom: 0;
      }

      .name {
        font-size: 1.7rem;
        margin-bottom: 0;
      }

      .tag {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }

  .text-right {
    text-align: right;
  }

  .btn-primary {
    font-size: 2rem;
    margin-top: 3rem;
  }

  .list-menu {
    .card-item {
      background-color: #d2d2d2;
      width: 200px;
      height: 200px;
      margin-bottom: 1rem;
      text-align: center;
      border-radius: 1.2rem;
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 115px;
        height: 115px;
      }

      p {
        color: #5050b3;
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 0px;
      }
    }
  }
}
</style>

<script>
import imagePreview from "@/components/imagePreview/index.vue";
import generalInfo from "@/components/myProfileSections/generalInfo.vue";
import queryReason from "@/components/myProfileSections/queryReason.vue";
import personalHistory from "@/components/myProfileSections/personalHistory.vue";
import treatmentPlan from "@/components/myProfileSections/treatmentPlan.vue";
import therapeuticSessions from "@/components/myProfileSections/therapeuticSessions.vue";
import newTherapeuticSessions from "@/components/myProfileSections/newTherapeuticSessions.vue";
import printDocs from "@/components/myProfileSections/printDocs.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    imagePreview,
    generalInfo,
    queryReason,
    personalHistory,
    treatmentPlan,
    therapeuticSessions,
    newTherapeuticSessions,
    printDocs,
  },
  data() {
    return {
      user: {
        photo: "https://dev.sigma.net.pe/img/man.76101d57.png",
        name: "Rolando Escobar",
        nickname: "leonardoescobar",
      },
      editedItem: {
        fileImage: "",
      },
      tabIndex: 0,
      showtab: "generalInfo",
      form: {},
    };
  },
  async mounted() {
    if (this.$route.params.id !== undefined) {
      const data = {
        id: this.$route.params.id,
      };
      const step = this.$route.query.step;
      this.setStep({ payload: step });
      await this.getUserPatient({ payload: this.$route.params.id });
    }
    {
      this.setStep({ payload: "generalInfo" });
    }
  },
  computed: {
    ...mapState({
      step: (state) => state.patients.step,
      patient: (state) => state.user.patient,
    }),
    isActive() {
      return this.$route.params.id !== undefined;
    },
  },
  methods: {
    ...mapActions({
      createPatient: "patients/createPatient",
      getUser: "user/getUser",
      setStep: "patients/setStep",
      getUserPatient: "user/getUserPatient",
      updateUser: "user/updateUser",
    }),
    setTabIndex(index = 0) {
      this.tabIndex = index;
    },

    changetab(tab) {
      if (this.$route.params.id !== undefined) {
        this.setStep({ payload: tab });
        let step = tab;
        this.$router.push({
          path: `/nuevo-paciente/${this.$route.params.id}`,
          query: { step },
        });
      } else {
        this.$toast.show("No Saltar pasos", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "warning",
          duration: 2000,
        });
      }
    },
    joinData(form) {
      this.form = { ...this.form, ...form };
    },
    async createPatientForm(form) {
      try {
        this.form = { ...this.form, ...form };
        let response = "";
        if (this.$route.params.id !== undefined) {
          this.form.id = this.$route.params.id;
          this.form.user_type = this.patient.user_type;
          response = await this.updateUser({ payload: this.form });
          const step = "therapeuticSessions";
          this.setStep({ payload: step });
          this.$router.push({
            path: `/nuevo-paciente/${this.$route.params.id}`,
            query: { step },
          });
          this.setStep({ payload: step });
        } else {
          response = await this.createPatient({ payload: this.form });
          await this.getUserPatient({ payload: response.data.user_id });
          const step = "therapeuticSessions";
          this.setStep({ payload: step });
          this.$router.push({
            path: `/nuevo-paciente/${response.data.user_id}`,
            query: { step },
          });
          const data = {
            id: response.data.user_id,
          };
          await this.getSesionsById({ payload: data });
          this.setStep({ payload: step });
        }
      } catch (error) {
        this.$toast.show("Error al crear paciente", {
          theme: "outline",
          position: "top-right",
          icon: "times",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>
