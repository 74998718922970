<template>
     <div class="col-xxl-6 box-col-50 xl-40">
                <div class="card">
                  <div class="blog-box blog-shadow">
                    <div class="blog-details">
                      <p>04 August 2023</p>
                      <h4>People just don't do it anymore. We have to change that. Sometimes the simplest things are the most profound.</h4>
                      <ul class="blog-social">
                        <li><i class="icofont icofont-user"></i>Mark Jecno</li>
                        <li><i class="icofont icofont-thumbs-up"></i>02 Hits</li>
                        <li><i class="icofont icofont-ui-chat"></i>598 Comments</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
</template>